<template>
  <v-app
    style="background-color: #12141B !important;"
  >
    <v-main style="padding-left: 0% !important; padding-right: 0% !important;">
      <v-row class="ma-0" style="width: 100%; height: 100%;">
        <v-col cols="0" lg="2" class="pa-4">
          <admin-sidebar
          :drawer="drawer"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          @openSectionIndex="openSectionIndex"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          :showSettingsDrawer="showSettingsDrawer"
          />
          <!--
            transparent 
            white
            dark
          -->
        </v-col>
        <v-col cols="12" lg="10" class="pa-0">
          <v-row style="width: 100%; height: 100%; margin: 0;">
            <v-col cols="12" class="pa-4">
              <nav-bar
                background="bg-transparent"
                @drawer-toggle="drawer = $event"
                :toggle-active="drawer"
                :navbarFixed="navbarFixed"
                @toggleSettingsDrawer="toggleSettingsDrawer"
              ></nav-bar>

              <fade-transition :duration="200" origin="center top" mode="out-in">
                <!-- your content here -->
                <router-view
                :linkColor="secondaryColor"
                @navbarMainPage="navbarMainPage"
                :SectionTypeId="SectionTypeId"
                :key="$route.fullPath"
                style="height: auto !important;"
                ></router-view>
              </fade-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import AdminSidebar from "./Sidebar.vue";
import NavBar from "./Navbar.vue";

export default {
  components: {
    AdminSidebar,
    NavBar,
    FadeTransition
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "error",
      sidebarTheme: "transparent",
      navbarFixed: true,
      secondaryColor: "#656565",
      navbarMainPage: false,

      SectionTypeId: null,
      actualSectionTitles: [],
    };
  },
  methods: {
    openSectionIndex (section_type_id) {
      this.SectionTypeId = section_type_id;
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    toggleNavbarOpacity(value) {
      this.navbarMainPage = value;
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
