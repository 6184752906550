<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <div
          class="page-header main-image min-vw-100"
          :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/rocas.jpg')}) !important; background-size: cover; background-position: 50%;`"
        >
        </div>
      </v-col>
    </v-row>
    <v-row class="margin-view margin-card">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pa-5">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pa-5"
            v-for="(incentive, index) in incentives.data" :key="index"  
          >
            <div class="text-center border-radius-xl blur blur-transparent py-3 px-5 full-height">
              <h3 class="text-body mb-5">Incentivo</h3>
              <h2 class="text-white mb-5 notranslate">{{ incentive.public_info.title.value }}</h2>
              <p class="justify-text text-body" v-html="incentive.public_info.main_description.value"></p>
              <div class="margin-button">
              </div>
              <v-btn 
                class="mb-5 mx-0 main_gradient_background text-white text-transform-none main-image-button bottom-button"
                x-large
                @click="goToPostulationForm(incentive.id)"
                :loading="loadingBtn"
              >
                Conocer más
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pa-5"
            v-if="incentives.data.length <= 2"
          >
            <div class="text-center border-radius-xl blur blur-transparent py-3 px-5 full-height">
              <h3 class="text-body mb-5">Incentivo</h3>
              <h2 class="text-white mb-5">Próximamente</h2>
              <div class="margin-button">
                <!--  -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pa-5"
            v-if="incentives.data.length <= 1"
          >
            <div class="text-center border-radius-xl blur blur-transparent py-3 px-5 full-height">
              <h3 class="text-body mb-5">Incentivo</h3>
              <h2 class="text-white mb-5">Próximamente</h2>
              <div class="margin-button">
                <!--  -->
              </div>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pa-5"
            v-if="incentives.data.length == 0"
          >
            <div class="text-center border-radius-xl blur blur-transparent py-3 px-5 full-height">
              <h3 class="text-body mb-5">Incentivo</h3>
              <h2 class="text-white mb-5">Próximamente</h2>
              <div class="margin-button">
                <!--  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="pa-5">
        <v-pagination
          class="pagination table-pagination"
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          total-visible="6"
          v-model="incentives.current_page"
          :length="incentives.last_page"
          circle
          @input="getIncentives"
        />
      </v-col>
    </v-row>

    <!--     
    <pre style="width: 100%; height: 300px; overflow-y: scroll;" class="text-white">
      incentives: {{ incentives }}
    </pre> 
    -->

  </v-container>
</template>
<script>
  import Api from '@/api/Api';
  export default {
    data() {
      return {
        incentives: [],
        loadingBtn: false
      };
    },
    created() {
      this.getIncentives();
      this.$emit('navbarMainPage',false);
    },
    methods: {
      getIncentives: function (page = 1) {
        Api.get(`section/get_public_incentives_information?page=${page}`)
        .then(response => {
          this.incentives = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      goToPostulationForm: function (incentive_id) {
        this.loadingBtn = true;
        Api.get('/auth/user_is_logged').then(response => {
          if (response.data === 'not_logged') {
            this.$swal('Acceso denegado', 'Para solicitar información de un incentivo, necesita un usuario de tipo casa productora.', "error");
            this.$router.push({ 
              name: 'SignUpCover'
            })
          } else {
            Api.get('/auth/logged_user').then(response => {
              this.user = response.data;
              if (this.user.Roles[0] != 'Producer') {
                this.$swal('Acceso denegado', 'No se puede postular a un incentivo si no es una casa productora. Pruebe con otro usuario.', "error");
              } else {
                this.$router.push({ 
                  name: 'Apply for incentive', 
                  params: { 
                    incentive_id: incentive_id                
                  } 
                })
              }
            })
            .catch(error => this.errorHandling(error))
          }
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.loadingBtn = false;
        })
      }
    }
  }
</script>
<style scoped>
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.card-size{
  width: 100vw;
  min-height: 65vh;
}
.margin-card{
    margin-bottom: 20vh;
}
.content{
    padding-top: 10vh;
    padding-left: 3vw;
    padding-right: 3vw;
}
.content h2{
    margin-bottom: 1vh;
    color: #949598;
}
.content h1{
    margin-bottom: 3vh;
}
.content p{
    margin-bottom: 8vh;
    color: #949598;
}

.bottom-button {
  position: absolute;
  bottom: 0;
  right: 30%;
  left: 30%;
}
.margin-button {
  height: 70px;
}
</style>