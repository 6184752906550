<template style="font-family: 'roboto';">
  <section>
    <div class="container pt-10">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                Gestión de mensajes de Contacto
              </h5>
              <p class="text-body mb-0">
                Revisa todos los mensajes de contacto realizados por los usuarios
              </p>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex align-right">
              <v-text-field
                v-model="itemsPerPage" 
                hide-details
                type="number"
                min="1"
                max="10"
                color="#FFFFFF"
                placeholder="10"
                class="
                  input-icon
                  cancel_gradient_border
                  input-white-text
                  ma-0
                  pa-1
                "
              />
              <span class="text-white mt-3 mx-3 text-sm">registros por página</span>
            </v-col>
            <v-col cols="1" lg="6" class="d-flex align-left">
              <!--  -->
            </v-col>
            <v-col cols="11" lg="3" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar por email o nombre"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white notranslate">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>Nombre</th>
                <th>Teléfono</th>
                <th>Fecha del mensaje</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(contact,index) in contacts.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ contact.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ contact.email }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ contact.complete_name }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ contact.complete_phone }} {{ contact.phone_whatsapp ? '(con Whatsapp)' : '(sin Whatsapp)' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ formatDate(contact.created_at) }}
                    </span>
                  </div>
                </td>
                
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                       
                        <v-btn 
                          icon text rel="tooltip" title="Revisar información del contacto"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/contact_details/'+contact.id"
                        >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            visibility
                          </v-icon>
                        </v-btn>            
                        <v-btn icon text rel="tooltip" title="Borrar usuario" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteContact(contact.id)">
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
              <tr v-if="contacts.data.length == 0" >
                <td colspan="6" class="pa-3 align-center text-secondary" style="text-align: center;">
                  Aún no hay registros
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ contacts.from }} a {{ contacts.to }} de {{ contacts.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="contacts.current_page"
                :length="contacts.last_page"
                circle
                @input="getContacts"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            contacts: {{ contacts }}
            search: {{ search }}
            itemsPerPage: {{ itemsPerPage }}
            page: {{ page }} 
          </pre>
        </v-col>   
        -->
      </v-row>
      
    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  export default {
    name: "Dashboard",
    components: {
      //
    },
    data() {
      return {
        contacts: {
          data: []
        },
        search: '',
        itemsPerPage: 10,
        page: 1,
        loggedUser: {
          Personal_information: {
            complete_name: "  "
          },
          Roles: [
            null
          ]
        },

        btnLoading: false
      };
    },
    watch: {
      itemsPerPage(value) {
        if (value === '') value = 10;
        this.debounceSearch();
      },
      search () {
        this.debounceSearch();
      }
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      getContacts: function (page = 1) {
        Api.get(`contacto`, {
          params: {
            page: page,
            query: this.search,
            paginate: this.itemsPerPage
          }
        })
        .then(response => {
          this.contacts = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      
      deleteContact: function (contactId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`contacto/${contactId}`)
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getContacts();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },

      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      },
      
    },
    created() {
      this.getUserInfo();
      this.getContacts();
      this.debounceSearch = debounce(() => {
        this.getContacts();
      }, 500);
    }
  };
</script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>