<template>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/rocas.jpg')}) !important; background-size: cover; background-position: 50%;`"
          >
          </div>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-search">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-card class="border-radius-xl blur blur-transparent">
              <v-row class="card-margin" justify="center">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                      v-if="!actualDomain.includes('en.')"
                    >
                      <label class="ms-0 text-white">Buscar</label>
                      <v-text-field
                        hide-details
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          cancel_gradient_border
                          input-white-text
                        "
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        v-model="search"
                        placeholder="Buscar aquí"
                        clearable
                      >
                        <template slot="prepend-inner">
                          <v-icon
                            color="#adb5bd"
                            size="16"
                            class="material-icons-round mt-n2 "
                            >search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                        <label class="ms-0 text-white">Categoria</label>
                        <v-select
                          v-model="temporalCategory" 
                          color="white" 
                          dark 
                          clearable
                          item-text="name"
                          item-value="id"
                          :items="categories"
                          label="Categoría"
                          @change="getSubcategories(temporalCategory)">
                        </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                        <label class="ms-0 text-white">Subcategoria</label>
                        <v-select
                          v-model="temporalSubCategory" 
                          color="white" 
                          dark 
                          clearable
                          item-text="name"
                          item-value="id"
                          :items="subcategories"
                          label="Subcategoría"
                          :disabled="subcategories.length < 1"
                          @change="getMovies()"
                        >
                        </v-select>
                    </v-col>
                    <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3" class="pt-8">
                        <v-btn 
                            class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                            x-large
                            @click="getMovies()"
                        >
                            Buscar
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-card-movie">
          <v-col v-for="(movie, index) in movies.data" :key="index" cols="12" xs="12" sm="12" md="3" lg="3" class="padding-margin-card-movie">
            <a :href="`/movie/${movie.id}`">
                <div class="card border-radius-lg movie" :style="[movie.contents_info.main_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${movie.contents_info.main_image_url.value})`}: {'background': '#282c39'}]" >
                  <div class="content">
                      <h2 class="text-white title-movie notranslate">{{ movie.contents_info.title ? movie.contents_info.title.value : '-' }}</h2>
                      <span v-html="movie.contents_info.main_description ? movie.contents_info.main_description.value : '-'" class="text-white parrafo ql-editor">
                      </span>
                  </div>
                </div>
            </a>
          </v-col>
      </v-row>
      <v-row class="margin-view margin-card">
        <v-col cols="12" lg="12" class="d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination table-pagination"
                v-model="movies.current_page"
                :length="movies.last_page"
                total-visible="6"
                circle
                @input="getMovies"
              />
        </v-col>
      </v-row>
      <v-row class="margin-view margin-card" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Accede al catalogo de Cine en Durango
            </h1>
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pt-8">
                  <v-btn 
                      class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                      x-large
                      target="_blank"
                      href="https://filmografiadurango.com/"
                  >
                      Catalogo
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>  
</template>

<script>
import Api from '@/api/Api';
import debounce from "lodash.debounce";
export default {
    props: {
    },
    data() {
        return {
            visible: false,
            index: 0,
            categories: [],
            subcategories: [],
            temporalCategory: 0,
            temporalSubCategory: 0,
            movies: [],
            itemsPerPage: 8,
            search: '',
        };
    },
    created() {
      this.$emit('navbarMainPage',false);
      this.getCategories();
      this.getMovies();
      this.debounceSearchSection = debounce(() => {
        this.getMovies()
      }, 500);
    },
    computed: {
      actualDomain () {
        return window.location.host;
      }
    },
    methods: {
      getCategories(){
        Api.get(`category/get_by_section_type/5`)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getSubcategories(category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getMovies:function (page = 1){
        Api.get('section/get_section_by_section_type/5', {
          params: {
            page: page,
            query: this.search,
            paginate: this.itemsPerPage,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
            category: this.temporalCategory,
            subCategory: this.temporalSubCategory
          }
        })
        .then(response => {
          this.movies = response.data;
        })
        .catch(error => this.errorHandling(error))
      }
    },
    watch: {
      temporalCategory (value) {
        this.temporalSubCategory = 0;
        this.getMovies();
        this.getSubcategories(value);
      },
      search () {
        this.debounceSearchSection();
      },
    },
};
</script>
<style scoped>
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.card-margin{
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-search{
  margin-bottom: 20vh;
}
.margin-card{
  margin-bottom: 20vh;
}
.v-btn:not(.v-btn--round).v-size--x-large {
    min-width: 100% !important;
}
.card-padding{
  padding-right: 3% !important;
}
.movie{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    padding: 0vw 1vw;
    width : auto;
    height : 50vh;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-size: cover;
    background-position: 50%;
}
.content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
}
.content:active{
  display: block;
}
.parrafo{
  font-weight: 300 !important;
}
.margin-card-movie{
  margin-bottom: 20vh;
}
.title-movie{
  font-size: 1.2em;
  font-weight: 500;
}
.padding-margin-card-movie{
  padding-right: 2%;
  padding-left: 2%;
  margin-bottom: 6vh;
}
.padding-margin-card-movie a{
    text-decoration: none;
}
</style>