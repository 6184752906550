<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 py-10 ">
      <v-row class="justify-content-center border-radius-lg">
        <v-col cols="12" class="pa-0">
          <v-btn 
            class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
            :to="'/admin/sections/new/'+sectionName+'/'+sectionTypeId"
          >
            Nuevo(a) {{ sectionName }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                {{
                  (sectionTypeId != 14) ? sectionName.charAt(0).toUpperCase() + sectionName.slice(1) + ((sectionTypeId == 4) ? 'es' : 's') : sectionName
                }}
              </h5>
              <!--               
              <p class="text-body mb-0">
                View all the {{ sectionName }}s from the previous year
              </p> 
              -->
              <p class="text-body mb-0 text-center mt-3">
                NOTA: No puedes editar una sección cuando hay una revisión pendiente
              </p>
            </v-col>
            <v-col cols="3" lg="3" class="d-flex align-right">
              <v-text-field
                v-model="itemsPerPage" 
                hide-details
                type="number"
                min="1"
                max="10"
                color="#FFFFFF"
                placeholder="Registros por página"
                class="
                  input-icon
                  cancel_gradient_border
                  input-white-text
                  ma-0
                  pa-1
                "
              />
              <span class="text-white mt-3 mx-3 text-sm">registros por página</span>
            </v-col>
            <v-col cols="6" lg="6" class="d-flex align-left">
              <!--  -->
            </v-col>
            <v-col cols="3" lg="3" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar aquí"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white notranslate" style="overflow-x: auto !important">
            <thead class="text-white">
              <tr>
                <th>
                  <v-btn class="text-white btn-sort-table text-transform-none"
                    :ripple="false"
                    @click="sorting('id')"
                  >
                    Id
                    <v-icon v-if="sortBy == 'id' && isSort">
                      {{ order  ? 'arrow_drop_up' : 'arrow_drop_down' }}
                    </v-icon>
                  </v-btn>
                </th>
                <th>Nombre</th>
                <!-- <th>{{ sectionTypeId == 5 ? 'Sinopsis' : 'Descripción'}}</th> -->
                <th v-if="sectionTypeId != 4">Categoría</th>
                <th>{{ sectionTypeId == 4 ? 'Categoría' : 'Subcategoría'}}</th>
                <th>Usuario que lo creó</th>
                <th>
                  <v-btn class="text-white btn-sort-table text-transform-none"
                    :ripple="false"
                    @click="sorting('approved')"
                  >
                    Estatus
                    <v-icon v-if="sortBy == 'id' && isSort">
                      {{ order  ? 'arrow_drop_up' : 'arrow_drop_down' }}
                    </v-icon>
                  </v-btn>
                </th>
                <th>¿Activa?</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(section,index) in sections.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ section.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (section.contents_info.title) ? section.contents_info.title.value : 'n/a' }}
                    </span>
                  </div>
                </td>

                <!--                 
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 15vw !important;">
                      {{ (section.contents_info.main_description) ? removeTags(section.contents_info.main_description.value) : 'n/a' }}
                    </span>
                  </div>
                </td> 
                -->

                <td v-if="sectionTypeId != 4">
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 6vw !important;"
                      :class="section.categories_subcategories[0] ? 'text-white' : 'text-body'"
                    >
                      {{ (section.categories_subcategories[0]) ? section.categories_subcategories[0].category_text : 'n/a' }}
                    </span>
                  </div>
                </td> 

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal table-description-cell" style="max-width: 6vw !important;"
                      :class="section.categories_subcategories[0] ? 'text-white' : 'text-body'"
                    >
                      {{ (section.categories_subcategories[0]) ? section.categories_subcategories[0].subcategory_text : 'n/a' }}
                    </span>
                  </div>
                </td> 

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 15vw !important;">
                      <!-- {{ (section.user_owner.personal_information.name != null) ? section.user_owner.personal_information.complete_name : section.user_owner.email }} -->
                      {{ section.user_owner.email }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (section.approved == null) ? 'Pendiente' : (section.approved == true) ? 'Aprobada' : 'Rechazada' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (section.section_active) ? 'Sí' : 'No' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Revisar cambios de sección"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/preview_changes/'+sectionName+'/'+sectionTypeId+'/'+section.id">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            visibility
                          </v-icon>
                        </v-btn>      
                        <v-btn icon text rel="tooltip" title="Activar / desactivar sección"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="activateSection(section.id, (section.section_active ? false : true))"
                        >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            {{section.section_active ? 'toggle_off' : 'toggle_on'}}
                          </v-icon>
                        </v-btn>      
                        <v-btn icon text rel="tooltip" title="Revisar perfil de usuario"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/user_details/'+section.user_id">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            account_circle
                          </v-icon>
                        </v-btn>      
                        <v-btn icon text rel="tooltip" title="Cambiar el usuario propietario"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="openAssignUserModal(section.id)"
                          v-if="user.Roles[0] == 'Admininstrator'"
                        >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            manage_accounts
                          </v-icon>
                        </v-btn>      
                        <v-btn icon text rel="tooltip" title="Editar sección"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/sections/edit/'+sectionName+'/'+sectionTypeId+'/'+section.id"
                          :disabled="section.approved == null"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            edit
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Aprobar sección"
                          v-if="user.Roles[0] == 'Admininstrator' && section.approved == null"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="approveOrRejectSection(section.id, true)">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            task_alt
                          </v-icon>
                        </v-btn>   
                        <v-btn icon text rel="tooltip" title="Rechazar sección"
                          v-if="user.Roles[0] == 'Admininstrator' && section.approved == null"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="approveOrRejectSection(section.id, false)">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            unpublished
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Borrar sección" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteSection(section.id)">
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>
              </tr>
              <tr v-if="sections.data.length == 0">
                <td colspan="7" class="pa-3 align-center text-secondary" style="text-align: center;">
                  Aún no hay registros
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div v-if="sections.total > 0" class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ sections.from }} a {{ sections.to }} de {{ sections.total }} registros
                </div>
                <div v-else class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando 0 registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="sections.current_page"
                :length="sections.last_page"
                circle
                @input="getSections"
              />

            </v-col>
          </v-row>
        </v-col>
        <!--         
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            sections: {{ sections }}
            search: {{ search }}
            itemsPerPage: {{ itemsPerPage }}
            page: {{ page }} 
          </pre>
        </v-col>
        -->
      </v-row>
    </div>
    
    <!-- Modal for assign a user to a company -->
    <v-dialog v-model="showAssignUserModal" class="upload-image-card" width="100%" height="100%">
      <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="500px" height="100%">
        <v-card-text>
          <v-row class="my-5">
            <v-col cols="12" class="d-flex">
              <label class="text-white new-section-title">Asignar esta sección a otro usuario</label>
              <v-spacer></v-spacer>

              <v-btn icon dark @click="showAssignUserModal = false" class="text-white">
                <v-icon>close</v-icon>
              </v-btn>                    
            </v-col>

            <v-col cols="12" lg="12" class="mt-1 py-0">
              <v-autocomplete
                v-model="newAssignation.user_id"
                :items="vAutocompleteEmployer.employers" 
                :loading="vAutocompleteEmployer.isLoading" 
                v-bind:search-input.sync="vAutocompleteEmployer.search" 
                label="Usuario" 
                item-text="email"
                item-value="id"
                hint="Escribe al menos 3 caracteres" 
                class="input-style mb-5 input-white-text"
              />
            </v-col>
          </v-row>

          <v-btn 
            block
            class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
            @click="assignUser"
            :loading="btnAssignUser"
          >
            Asignar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </section>
  </template>
  
  <script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  export default {
    name: "Dashboard",
    components: {
      //
    },
    data() {
      return {
        sections: {
          data: []
        },
        sectionName: '',
        //actualSectionTitles: [],
        search: '',
        itemsPerPage: 10,
        page: 1,
        user: {
          Personal_information: {
            complete_name: "  "
          },
          Roles: [
            null
          ]
        },

        //Used to sort items
        isSort: true,
        sortBy: 'id',
        order: false,

        showAssignUserModal: false,
        btnAssignUser: false,

        vAutocompleteEmployer: {
          isLoading: false,
          employers: [],
          search: null,
          select: {},
        },

        newAssignation: {
          user_id: null,
          section_id: null
        },
      };
    },
    computed: {
      searchUser: function () {
        return this.vAutocompleteEmployer.search
      }
    },
    watch: {
      itemsPerPage(value) {
        if (value === '') value = 10;
        this.getSections();
      },
      search () {
        this.debounceSearchSection();
      },
      sortBy: function() {
        this.getSections();
      },
      order: function() {
        this.getSections();
      },
      searchUser() {
        this.getUsers();
      },
      showAssignUserModal (value) {
        if (value == false) {
          this.newAssignation = {
            user_id: null,
            section_id: null
          };
        }
      }
    },
    methods: {
      removeTags: function(content) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = content;
        return tmp.textContent || tmp.innerText || "";
      },
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.user = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getSections: function (page = 1) {
        Api.get(`section/show_by_section_type/${this.$route.params.section_type_id}`, {
          params: {
            page: page,
            query: this.search,
            paginate: this.itemsPerPage,
            sortBy: this.sortBy,
            order:this.order ? 'asc' : 'desc',
            contents: ['title','main_description'],
            pagination_required: 'Yes'
          }
        })
        .then(response => {
          this.sections = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      activateSection: function (sectionId, active) {
        this.$swal({
          title: "Confirmación",
          text: active ? "¿Activar la sección?" : "¿Desactivar la sección?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`/section/view_or_hide_section`, {
              section_id: sectionId,
              active: active
            })
            .then(response => {
              this.$swal.fire("", response.data.message, "success");
              this.getSections();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      deleteSection: function (sectionId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`section/${sectionId}`)
            .then(() => {
              this.$swal.fire("", "Sección eliminada", "success");
              this.getSections();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      approveOrRejectSection: function(SectionId, value) {
        this.$swal({
          title: ((value) ? '¿Aprobar sección?' : '¿Rechazar sección?'),
          text: "",
          type: "info",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`section/approve_reject_section`, {
              section_id: SectionId,
              approved: value
            })
            .then(response => {
              //this.$swal.fire("Success", ((value) ? "Sección aprobada" : "Sección rechazada"), "success");
              this.$swal.fire("", response.data.message, "success");
              this.getSections();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },

      sorting: function(column) {
        if(column == this.sortBy) {
          this.order = !this.order;
        }
        this.sortBy = column;
        this.isSort = true;
      },

      openAssignUserModal: function (sectionId) {
        this.showAssignUserModal = true;
        this.newAssignation.section_id = sectionId;
      },
      getUsers: function() {
        if (!this.vAutocompleteEmployer.search || this.vAutocompleteEmployer.search.length < 3) {
          return this.vAutocompleteEmployer.isLoading = true
        }
        return Api.get(`users?query=${this.vAutocompleteEmployer.search}`)
        .then(response => {
          this.vAutocompleteEmployer.employers = response.data.data ? response.data.data : response.data
          this.vAutocompleteEmployer.isLoading = false
        })
        .catch(error => this.errorHandling(error))
      },
      assignUser: function () {
        this.btnAssignUser = true;
        Api.post(`section/assign_user`, this.newAssignation)
        .then(response => {
          if (response.data.status == true) {
            this.$swal("", response.data.message, "success");
            this.showAssignUserModal = false;
            this.getSections();
          } else {
            this.$swal("", response.data.message, "error");
          }
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnAssignUser = false;
        })
      },
    },
    created() {
      this.getUserInfo();
      this.getSections();
      this.sectionTypeId = this.$route.params.section_type_id;
      this.sectionName = this.$route.params.section;
      this.debounceSearchSection = debounce(() => {
        this.getSections()
      }, 500);
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>