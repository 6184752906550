<template>
  <div>
    <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header min-vh-90 min-vw-100"
            :style="[(movie.contents_info.secondary_image_url && movie.contents_info.secondary_image_url.value != null) ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${movie.contents_info.secondary_image_url.value})`,backgroundSize:'cover'} : {'background': '#12141B'}]"
          >
            <v-row class="margin-view">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="text-white movie-description d-flex flex-column">
                <h1 class="text-white movie-title notranslate">{{ (movie.contents_info.title && movie.contents_info.title.value != null) ? movie.contents_info.title.value : '-' }}</h1>
                <span v-html="(movie.contents_info.main_description && movie.contents_info.main_description.value != null) ? movie.contents_info.main_description.value: '-'" class="text-white main-image-description incentive_body_span"></span>
                <br>
                <div class="information">
                  <h2 class="text-white width-text">Productora</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_producer && movie.contents_info.film_producer.value != null) ? movie.contents_info.film_producer.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Director</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_director && movie.contents_info.film_director.value != null) ? movie.contents_info.film_director.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Escritor</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_writer && movie.contents_info.film_writer.value != null) ? movie.contents_info.film_writer.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Director de Fotografia</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_photography && movie.contents_info.film_photography.value != null) ? movie.contents_info.film_photography.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Música</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_music && movie.contents_info.film_music.value != null) ? movie.contents_info.film_music.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Filmado en</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_filmed_at && movie.contents_info.film_filmed_at.value != null) ? movie.contents_info.film_filmed_at.value : '-' }}</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Duración(minutos)</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_duration && movie.contents_info.film_duration.value != null) ? movie.contents_info.film_duration.value : '-' }} minutos</p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Actores participantes</h2>
                  <p v-html="(movie.contents_info.film_stars && movie.contents_info.film_stars.value != null) ? movie.contents_info.film_stars.value : '---'" class="text-white text-justify ql-editor"></p>
                </div>
                <div class="information">
                  <h2 class="text-white width-text">Fecha de estreno</h2>
                  <p class="text-white fix-p">{{ (movie.contents_info.film_release_date && movie.contents_info.film_release_date.value != null) ? formatDate(movie.contents_info.film_release_date.value) : '-' }}</p>
                  <!-- <p class="text-white fix-p">{{ (movie.contents_info.film_release_date && movie.contents_info.film_release_date.value != null) ? movie.contents_info.film_release_date.value : '-' }}</p> -->
                </div>
                <br>
                <h2 class="text-white width-text mb-4">Donde ver</h2>
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                    cols="12"
                    class="mb-6"
                    v-if="movie.contents_info.film_netflix_url && movie.contents_info.film_netflix_url.value != null"
                  >
                    <a target="_blank" :href="movie.contents_info.film_netflix_url ? movie.contents_info.film_netflix_url.value : ''" >
                    <v-img
                      alt="netflix"
                      src="@/assets/img/logos/gray-logos/logo-netflix.svg"
                      class="logo-img"
                      positon="center center"
                    ></v-img>
                    </a>
                  </v-col>
                  <v-col
                    lg="2"
                    md="4"
                    cols="12"
                    class="mb-6"
                    v-if="movie.contents_info.film_hbo_url && movie.contents_info.film_hbo_url.value != null"
                  >
                    <a target="_blank" :href="movie.contents_info.film_hbo_url ? movie.contents_info.film_hbo_url.value : ''" >
                    <v-img
                      alt="netflix"
                      src="@/assets/img/logos/gray-logos/logo-hbo.svg"
                      class="logo-img"
                      positon="center center"
                    ></v-img>
                    </a>
                  </v-col>
                  <v-col
                    lg="2"
                    md="4"
                    cols="12"
                    class="mb-6"
                    v-if="movie.contents_info.film_disney_url && movie.contents_info.film_disney_url.value != null"
                  >
                    <a target="_blank" :href="movie.contents_info.film_disney_url ? movie.contents_info.film_disney_url.value : ''" >
                    <v-img
                      alt="netflix"
                      src="@/assets/img/logos/gray-logos/logo-disney.svg"
                      class="logo-img"
                      positon="center center"
                    ></v-img>
                    </a>
                  </v-col>
                  <v-col
                    lg="2"
                    md="4"
                    cols="12"
                    class="mb-6"
                    v-if="movie.contents_info.film_prime_url && movie.contents_info.film_prime_url.value != null"
                  >
                    <a target="_blank" :href="movie.contents_info.film_prime_url ? movie.contents_info.film_prime_url.value : ''" >
                    <v-img
                      alt="prime video"
                      src="@/assets/img/logos/gray-logos/logo-prime-video.svg"
                      class="logo-img"
                      positon="center center"
                    ></v-img>
                    </a>
                  </v-col>
                  <v-col
                    lg="2"
                    md="4"
                    cols="12"
                    class="mb-6"
                    v-if="movie.contents_info.film_additional_url && movie.contents_info.film_additional_url.value != null"
                  >
                    <a target="_blank" :href="movie.contents_info.film_additional_url ? movie.contents_info.film_additional_url.value : ''" >
                    <v-img
                      alt="netflix"
                      src="@/assets/img/logos/gray-logos/logo-link.svg"
                      class="logo-img"
                      positon="center center"
                    ></v-img>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="movie-trailer">
                <iframe v-if="movie.contents_info.film_trailer_url && movie.contents_info.film_trailer_url.value != null" :src="getNewUrl(movie.contents_info.film_trailer_url.value)"></iframe>
                <img v-else style="width: 100%; max-height: 80vh;" class="not-alter-image" :src="(movie.contents_info.main_image_url && movie.contents_info.main_image_url.value != null) ? movie.contents_info.main_image_url.value : ''"/>
                <v-col v-if="movie.contents_info.film_trailer_url && movie.contents_info.film_trailer_url.value != null" cols="12" class="my-0 py-0 justify-center" style="text-align: center; !important;">
                  <p class="parrafo text-white font-weight-bold" style="text-align: center;">¿No puedes ver el trailer? Prueba con el siguiente botón:</p>
                  <v-btn class="main_gradient_background text-white mx-auto" large :href="(movie.contents_info.film_trailer_url && movie.contents_info.film_trailer_url.value != null) ? movie.contents_info.film_trailer_url.value : ''" target="/blank">
                    Tráiler
                  </v-btn>          
                </v-col>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view margin-parrafo">
      <v-row class="margin-text" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Durango tierra del cine
            </h1>
            <p class="parrafo">Mira otras peliculas que se han filmado aqui en Durango.</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view margin-card-movie">
      <v-row>
        <v-col v-for="(movie, index) in movies.data" :key="index" cols="12" xs="12" sm="12" md="3" lg="3" class="padding-margin-card-movie">
            <a :href="`/movie/${movie.id}`">
                <div class="card border-radius-lg movie" :style="[movie.contents_info.main_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${movie.contents_info.main_image_url.value})`}: {'background': '#282c39'}]" >
                  <div class="content">
                      <h2 class="text-white title-movie">{{ movie.contents_info.title ? movie.contents_info.title.value : '-' }}</h2>
                      <span v-html="movie.contents_info.main_description ? movie.contents_info.main_description.value : '-'" class="text-white parrafo ql-editor">
                      </span>
                  </div>
                </div>
            </a>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Api from '@/api/Api';
export default{
  data(){
    return {
      brands: 
        {
          logo_netflix:{
            name: "netflix",
            image: require("@/assets/img/logos/gray-logos/logo-netflix.svg"),
          },
          logo_hbo:{
            name: "HBO",
            image: require("@/assets/img/logos/gray-logos/logo-hbo.svg"),
          },
          logo_disney:{
            name: "Disney+",
            image: require("@/assets/img/logos/gray-logos/logo-disney.svg"),
          },
          logo_prime:{
            name: "prime_video",
            image: require("@/assets/img/logos/gray-logos/logo-prime-video.svg"),
          },
          logo_enlace:{
            name: "enlace",
            image: require("@/assets/img/logos/gray-logos/logo-link.svg"),
          }
        },
      id: this.$route.params.id,
      movie: [],
      movies: [],
      itemsPerPage: 4
    }
  },
  created(){
    this.$emit('navbarMainPage',false);
    this.getMovie();
    this.getMovies();
  },
  methods:{
    getMovie(){
      Api.post(`section/get_specific_contents`,
      {
        section_id: this.id,
        contents: [
          'title',
          'main_image_url',
          'secondary_image_url',
          'film_producer',
          'film_director',
          'film_writer',
          'film_photography',
          'film_music',
          'film_filmed_at',
          'film_duration',
          'film_release_date',
          'film_stars',
          'film_trailer_url',
          'film_netflix_url',
          'film_hbo_url',
          'film_disney_url',
          'film_prime_url',
          'film_additional_url',
          'main_description',
        ]
      })
      .then(response => {
        if(response.data != 'Sección desactivada') {
          this.movie = response.data;
        } else {
          this.$router.back();
        }
      })
      .catch(error => this.errorHandling(error))
    },
    getMovies:function (page = 1){
        Api.get('section/get_section_by_section_type/5', {
          params: {
            page: page,
            paginate: this.itemsPerPage,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
          }
        })
        .then(response => {
          this.movies = response.data;
        })
        .catch(error => this.errorHandling(error))
    },
    getUrl(url){
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    },  
    getNewUrl(url){
      const videoId = this.getUrl(url);
      if(videoId != null){
        const newUrl = 'https://www.youtube.com/embed/' + videoId;
        return newUrl;
      }else{
        const newUrl = url
        return newUrl;
      }
    },

    formatDate: function (raw_date) {
      let day_birth = '00';
      let month_birth = '00';
      let year_birth = '0000';
      
      year_birth = raw_date.substring(0, 4);
      month_birth = raw_date.substring(5, 7);
      day_birth = raw_date.substring(8, 10);
      
      let month_birth_text = '';

      let final_date = '';

      switch (month_birth) {
        case '01': month_birth_text = 'enero'; break;
        case '02': month_birth_text = 'febrero'; break;
        case '03': month_birth_text = 'marzo'; break;
        case '04': month_birth_text = 'abril'; break;
        case '05': month_birth_text = 'mayo'; break;
        case '06': month_birth_text = 'junio'; break;
        case '07': month_birth_text = 'julio'; break;
        case '08': month_birth_text = 'agosto'; break;
        case '09': month_birth_text = 'septiembre'; break;
        case '10': month_birth_text = 'octubre'; break;
        case '11': month_birth_text = 'noviembre'; break;
        case '12': month_birth_text = 'diciembre'; break;
        default: month_birth_text = 'Mes'; break;
      }
      
      final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

      return final_date;
    }
  }
}
</script>
<style scoped>
  .movie-description{
    margin-top: 30vh;
  }
  .information{
    display: flex;
    margin-bottom: 1vh;
  }
  .information h2{
    font-size: 1.2em;
    font-weight: 500;
  }
  .information p{
    font-size: 1em;
    font-weight: 300;
    width: 60%;
  }
  .fix-p{
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
  }
  .margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
  }
  .width-text{
    width: 35%;
  }
  .movie-trailer{
    margin-top: 30vh;
  }
  iframe{
    height: 45vh;
    width: 40vw;
  }
  .movie-title{
    margin-bottom: 2vh;
    font-size: 1.5em;
  }
  .margin-parrafo{
    margin-bottom: 10vh;
  }
  .margin-card-movie{
    margin-bottom: 20vh;
  }
  .movie{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    padding: 0vw 2vw;
    width : auto;
    height : 50vh;
    overflow: auto;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-size: cover;
    background-position: 50%;
  }
  .margin-main-image{
    margin-bottom: 20vh;
  }
  .parrafo{
    font-weight: 300 !important;
  }
  .title-movie{
    font-size: 1.3em;
  }
  .padding-movie{
    padding-right: 3%;
  }
  .content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
  }
  .content:active{
    display: block;
  }
  .padding-margin-card-movie{
  padding-right: 3.7%;
  margin-bottom: 10vh;
  }
  .padding-margin-card-movie a{
      text-decoration: none;
  }
  *{
    font-family: "Roboto", sans-serif !important;
  }
  .fa, .fas {
    font-family: 'Font Awesome 5 Free' !important;
  }
  .logo-img{
    height: 60px;
    width: 200px; 
  }
  .main-image-description {
    font-size: 1em;
    font-weight: 300;
  }
  @media (max-width: 1366px) {
    .width-text{
      width: 21vw;
    }
    .margin-parrafo{
      margin-bottom: 12vh;
    }
  }
  @media (max-width: 600px) {
    .information{
      display: block;
      margin-bottom: 1vh;
    }
    .margin-parrafo{
      margin-bottom: 12vh;
    }
    iframe{
      height: 45vh;
      width: 80vw;
    }
    .movie-trailer{
      margin-top: 0;
    }
  }
  @media (max-width: 1366px) {
  .logo-img{
    height: 45px;
    width: 300px;
  }
}
</style>