<template>
  <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/LaPerla106-Panorama.jpg')}) !important; background-size: cover; background-position: 50%;`"
          >
          </div>
        </v-col>
      </v-row>
      <v-row class="margin-view">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="margin-search">
            <v-card class="border-radius-xl blur blur-transparent">
              <v-row class="card-margin" justify="center">
                <v-col cols="12" xs="12" sm="12" md="12" lg="4"
                  v-if="!actualDomain.includes('en.')"
                >
                  <label class="ms-0 text-white">Buscar</label>
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      cancel_gradient_border
                      input-white-text
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Buscar aquí"
                    clearable
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="16"
                        class="material-icons-round mt-n2 "
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <!-- 
                <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                    <label class="ms-0 text-white">Categoria</label>
                    <v-select
                      v-model="temporalCategory" 
                      color="white" 
                      dark 
                      item-text="name"
                      item-value="id"
                      :items="categories"
                      label="Categoría"
                      clearable
                      @change="getSubcategories(temporalCategory)">
                    </v-select>
                </v-col> 
                -->
                <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                    <label class="ms-0 text-white">Categoría</label>
                    <v-select
                      v-model="temporalSubCategory" 
                      color="white" 
                      dark 
                      item-text="name"
                      item-value="id"
                      :items="subcategories"
                      label="Categoría"
                      :disabled="subcategories.length < 1"
                      @change="getLocations()"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                  <v-btn 
                    class="mx-0 mt-5 main_gradient_background text-white text-transform-none"
                    x-large
                    @click="function () {
                      temporalSubCategory = 0;
                      search = '';
                    }"
                  >
                    Todas las categorías
                  </v-btn>
                </v-col>
                <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3" class="pt-8">
                    <v-btn 
                        class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                        x-large
                        @click="getLocations()"
                    >
                        Buscar
                    </v-btn>
                </v-col> -->
                <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pt-8">
                    <v-btn 
                        class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                        x-large
                        @click="getImagesAll()"
                    >
                        Ver todo
                    </v-btn>
                </v-col> -->
              </v-row>
            </v-card>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3">
          <v-card class="border-radius-xl blur blur-transparent">
            <v-list class="px-4 pt-8 pb-0 background-color-v-list">
              <v-list-group 
                  append-icon="fas fa-angle-down"
                  v-for="item in this.categories"
                  :key="item.id"
                  class="margin-categories px-0"
                  style="display: flow !important;">
                <template v-slot:activator>
                  <v-list-item-content class="py-0 px-0">
                    <p class="text-white mb-0" v-if="categories.length < 1">No hay locaciones disponibles</p>
                    <p class="text-white mb-0" v-else>{{ item.name }}</p>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="subcategorie in item.subcategories"
                  :key="subcategorie.id"
                  class="border-radius-lg">
                  <v-list-item-content class="py-0 px-0 ml-5 mt-0">
                    <a @click="getImagesCategory(subcategorie.id)" class="text-decoration-none ml-2">
                      <p class="text-white mb-0">{{ subcategorie.name }}</p>
                    </a>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col> -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" 
          v-if="temporalSubCategory == 0 && (search == '' || search == null)"
        >
          <v-row class="img-size mb-2 d-flex flex-row" style="height: 100% !important;">
            <v-col v-for="(subcategory, idSub) in subcategories" :key="idSub" cols="12" xs="12" sm="12" md="12" lg="3" class="pa-0 ma-0">
              <v-card class="text-white location-card" outlined color="transparent">
                <div class="mt-0 card-header position-relative z-index-2">
                  <div class="d-block blur-shadow-image">
                    <v-img
                      :src="(subcategory.image_url != null) ? subcategory.image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image-subcategory-2.jpg'"
                      class="img-middle img-fluid shadow border-radius-lg not-alter-image ma-3"
                      style="height: 250px;"
                      @click="temporalSubCategory = subcategory.id"
                    />
                    <!-- :src="(subcategory.image_url != null) ? subcategory.image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image-subcategory-1.svg'" -->
                    <!-- :src="(subcategory.image_url != null) ? subcategory.image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" -->
                  </div>
                </div>
                <h3 class="mt-0 mb-0 mx-3 px-4 text-white">
                  {{ subcategory.name }}
                </h3>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" xs="12" sm="12" md="12" lg="12"
          v-else
        >
          <!-- <v-card class="border-radius-xl blur blur-transparent card-size mb-6">
            <div class="px-4 pt-6 pb-0 title-locaciones">
              Locaciones
            </div>
          </v-card> -->
          <CoolLightBox 
            :items="this.additionalImages"
            :index="index"
            @close="index = null">
          </CoolLightBox>
          <v-row class="img-size mb-2 d-flex flex-row" style="height: 100% !important;">
            <template v-for="(location, idlocation) in locations.data">
              <v-col v-if="index < 3" :key="idlocation" cols="12" xs="12" sm="12" md="4" lg="4" class="pa-0 ma-0">
                <v-card class="text-white location-card" outlined color="transparent">
                  <div class="mt-0 card-header position-relative z-index-2">
                    <div class="d-block blur-shadow-image">
                      <template v-for="(image, imageIndex) in location.image">
                        <v-col v-if="image.slug == 'main_image_url'" :key="imageIndex" class="gallery">
                          <v-img
                            :src="image.value"
                            class="img-middle img-fluid shadow border-radius-lg not-alter-image"
                            style="height: 250px;"
                            @click="index = idlocation, getAdditionalImages(location.id)"
                          />
                        </v-col>
                      </template>
                    </div>
                  </div>
                  <h2 class="mt-0 mb-0 px-4 text-white notranslate">
                    {{ (location.other_contents.title && location.other_contents.title.value != null) ? location.other_contents.title.value : '' }}
                   
                  </h2>
                  <div class="text-broken">
                    <p v-html="(location.other_contents.main_description && location.other_contents.main_description.value != null) ? location.other_contents.main_description.value : ''" class="mb-0 px-4 text-justify"></p>
                  </div>
                  <v-card-actions>
                    <v-btn 
                      class="btn-gradient text-white"
                      large
                      @click="selectForm(), getLocationName(location.other_contents.title.value), getLocationId(location.id)"
                  >
                      Más información
                  </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-dialog
          v-model="dialog_form_not_logged"
          max-width="1000px"
        >
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-card class="card-dialog">
                <v-card-title>
                  <v-toolbar
                  flat
                  dark
                  color="#12141b"
                  >
                      <v-toolbar-title>Información de locación</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                      icon
                      dark
                      @click="dialog_form_not_logged = false, resetValidation(),clearForm()"
                      >
                      <v-icon>fas fa-times</v-icon>
                      </v-btn>
                  </v-toolbar>
                </v-card-title>
                <hr class="color-line">
                <v-form ref="form" lazy-validation>
                <div class="padding-text">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <h2 class="text-white">Datos de contacto</h2>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                          <label class="text-white">Nombre:</label>    
                          <v-text-field
                              v-model="newContact.name"
                              outlined
                              clearable
                              background-color="#292b31"
                              class="text-color"
                              color="white"
                              required
                              :rules="required"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                          <label class="text-white">Apellido Paterno:</label>    
                          <v-text-field
                              v-model="newContact.last_name"
                              outlined
                              clearable
                              background-color="#292b31"
                              class="text-color"
                              color="white"
                              required
                              :rules="required"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                          <label class="text-white">Apellido Materno:</label>    
                          <v-text-field
                              v-model="newContact.second_last_name"
                              outlined
                              clearable
                              background-color="#292b31"
                              class="text-color"
                              color="white"
                              required
                              :rules="required"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                          <label class="text-white">Email:</label>    
                          <v-text-field
                              v-model="newContact.email"
                              outlined
                              clearable
                              background-color="#292b31"
                              class="text-color"
                              color="white"
                              required
                              type="email"
                              :rules="required"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                        <label class="text-white">País</label>
                        <v-autocomplete
                          v-model="newContact.country"
                          :items="countries"
                          item-text="country"
                          item-value="country"
                          background-color="#292b31"
                          color="white"
                          outlined
                          dense
                          hide-details
                          required
                          :rules="[v=> !!(v && v.length) || 'campo requerido']"
                          class="font-size-input input-style mb-5 style_background text-color background-v-list"
                        ></v-autocomplete>
                      </v-col>
          
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                        <label class="text-white">Estado</label>
                        <v-autocomplete
                          v-model="newContact.state"
                          :items="states"
                          background-color="#292b31"
                          color="white"
                          outlined
                          dense
                          hide-details
                          required
                          :rules="[v=> !!(v && v.length) || 'campo requerido']"
                          class="font-size-input input-style mb-5 style_background text-color background-v-list"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                        <label class="text-white">Código de país:</label>
                        <vue-country-code 
                          class="input-style style_background country-select-code background-color-country"
                          v-model="newContact.phone_prefix"
                          :disabledFetchingCountry="true"
                          :enabledCountryCode="true"
                          :preferredCountries="preferredCountries"
                          @onSelect="selectCountryCode"
                        />
                      </v-col>
          
                      <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                        <label class="text-white">Teléfono:</label>
                        <v-text-field
                          v-model="newContact.phone"
                          background-color="#292b31"
                          clearable
                          outlined
                          mask="###.###.###-##"
                          dense
                          max="10"
                          hide-details
                          required
                          :rules="required"
                          class="font-size-input input-style mb-5 style_background text-color"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                        <v-checkbox 
                          color="white"
                          v-model="newContact.phone_whatsapp"
                          class="wa-checkbox-container"
                        >
                          <template v-slot:label>
                            <span style="color: white; font-weight: bold;">¿Whatsapp?</span>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                        <label class="text-white">Nombre de la locacion que solicita informacion:</label>
                        <v-text-field
                            v-model="newContact.body"
                            outlined
                            clearable
                            disabled
                            background-color="#292b31"
                            class="text-color"
                            color="white"
                        ></v-text-field>    
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn 
                            class="my-1 mx-0 button-gradient text-white text-transform-none" 
                            :loading="btnLoading"
                            @click="sendInformation"
                            >
                            Enviar
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                </div>
              </v-form>
            </v-card>
            </v-col>
        </v-dialog>
        <v-dialog
          v-model="dialog_form_logged"
          max-width="900px"
        >
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-card class="card-dialog">
                <v-card-title>
                  <v-toolbar
                  flat
                  dark
                  color="#12141b"
                  >
                      <v-toolbar-title>Información de locación</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                      icon
                      dark
                      @click="dialog_form_logged = false, resetValidation(),clearForm()"
                      >
                      <v-icon>fas fa-times</v-icon>
                      </v-btn>
                  </v-toolbar>
                </v-card-title>
                <hr class="color-line">
                <v-form ref="form" lazy-validation>
                <div class="padding-text">
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <h2 class="text-white">Datos de contacto</h2>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                          <h3 class="text-white">Recuerda que necesita que los datos de su perfil estén completos para enviar la información de la locación.</h3>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                        <label class="text-white">Nombre de la locacion que solicita informacion:</label>
                        <v-text-field
                            v-model="newContact.body"
                            outlined
                            clearable
                            disabled
                            background-color="#292b31"
                            class="text-color"
                            color="white"
                        ></v-text-field>    
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn 
                            class="my-1 mx-0 button-gradient text-white text-transform-none" 
                            :loading="btnLoading"
                            @click="sendInformation"
                            >
                            Enviar
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                </div>
                </v-form>
            </v-card>
          </v-col>
        </v-dialog>
    </v-row>
    <v-row class="margin-view margin-images-gallery"
      v-if="temporalSubCategory > 0"
    >
      <v-col cols="12" lg="12" class="d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination table-pagination"
          v-model="locations.current_page"
          :length="locations.last_page"
          total-visible="6"
          circle
          @input="getLocations"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Api from '@/api/Api';
import countries from '../../../util/countries';
import VueCountryCode from "vue-country-code";
import Vue from "vue";
import debounce from "lodash.debounce";
Vue.use(VueCountryCode);
export default {
  data() {
    return {
      dialog_form_not_logged: false,
      dialog_form_logged: false,
      active: false,
      categories: [],
      locations: [],
      index: null,
      additionalImages: [],
      btnLoading: false,
      required: [v=> !!v || 'Campo requerido'],
      countries: countries,
      newContact: {
        name: null,
        last_name: null,
        second_last_name: null,
        email : null,
        phone_prefix: null,
        phone: null,
        phone_whatsapp: false,
        country: null,
        state: null,
        motive: null,
        body: null,
        user_id: null
      },
      user: {},
      preferredCountries: [
        'mx',
        'us',
        'ca'
      ],
      logged: null,
      subcategories: [],
      temporalCategory: 0,
      temporalSubCategory: 0,
      search: '',
      itemsPerPage: 12
    };
  },
  created() {
    this.getCategories();
    //this.getImagesAll();
    this.$emit('navbarMainPage',false);
    this.debounceSearchSection = debounce(() => {
        this.getLocations()
    }, 500);
  },
  methods: {
    getCategories(){
      Api.get(`category/get_by_section_type/4`)
      .then(response => {
        this.categories = response.data;
        this.temporalCategory = this.categories[0].id;
        this.getSubcategories(this.temporalCategory);
      })
      .catch(error => this.errorHandling(error))
    },
    getSubcategories(category_id) {
      Api.get(`subcategory/get_by_category/${category_id}`)
      .then(response => {
        this.subcategories = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    getImagesAll(page = 1){
      Api.get(`/section/4/get_section_images_all`, {
        params: {
          page: page,
          query: this.search,
          paginate: this.itemsPerPage,
          pagination_required: 'Yes',
        }
      }).then( response => {
        this.locations = response.data;
      }).catch(error => this.errorHandling(error))
    },
    getLocations(page = 1){
      Api.get('/section/4/get_section_images_all', {
        params: {
          page: page,
          query: this.search,
          paginate: this.itemsPerPage,
          pagination_required: 'Yes',
          category: this.temporalCategory,
          subCategory: this.temporalSubCategory
        }
      })
      .then(response => {
        this.locations = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    getAdditionalImages($id){
      this.additionalImages = [];
      this.locations.data.forEach(element => {
        if(element.id == $id){
          let arrayImages = Object.values(element.image);
          arrayImages.forEach(image => {
            this.additionalImages.push(image.value);
            this.index = 0;
          });
        }
      });
    },
    resetValidation () {
        this.$refs.form.resetValidation()
    },
    validate () {
        this.$refs.form.validate()
    },
    sendInformation() {
      if(this.logged === 'not_logged'){
      this.validate();
        if(this.$refs.form.validate() == true){
          this.btnLoading = true;
          this.$swal({
            title: "Confirmación",
            text: "¿Esta seguro de enviar la información?",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.post('/contacto',this.newContact)
              .then(response => {
                if (response.data.message_type == "success") {
                  this.$swal('¡Todo listo!', 'Se ha enviado los datos, un administrador se contactara contigo.', "success");
                }
              })
              .catch(error => this.errorHandling(error))
              .finally(() => {
                if(this.logged === 'not_logged'){
                  this.btnLoading = false;
                  this.dialog_form_not_logged = false;
                  this.resetValidation();
                  this.clearForm()
                }
                if(this.logged === 'logged'){
                  this.btnLoading = false;
                  this.dialog_form_logged = false;
                  this.clearForm()
                }
              
              })
            } else {
              this.btnLoading = false;
            }
          });
        }
      }
      if(this.logged === 'logged'){
        this.btnLoading = true;
          this.$swal({
            title: "Confirmación",
            text: "¿Esta seguro de enviar la información?",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.post('/contacto',this.newContact)
              .then(response => {
                if (response.data.message_type == "success") {
                  this.$swal('¡Todo listo!', 'Se ha enviado los datos, un administrador se contactara contigo.', "success");
                }
              })
              .catch(error => this.errorHandling(error))
              .finally(() => {
                if(this.logged === 'logged'){
                  this.btnLoading = false;
                  this.dialog_form_logged = false;
                  this.clearForm()
                }
              
              })
            } else {
              this.btnLoading = false;
            }
          });
      }
    },
    selectCountryCode: function ({ name, iso2, dialCode }) {
      this.newContact.phone_prefix = name + iso2;
      this.newContact.phone_prefix = dialCode;
    },
    selectCountry: function ({ name, iso2, dialCode }) {
      this.newContact.country = dialCode + iso2;
      this.newContact.country = name;
    },
    getLocationName(name){
      this.newContact.body = name;
    },
    getLocationId(id){
      this.newContact.motive = id;
    },
    getUserData(){
      if(this.logged === "logged"){
        this.newContact.name = this.user.Personal_information.name;
        this.newContact.last_name = this.user.Personal_information.last_name;
        this.newContact.second_last_name = this.user.Personal_information.second_last_name;
        this.newContact.email = this.user.User.email;
        this.newContact.phone_prefix = this.user.Contact_information.phone_prefix;
        this.newContact.phone = this.user.Contact_information.phone;
        this.newContact.phone_whatsapp = this.user.Contact_information.phone_whatsapp;
        this.newContact.country = this.user.Contact_information.country;
        this.newContact.state = this.user.Contact_information.state;
        this.newContact.user_id = this.user.User.id;
      }
    },
    clearForm(){
      var clear = {
        name: null,
        last_name: null,
        second_last_name: null,
        email : null,
        phone_prefix: null,
        phone: null,
        phone_whatsapp: false,
        country: null,
        state: null,
        motive: null,
        body: null,
        user_id: null
      }
      this.newContact = clear;
    },
    selectForm(){
      Api.get('/auth/user_is_logged').then(response => {
        this.logged = response.data;
        if (this.logged === 'not_logged') {
          return this.dialog_form_not_logged = true;
        }
        if (this.logged === 'logged') {
          Api.get('/auth/logged_user').then(response => {
              this.user = response.data;
              this.getUserData();
            })
          .catch(error => this.errorHandling(error))
          return this.dialog_form_logged = true;
        }
      }).catch(error => this.errorHandling(error));
    }
  },
  computed: {
    states() {
      if(!this.newContact.country) return [];
      return this.countries.find(item => item.country == this.newContact.country).states;
    },
    actualDomain () {
      return window.location.host;
    }
  },
  watch:{
    dialog(){
      this.$emit('dialog',this.dialog);
    },
    temporalCategory (value) {
      this.temporalSubCategory = 0;
      //this.getLocations();
      this.getSubcategories(value);
    },
    temporalSubCategory () {
      this.locations = [];
      this.getLocations();
    },
    search () {
      this.temporalSubCategory = 0;
      this.debounceSearchSection();
    },
  }
}
</script>
<style scoped>
.location-card p {
  margin-bottom: 0 !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.margin-main-image{
  margin-bottom: 10vh;
}
.card-size{
  height: 12vh;
}
.title-locaciones{
  display: flex;
  align-items: center;
  font-size: 2.5em;
  color: white;
}
.background-color-v-list{
  background: var(--transparent) !important;
  color: var(--transparent) !important;
}
.categories-center{
  display: flex;
  justify-items: center !important;
}
.text-categories{
  font-size: 1.3em;
}
.img-size{
  min-height: 50vh;
}
.main-image{
  min-height: 43vh;
}
.gallery:hover{
  transform: scale(1.1);
  cursor: pointer;
}
.full-image{
  width: 100%;
  height: 100vh;
  background-color: (0,0,0,0.9);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.full-image span{
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 30px;
  color:#FFFFFF;
  cursor: pointer;
}
.full-image img{
  width: 90%;
  max-width: 600px;
}
.fa-angle-down{
  color: #FFFFFF;
}
.margin-categories{
  margin-bottom: 5%;
}
.margin-categories .v-list-item__content {
  margin-bottom: 5%;
}
.margin-images-gallery{
  margin-top: 10vh;
}
.img-size-full{
  background-size: cover;
}

.lightbox .lb-image {
  width: 100%!important;
  height: auto!important;
}

.button-gradient{
    background: linear-gradient(90deg, #50005E 0%, #C71C4F 100%);
}
.card-dialog{
  background: #12141b;
}
.padding-text{
    padding-left: 5vh;
    padding-right: 5vh;
}
.color-line{
    color: #FFFFFF;
}
.text-color >>> .v-text-field__slot input{
  color: #666b7f;
}
.text-color >>> .theme--light.v-counter{
  color: white !important;
}
.text-color >>> .v-select__slot input{
  color: #666b7f;
}
.text-color >>> .v-text-field__slot textarea{
  color: #666b7f !important;
}
.country-select-code {
  width: 100%;
  border-style: solid;
  border-width: thin 0 0 0;
  height: 50%;
  border-radius: 0.375rem;
}
.background-color-country{
  background-color: #292b31 !important;
}
.background-color-country >>> .dropdown:hover{
  background-color: #292b31 !important;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.margin-search{
  margin-bottom: 10vh;
}
.card-margin{
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.v-btn:not(.v-btn--round).v-size--x-large {
    min-width: 100% !important;
}
@media (max-width: 600px) {
  .img-size{
    min-height: 50vh;
  }
}
@media (max-width: 1264px) {
  .img-size{
    min-height: 50vh;
  }
}
</style>