/**
 * Global mixin for navigation in Vue Router.
 * @type {import('vue').Mixin}
 */
const GlobalMixins = {
  methods: {
    /**
     * Navigate to a specific route using Vue Router.
     * 
     * @param {string} routePath 
     * @param {object} [params={}] 
     */
    navigateTo(routePath, params = {}) {
      this.$router.push({ path: routePath, params })
    },

    /**
     * Return a error handling with laravel's error format 
     * 
     * @param {Array} error
     */
    errorHandling(error) {
      if (!error.response) {
        console.error('Error sin respuesta de axios', error)
        throw error
      }
      if (error.response.status === 401) {
        this.navigateTo('/login')
        throw error
      }

      if (error.response.status === 422 && error.response.data.message_text) {
        this.$alertify.error(error.response.data.message_text)
        throw error
      }

      if (error.response.status === 403) {
        this.$alertify.error('Error, no tienes permiso para realizar esa acción')
        throw error
      }

      if (!error.response.data) {
        this.$alertify.error('Error desde el servidor')
        throw error
      }

      if (error.response.data.message_type === 'error') {
        this.$alertify.error(error.response.data.message_text)
        throw error
      }

      if (!error.response.data.message_text) {
        if (error.response.status === 422) {
          for (const field in error.response.data.errors) {
            error.response.data.errors[field].forEach(error => this.$alertify.error(error))
          }
        } else {
          if (error.response.status == 503) {
            this.$alertify.error('Actualización en proceso. Por favor espere unos minutos y reintente')
          } else {
            this.$alertify.error('Error Inesperado, reintente')
            console.error(error.response)
          }
        }
        throw error
      }

      if (error.response.statusText !== 'OK') {
        this.$alertify.error('Error Inesperado! Intente volver a iniciar sesión.')
        throw error
      }

      this.$alertify.error('Error Inesperado! Intente volver a iniciar sesión.')
      throw error
    }
  }
}

export default GlobalMixins
