<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 py-10 ">
      <v-row class="justify-content-center border-radius-lg">
        <v-col cols="12" class="pa-0">
          <v-btn 
            class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
            to="/admin/experience_management/new/0"
          >
            Nueva certificación
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                Experiencia profesional
              </h5>
              <p class="text-body mb-0">
                Gestiona la información de tu formación profesional
              </p>
            </v-col>
            <v-col cols="11" lg="4" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar..."
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Organización donde se obtuvo</th>
                <!-- <th>Descripción</th> -->
                <th>Fecha de inicio</th>
                <th>Fecha de término</th>
                <th>Tipo de experiencia</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody class="notranslate">
              <tr v-for="(experience, index) in experiences.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ experience.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ experience.title_name }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ experience.organization }}
                    </span>
                  </div>
                </td>

                <!-- 
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 20vw !important;">
                      {{ experience.description }}
                    </span>
                  </div>
                </td> 
                -->

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 20vw !important;">
                      {{ formatDate(experience.start_date) }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 20vw !important;">
                      {{ formatDate(experience.end_date) }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white table-description-cell" style="max-width: 20vw !important; text-align: center;">
                      {{ experience.slug_es }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Editar información"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/experience_management/edit/'+experience.id"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            edit
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Borrar registro" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteRecord(experience.id)">
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
              <tr v-if="experiences.data.length == 0">
                <td colspan="7" class="pa-3 align-center text-secondary" style="text-align: center;">
                  Aún no hay registros
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ experiences.from }} a {{ experiences.to }} de {{ experiences.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="experiences.current_page"
                :length="experiences.last_page"
                circle
                @input="getExperiences"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            experiences: {{ experiences }}
            search: {{ search }}
          </pre>
        </v-col>   
        -->

      </v-row>

      </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  //import ActionsCompanyModal from "./ActionsCompanyModal.vue";

  export default {
    name: "experiences-management",
    components: {
      //ActionsCompanyModal
    },
    data() {
      return {
        LoggedUser: {},

        experiences: {
          data: []
        },
        search: '',

        //used to crud operations on a record
        btnLoadingNewRecord: false,

        showNewRecordModal: false,
        currentCompanyId: null,
        actionEdit: false,
      };
    },
    computed: {
      //
    },
    watch: {
      LoggedUser(){
        this.getExperiences();
      },
      search () {
        this.debounceSearchRecord();
      },
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.LoggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      getExperiences: function (page = 1) {
        Api.get(`proffesional_experience/${this.LoggedUser.User.id}`, {
          params: {
            page: page,
            search: this.search
          }
        })
        .then(response => {
          this.experiences = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      openAddRecordModal: function () {
        this.currentCompanyId = null;
        this.actionEdit = false;
        this.showNewRecordModal = true;
      },
      openUpdateRecordModal: function (companyId) {       
        this.currentCompanyId = companyId;
        this.actionEdit = true;
        this.showNewRecordModal = true;
      },

      deleteRecord: function (itemId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "Esta acción no se puede revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`proffesional_experience/${itemId}`)
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getExperiences();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      
      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      }
    },
    created() {
      this.getUserInfo();
      this.debounceSearchRecord = debounce(() => {
        this.getExperiences();
      }, 500);
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>