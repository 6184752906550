<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent my-5">
        <div class="col-lg-12">
          <div class="card">
            <div>

              <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
                class="form-control" 
              />
              <input accept=".pdf" @change="getFile" style="display: none;" type="file" ref="input_file_pdf"
                class="form-control" 
              />

              <v-row class="text-white ma-1" justify="space-around">
                <v-col cols="12" lg="12" class="mt-1">
                  <label class="text-white new-section-title">{{$route.params.action == 'edit' ? 'Editar ' : 'Nuevo(a) '}} {{ sectionName }}</label>
                </v-col>
                <v-col cols="12" lg="12" 
                  :class="$route.params.section_type_id == 12 ? 'mb-0 pb-0' : 'mb-5'"
                >
                  <v-text-field
                    v-model="temporalValues.title"
                    :label="$route.params.section_type_id == 12 ? 'Nombre' : 'Titulo'"
                    :placeholder="$route.params.section_type_id == 12 ? 'Nombre' : 'Título'"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('title', temporalValues.title, null)"
                  />
                </v-col>
                <v-col cols="12" lg="12" class="mb-5"
                  v-if="$route.params.section_type_id == 12"
                >
                  <v-text-field
                    v-model="temporalValues.directory_job_role"
                    label="Puesto de trabajo"
                    placeholder="Presidente"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('directory_job_role', temporalValues.directory_job_role, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="flex-column"
                  v-if="$route.params.section_type_id == 1
                  || $route.params.section_type_id == 3
                  || $route.params.section_type_id == 4
                  || $route.params.section_type_id == 5
                  || $route.params.section_type_id == 6
                  || $route.params.section_type_id == 7
                  || $route.params.section_type_id == 12
                  || $route.params.section_type_id == 14"
                >
                  <v-row  class="justify-content-center fill-space mb-5">
                    <v-col cols="12" class="pa-0" style="height: 15%">
                      <label class="text-white">Imágen principal</label>
                    </v-col>
                    <v-col cols="12" class="pa-0" style="height: 85%">
                      <div class="border-radius-lg main-img-container">
                        <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                        <v-img :src="(temporalValues.main_image_url != null) ? temporalValues.main_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                        class="img-view-size pa-2 border-radius-lg">
                          <v-btn 
                            icon
                            class="my-1 mx-0 text-white text-transform-none floating-button" 
                            @click="pickFile(null, 'main_image_url')"
                            title="Subir imágen principal"
                            :loading="mainImageBtnLoading"
                          >
                            <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                              edit
                            </v-icon>
                          </v-btn>
                        </v-img>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <label class="text-secondary ml-5">Tamaño máximo de 1.95 MB</label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="4" class="flex-column" 
                  v-if="$route.params.section_type_id == 1
                  || $route.params.section_type_id == 3
                  || $route.params.section_type_id == 5
                  || $route.params.section_type_id == 14"
                >
                  <v-row  class="justify-content-center fill-space mb-5">
                    <v-col cols="12" class="pa-0" style="height: 15%">
                      <label class="text-white">Imágen secundaria (fondo)</label>
                    </v-col>
                    <v-col cols="12" class="pa-0" style="height: 85%">
                      <div class="border-radius-lg main-img-container">
                        <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                        <v-img :src="(temporalValues.secondary_image_url != null) ? temporalValues.secondary_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                        class="img-view-size pa-2 border-radius-lg">
                          <v-btn 
                            icon
                            class="my-1 mx-0 text-white text-transform-none floating-button" 
                            @click="pickFile(null, 'secondary_image_url')"
                            title="Subir imágen secundaria"
                            :loading="mainImageBtnLoading"
                          >
                            <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                              edit
                            </v-icon>
                          </v-btn>
                        </v-img>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <label class="text-secondary ml-5">Tamaño máximo de 1.95 MB</label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="4" 
                  v-if="$route.params.section_type_id == 2
                  || $route.params.section_type_id == 4"
                >
                  <v-row>
                    <v-col cols="12" class="flex-column">
                      <v-text-field
                        v-if="$route.params.section_type_id == 4"
                        v-model="temporalValues.address"
                        url=""
                        label="Dirección"
                        color="text-white"
                        class="input-style mb-5 input-white-text"
                        hide-details
                        @input="addContent('address', temporalValues.address, null)"
                      />
                      <v-text-field
                        v-if="$route.params.section_type_id == 2
                        || $route.params.section_type_id == 4"
                        v-model="temporalValues.phone"
                        label="Teléfono"
                        placeholder="6181234567"
                        mask="(###)-###-##-##"
                        color="text-white"
                        class="input-style mb-5 input-white-text"
                        hide-details
                        @input="addContent('phone', temporalValues.phone, null)"
                      />
                      <v-text-field
                        v-if="$route.params.section_type_id == 2
                        || $route.params.section_type_id == 4"
                        v-model="temporalValues.email"
                        label="Email"
                        placeholder="correo@ejemplo.com"
                        color="text-white"
                        class="input-style mb-5 input-white-text"
                        hide-details
                        @input="addContent('email', temporalValues.email, null)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="4"
                  v-if="$route.params.section_type_id != 11
                  && $route.params.section_type_id != 12"
                >
                  <v-row>
                    <v-col cols="12" class="flex-column"
                    :class="$route.params.section_type_id == 3 ? 'py-0' : ''">
                      <v-select
                        v-model="temporalValues.category"
                        item-text="name"
                        item-value="id"
                        :items="categories"
                        label="Categoría"
                        required
                        class="font-size-input input-style input-white-text"
                        :class="$route.params.section_type_id != 3 ? 'mb-5' : 'mb-0'"
                        @change="getSubcategories(temporalValues.category)"
                        :disabled="$route.params.section_type_id == 1 && loggedUser.Roles[0] == 'Producer'"
                      />
                      <v-select
                        v-model="temporalValues.subcategory"
                        item-text="name"
                        item-value="id"
                        :items="subcategories"
                        label="Subcategoría"
                        required
                        class="font-size-input input-style input-white-text"
                        :class="$route.params.section_type_id != 3 ? 'mb-5' : 'mb-0'"
                        :disabled="subcategories.length < 1"
                      />
                    </v-col>

                    <v-col cols="12" class="flex-column py-0"
                    v-if="$route.params.section_type_id == 3">
                      <v-text-field
                        v-model="temporalValues.new_writer"
                        label="Autor de la noticia"
                        color="text-white"
                        class="input-style mb-5 input-white-text"
                        hide-details
                        @input="addContent('new_writer', temporalValues.new_writer, null)"
                      />
                    </v-col>

                    <v-col cols="12" class="flex-column"
                    v-if="$route.params.section_type_id == 3">
                      <v-text-field
                        v-model="temporalValues.new_date"
                        type="datetime-local"
                        label="Fecha de la noticia"
                        color="text-white"
                        class="input-style mb-5 input-white-text"
                        @input="addContent('new_date', temporalValues.new_date, null)"
                      />
                    </v-col>
                    
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-10 border-radius-lg my-5" justify="space-around"
                v-if="$route.params.section_type_id == 1
                || $route.params.section_type_id == 14"
              >
                <v-col cols="12" class="pa-0 ml-5">
                  <label class="text-white font-weight-bold">{{$route.params.section_type_id == 1 ? 'Información del trabajo' : 'Información de la solicitud'}}</label>
                </v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">
                  <v-text-field
                    v-model="temporalValues.job_provider_request_location"
                    label="Ubicación / dirección"
                    placeholder="Durango, Durango"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('job_provider_request_location', temporalValues.job_provider_request_location, null)"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">
                  <v-text-field
                    v-model="temporalValues.job_provider_request_start_date"
                    type="date"
                    label="Fecha de inicio"
                    class="input-style mb-5 input-white-text"
                    @input="addContent('job_provider_request_start_date', temporalValues.job_provider_request_start_date, null)"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">
                  <v-text-field
                    v-model="temporalValues.job_provider_request_duration"
                    label="Duración del contrato"
                    placeholder="3 meses"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('job_provider_request_duration', temporalValues.job_provider_request_duration, null)"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">
                  <v-text-field
                    v-model="temporalValues.job_provider_request_salary"
                    label="Salario (MXN)"
                    placeholder="200 / día"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('job_provider_request_salary', temporalValues.job_provider_request_salary, null)"
                  />
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-5 border-radius-lg my-5" justify="space-around"
                v-if="$route.params.section_type_id == 5"
              >
                <v-col cols="12" lg="12" class="mt-1 py-0">
                  <label class="text-white font-weight-bold">Personal</label>
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_producer"
                    label="Productor"
                    placeholder="productor"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_producer', temporalValues.film_producer, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_director"
                    label="Director"
                    placeholder="director"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_director', temporalValues.film_director, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_writer"
                    label="Escritor"
                    placeholder="Escritor"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_writer', temporalValues.film_writer, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_photography"
                    label="Director de fotografía"
                    placeholder="Director de fotografía"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_photography', temporalValues.film_photography, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_music"
                    label="Compositor"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_music', temporalValues.film_music, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_filmed_at"
                    label="Filmado en..."
                    placeholder="Durango"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_filmed_at', temporalValues.film_filmed_at, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_duration"
                    label="Duración (minutos)"
                    type="number"
                    min="1"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_duration', temporalValues.film_duration, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_release_date"
                    label="Fecha de lanzamiento"
                    color="text-white"
                    type="date"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_release_date', temporalValues.film_release_date, null)"
                  />
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around"
                v-if="$route.params.section_type_id == 5"
              >
                <v-col cols="12" lg="12" class="mt-1">
                  <label class="text-white">Actores participantes</label>
                </v-col>
                <hr style="width: 100%; border: 1px solid #7b809a;">
                <v-col cols="12" lg="12" class="mt-1">
                  <VueEditor 
                    v-model="temporalValues.film_stars"
                    class="input-style ma-0 pa-1 input-white-text white-background"
                    @input="addContent('film_stars', temporalValues.film_stars, null)"
                    :editorOptions="optionsEditor"
                  />
                  <!--
                  <v-textarea
                    v-model="temporalValues.film_stars"
                    color="text-white"
                    class="input-style ma-0 pa-1 input-white-text"
                    hide-details
                    @input="addContent('film_stars', temporalValues.film_stars, null)"
                  /> 
                  -->
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-5 border-radius-lg my-5" justify="space-around"
                v-if="$route.params.section_type_id == 5"
              >
                <hr class="horizontal mb-0 light line_gradient_background"/>
                <v-col cols="12" lg="12" class="mt-1 py-0">
                  <label class="text-white font-weight-bold">Enlaces multimedia</label>
                </v-col>
                <v-col cols="12" lg="12" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_trailer_url"
                    label="Enlace del trailer"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_trailer_url', temporalValues.film_trailer_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_netflix_url"
                    label="Enlace de Nexflix"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_netflix_url', temporalValues.film_netflix_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_hbo_url"
                    label="Enlace de HBO"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_hbo_url', temporalValues.film_hbo_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_disney_url"
                    label="Enlace de Disney+"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_disney_url', temporalValues.film_disney_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_prime_url"
                    label="Enlace de Prime video"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_prime_url', temporalValues.film_prime_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.film_additional_url"
                    label="Enlace adicional"
                    placeholder="https://www.youtube.com/watch?v=ejemplo"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('film_additional_url', temporalValues.film_additional_url, null)"
                  />
                </v-col>
              </v-row>

              <v-row 
                v-if="$route.params.section_type_id == 1
                || $route.params.section_type_id == 11
                || $route.params.section_type_id == 14"
                class="text-white mx-1 mt-1 border-radius-lg my-5" justify="space-around"
              >
                <v-col cols="12" lg="12" class="mt-1 mb-0 pb-0">
                  <label class="text-white font-weight-bold">Adjuntar documento</label>
                </v-col>
                <v-col cols="12" lg="12" class="mt-0"
                  v-if="$route.params.section_type_id == 1
                  || $route.params.section_type_id == 14"
                >
                  <v-btn 
                    class="mb-1 mx-0 main_gradient_background text-white text-transform-none" 
                    @click="pickFile(null, 'job_file')"
                    :loading="btnLoading"
                  >
                    Elegir archivo
                  </v-btn>
                  <label v-if="temporalValues.job_file != null" class="text-secondary ml-5">{{ (temporalValues.job_file != null) ? temporalValues.job_file : 'Sin archivo elegido' }}</label>
                </v-col>
                <v-col cols="12" lg="12" class="mt-0"
                  v-if="$route.params.section_type_id == 11"
                >
                  <v-btn 
                    class="mb-1 mx-0 main_gradient_background text-white text-transform-none" 
                    @click="pickFile(null, 'permit_file')"
                    :loading="btnLoading"
                  >
                    Elegir archivo
                  </v-btn>
                  <label v-if="temporalValues.permit_file != null" class="text-secondary ml-5">{{ (temporalValues.permit_file != null) ? temporalValues.permit_file : 'Sin archivo elegido' }}</label>
                </v-col>

                <v-col cols="12" lg="12" class="mt-1"
                  v-if="$route.params.section_type_id == 1
                  || $route.params.section_type_id == 14"
                >
                  <v-text-field
                    v-model="temporalValues.job_url"
                    label="URL"
                    placeholder="www.ejemplo.com"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('job_url', temporalValues.job_url, null)"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="mt-1">
                  <!-- -->
                </v-col>
              </v-row>

              <v-row 
                v-if="$route.params.section_type_id == 1
                  || $route.params.section_type_id == 3
                  || $route.params.section_type_id == 4
                  || $route.params.section_type_id == 6
                  || $route.params.section_type_id == 7
                  || $route.params.section_type_id == 14"
                class="text-white mx-1 mt-1 border-radius-lg blur blur-transparent my-5" justify="space-around"
              >
                <v-col cols="12" lg="12" class="mt-1">
                  <v-btn 
                    class="my-1 mx-0 main_gradient_background text-white text-transform-none" 
                    @click="pickFile(temporalValues.newImagePriority, 'additional_image_url')"
                    :loading="btnLoading"
                  >
                    Agregar foto
                  </v-btn>
                </v-col>
                <hr style="width: 100%; border: 1px solid #7b809a;">
                <v-col cols="12" lg="12" class="mt-1">
                  <v-row>
                    <template v-for="(image, index) in newSection.contents">
                    <v-col
                      v-bind:key="index"
                      v-if="image.slug == 'additional_image_url'"
                      class="d-flex child-flex"
                      cols="2"
                    >
                      <v-img
                        :src="image.value"
                        aspect-ratio="1"
                        cover
                        class="bg-grey-lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey-lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        <v-btn 
                          v-if="image.slug == 'additional_image_url'"
                          icon
                          class='my-1 mx-0 text-white text-transform-none floating-button'
                          title="Remove image"
                          @click="removeImage(index, image)"
                        >
                          <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                            delete
                          </v-icon>
                        </v-btn>
                      </v-img>
                    </v-col>
                  </template>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around">
                <v-col cols="12" lg="12" class="mt-1">
                  <label class="text-white"
                    v-if="$route.params.section_type_id != 2"
                  >{{ $route.params.section_type_id == 5 ? 'Sinopsis' : 'Descripción'}}</label>
                  <label class="text-white"
                    v-if="$route.params.section_type_id == 2"
                  >Descripción (información mostrada de forma pública)</label>
                </v-col>
                <hr style="width: 100%; border: 1px solid #7b809a;">
                <v-col cols="12" lg="12" class="mt-1">
                  <VueEditor 
                    v-model="temporalValues.main_description"
                    class="input-style ma-0 pa-1 input-white-text white-background"
                    @input="addContent('main_description', temporalValues.main_description, null)"
                    :editorOptions="optionsEditor"
                  />
                  <!--
                  <v-textarea
                    v-model="temporalValues.main_description"
                    color="text-white"
                    class="input-style ma-0 pa-1 input-white-text"
                    hide-details
                    @input="addContent('main_description', temporalValues.main_description, null)"
                  />
                  -->
                </v-col>
              </v-row>

              <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around"
                v-if="$route.params.section_type_id == 2"
              >
                <v-col cols="12" lg="12" class="mt-1">
                  <label class="text-white">Información completa (solo visible para los proyectos aceptados)</label>
                </v-col>
                <hr style="width: 100%; border: 1px solid #7b809a;">
                <v-col cols="12" lg="12" class="mt-1">
                  <VueEditor 
                    v-model="temporalValues.body_incentive"
                    class="input-style ma-0 pa-1 input-white-text white-background"
                    @input="addContent('body_incentive', temporalValues.body_incentive, null)"
                    :editorOptions="optionsEditor"
                  />
                </v-col>
              </v-row>

              <v-row class="text-white ma-1" justify="space-around"
                v-if="$route.params.section_type_id == 1
                || $route.params.section_type_id == 14"
              >
                <v-col cols="12" lg="12" class="mt-1 py-0">
                  <label class="text-white font-weight-bold">Información de contacto</label>
                </v-col>
                <v-col cols="12" lg="12" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.job_provider_request_contact_name"
                    label="Nombre del contacto"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('job_provider_request_contact_name', temporalValues.job_provider_request_contact_name, null)"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.email"
                    label="Email"
                    placeholder="correo@ejemplo.com"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('email', temporalValues.email, null)"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="mt-1">
                  <v-text-field
                    v-model="temporalValues.phone"
                    label="Teléfono"
                    placeholder="6181234567"
                    mask="(###)-###-##-##"
                    color="text-white"
                    class="input-style mb-5 input-white-text"
                    hide-details
                    @input="addContent('phone', temporalValues.phone, null)"
                  />
                </v-col>
              </v-row>

              <v-row class="text-white ma-1" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 px-0">
                  <v-row class="text-white ma-0 px-0" justify="space-around"
                    v-if="$route.params.section_type_id == 1
                    || $route.params.section_type_id == 14"
                  >
                    <v-col cols="12" class="pa-0">
                      <label class="text-white font-weight-bold">Fechas de vigencia de la publicación</label>
                    </v-col>
                    <v-col cols="12" lg="6" class="mt-1">
                      <v-text-field
                        v-model="newSection.start_date"
                        type="datetime-local"
                        label="Fecha de inicio"
                        class="input-style mb-5 input-white-text"
                      />
                    </v-col>
                    <v-col cols="12" lg="6" class="mt-1">
                      <v-text-field
                        v-model="newSection.end_date"
                        type="datetime-local"
                        label="Fecha de término"
                        class="input-style mb-5 input-white-text"
                      />
                    </v-col>
                  </v-row>
                </v-col> 
                <v-col cols="12" lg="2" class="mt-1">
                  <label class="text-white">¿Activar por defecto?</label>
                  <div class="d-flex">
                    <label class="text-white mt-5 mx-3">No</label>
                    <v-switch
                      v-model="newSection.section_active"
                      class="mb-5 input-white-text switch-gradient-color"
                    />
                    <label class="text-white mt-5 mx-3">Sí</label>
                  </div>
                  
                </v-col> 
                <!--                 
                <v-col cols="12" lg="2" class="mt-1">
                  
                </v-col>  
                -->
                <v-col cols="12" lg="3" class="mt-1">
                  <v-btn 
                    class="my-5 mx-1 cancel_gradient_border text-white text-transform-none transparent-background"
                    @click="cancelSection"
                  >
                    Cancelar
                  </v-btn>
                  <!--
                  <button type="button" class="py-1 px-2 btn btn-outline-secondary text-white"
                  @click="cancelSection">
                    Cancelar
                  </button>
                  -->
                  <v-btn v-if="$route.params.action == 'new'" 
                    class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                    @click="saveSection" 
                    :loading="saveSectionLoading"
                  >
                    Guardar
                  </v-btn>
                  <v-btn v-else 
                    class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                    @click="updateSection" 
                    :loading="saveSectionLoading"
                  >
                    Actualizar
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <!--
          categories: {{ categories }}
          temporalValues: {{ temporalValues }}
          sectionName: {{ sectionName }}
	        newImagePriority: {{ temporalValues.newImagePriority }}
          btnLoading: {{ btnLoading }}
          mainImageBtnLoading: {{ mainImageBtnLoading }}
          section_type_id: {{ newSection.section_type_id }}
          category: {{ temporalValues.category }}
          subcategory: {{ temporalValues.subcategory }}
          editSectionCategoryId: {{ editSectionCategoryId }}
          category: {{ temporalValues.category }}
          subcategory: {{ temporalValues.subcategory }}
          fileSlug: {{ fileSlug }}
        -->
        <!--
        <pre style="width: 100%; height: 300px; overflow-y: scroll;" class="text-white">
          newSection: {{ newSection }}
          temporalValues: {{ temporalValues }}
        </pre>
        -->
        
      </v-row>
    </div>
  </section>
  </template>
  
  <script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';

  //import Dropzone from "@/components/Dropzone.vue";

  import Vue from "vue";
  import VueSweetalert2 from "vue-sweetalert2";
  Vue.use(VueSweetalert2);

  import { VueEditor } from "vue2-editor";

  //import HtmlEditor from "../../Ecommerce/Products/Widgets/HtmlEditor.vue";

  //import { erorrHandle } from '@/assets/js/errorHandler';

  export default {
    name: "Dashboard",
    components: {
      //HtmlEditor
      VueEditor
    },
    props: {
      //
    },
    data() {
      return {
        loggedUser: {},


        switchColor: 'linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%) !important',
        sectionName: '',
        categories: [],
        subcategories: [],

        temporalValues: {
          title: null,
          address: null,
          phone: null,
          main_description: null,

          category: null,
          subcategory: null,

          main_image_url: null,
          secondary_image_url: null,

          newImagePriority: 0,
          
          //Used in job_offers (id 1)
          job_file: null,
          job_url: null,

          //Used both in job_offers (id 1) and providers request (id 14)
          job_provider_request_location: null,
          job_provider_request_start_date: null,
          job_provider_request_duration: null,
          job_provider_request_salary: null,
          job_provider_request_contact_name: null,

          //Used in films (id 5)
          film_producer: null,
          film_director: null,
          film_writer: null,
          film_photography: null,
          film_music: null,
          film_filmed_at: null,
          film_duration: null,
          film_release_date: null,
          film_stars: null,
          film_netflix_url: null,
          film_hbo_url: null,
          film_disney_url: null,
          film_prime_url: null,
          film_trailer_url: null,
          film_additional_url: null,

          //Used in news (id 3)
          new_date: null,
          new_writer: null,

          //Used in directory (id 12)
          directory_job_role: null,

          //Used in incentives (id 2)
          body_incentive: null,

          //used in permits
          permit_file: null,
        },

        editSectionCategoryId: null,
        
        newSection: {
          section_type_id: null,
          start_date: null,
          end_date: null,
          section_active: true,
          contents: []
        },

        fileSlug: '',
        errors: '',
        resultMessage: '',
        btnLoading: false,
        mainImageBtnLoading: false,
        saveSectionLoading: false,


        optionsEditor: {
          theme: 'snow',
          formats: [
            'bold',
            'italic',
            'list',
            'align',
          ],
          modules: {
            toolbar: [
              ['bold', 'italic'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ align: "left" }, { align: "center" }, { align: "right"}, { align: "justify"}]
            ],
          }
        },
      };
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      getCategories: function () {
        Api.get(`category/get_by_section_type/${(this.$route.params.section_type_id == 6 || this.$route.params.section_type_id == 7 || this.$route.params.section_type_id == 14) ? 13 : this.$route.params.section_type_id}`)
        .then(response => {
          this.categories = response.data;
          if (this.$route.params.section_type_id == 1 && this.loggedUser.Roles[0] == 'Producer') {
            let jobOfferCategory = this.categories.find(element => element.name === 'Work in Film Durango');

            this.temporalValues.category = jobOfferCategory.id;
            this.getSubcategories(jobOfferCategory.id);
          }
        })
        .catch(error => this.errorHandling(error))
      },        
      getSubcategories: function (category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },   
      addContent: function (slug, value, priority) {
        //create object
        let newContent = {};
        newContent = {
          slug: slug,
          value: value,
          priority: priority
        }
        
        //check if the content object already exists in the array
        let exists = false;
        this.newSection.contents.forEach(function(item) {
          if (item.slug === slug) { exists = true; }
        })

        if (value != '' || (value == '' && this.$route.params.action == 'edit')) {

          //if doesn't exist, it's directly added to the array. 
          //If already exists, delete the previous version and add the new one
          if (exists == true) {
            let previousContent = this.newSection.contents.find(element => element.slug === slug);
            /*
            let previousContent = this.newSection.contents.filter(obj => {
              return obj.slug === slug;
            })
            */
            const index = this.newSection.contents.indexOf(previousContent);
            this.newSection.contents.splice(index, 1);
          }

          this.newSection.contents.push(newContent);

        } else {
          //if the input is empty, delete the content object from the array
          let result = this.newSection.contents.find(element => element.slug === slug);
          /*
          let result = this.newSection.contents.filter(obj => {
            return obj.slug === slug;
          })
            */
          const index = this.newSection.contents.indexOf(result);
          this.newSection.contents.splice(index, 1);
        }
      },
      addImage: function (value, priority, name, slug) {
        let newContent = {
          slug: slug,
          name: name,
          value: value,
          priority: priority,
        }

        if (this.$route.params.action == 'edit') {
          newContent.edit = 'yes';
        }

        //Checks if a main or secondary image, or a document is already uploaded
        //Replace if exists
        if (slug !== 'additional_image_url') {

          let result = this.newSection.contents.find(element => 
          element.slug === slug);

          if (result != undefined) {
            if (this.$route.params.action == 'new') {
              /*
              Api.delete(`files/delete_file/${result.name}`)
              .catch(error => this.errorHandling(error))
              */
            }

            const index = this.newSection.contents.indexOf(result);
            this.newSection.contents.splice(index, 1);
          }
        }

        this.newSection.contents.push(newContent);
      },
      removeImage: function (index, image) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            if (this.$route.params.action == 'edit' && !Object.prototype.hasOwnProperty.call(this.newSection.contents[index], 'edit')) {
              
              Api.post(`contents/mark_or_unmark_image_for_deletion`, {
                content_id: image.id,
                marked_for_deletion: true
              })
              .catch(error => this.errorHandling(error))
            }

            this.newSection.contents.splice(index, 1);
          } 
        });
      },
      pickFile: function (filePriority, slug) {
        //if the file is a pdf, pick it from the file input. Otherwise pick it from the image one
        if (slug == 'job_file' || slug == 'permit_file') {
          this.$refs.input_file_pdf.click();
        } else {
          this.$refs.input_file.click();
        }

        this.fileSlug = slug;
      },
      getFile: function (e) {
        this.btnLoading = !this.btnLoading;
        this.mainImageBtnLoading = !this.mainImageBtnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {

          let formData = new FormData();
          formData.append('file', file)
          formData.append('slug', this.fileSlug)
          formData.append('path', 'sections/media_files/')

          Api.post('files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            //this.$swal(response.data.message, '', "success");
            this.$alertify.success(response.data.message)
            //this.$swal(response.data.message, response.data.file_url, "success");

            //if the new file is an additional image, include the image priority on its database record
            if (this.fileSlug == 'additional_image_url') {
              this.addImage(response.data.file_url, this.temporalValues.newImagePriority, response.data.file_name, this.fileSlug);
              this.temporalValues.newImagePriority ++;
            } else {
              this.addImage(response.data.file_url, null, response.data.file_name, this.fileSlug);

              switch (this.fileSlug) {
                case 'main_image_url':
                  this.temporalValues.main_image_url = response.data.file_url;
                break;
                case 'secondary_image_url':
                  this.temporalValues.secondary_image_url = response.data.file_url;
                break;
                case 'job_file':
                  this.temporalValues.job_file = response.data.file_name;
                break;
                case 'permit_file':
                  this.temporalValues.permit_file = response.data.file_name;
                break;
              }
            }
            
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoading = !this.btnLoading;
            this.mainImageBtnLoading = !this.mainImageBtnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.btnLoading = !this.btnLoading;
          this.mainImageBtnLoading = !this.mainImageBtnLoading;
        }
        /*
        .catch(error => {
          this.errors = erorrHandle(error);
          this.$swal(this.errors.mainMessage, this.errors.errorMessage, "error");
        }) 
        */
      },
      saveSection: function () {
        if ((this.temporalValues.category != null && this.temporalValues.subcategory != null) 
        || (this.$route.params.section_type_id == 11 || this.$route.params.section_type_id == 12)) {

          if ((this.$route.params.section_type_id != 4 && this.$route.params.section_type_id != 12 && this.$route.params.section_type_id != 5)
            || ((this.$route.params.section_type_id == 4 || this.$route.params.section_type_id == 12 || this.$route.params.section_type_id == 5) && this.newSection.contents.find(element => element.slug === 'main_image_url') != null)
          ) {

            this.saveSectionLoading = !this.saveSectionLoading;
            Api.post('section', this.newSection)
            .then(response => {
              this.resultMessage = response.data.message;

              if (this.$route.params.section_type_id != 11 && this.$route.params.section_type_id != 12) {

                Api.post('section_category', {
                  modelable_id: response.data.new_section_id,
                  modelable_type: 'App\\Models\\Section',
                  category_id: this.temporalValues.category,
                  subcategory_id: this.temporalValues.subcategory
                })
                .then(response => {
                  this.resultMessage += '<br>' + response.data.message;
                  this.$swal("¡Todo listo!", this.resultMessage, "success");
                  //setTimeout(function () {
                    this.$router.push({ 
                      name: 'sections', 
                      params: { 
                        section: this.sectionName, 
                        section_type_id: this.newSection.section_type_id 
                      } 
                    })
                  //}, 1500);
                })
                .catch(error => this.errorHandling(error))

              } else {
                this.$swal("¡Todo listo!", this.resultMessage, "success");
                  //setTimeout(function () {
                    this.$router.push({ 
                      name: 'sections', 
                      params: { 
                        section: this.sectionName, 
                        section_type_id: this.newSection.section_type_id 
                      } 
                    })
                  //}, 1500);
              }
            })
            .catch(error => this.errorHandling(error))
            .finally(() => {
              this.saveSectionLoading = !this.saveSectionLoading;
            })
          } else {
            this.$swal('Datos faltantes', 'Debe añadir una imágen principal', "error");
          }
        } else {
          this.$swal('Datos faltantes', 'Debe escoger una categoría y una subcategoría', "error");
        }
      },
      cancelSection: function () {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            if (this.$route.params.action == 'new') {
              /*
              this.newSection.contents.forEach(function(item) {
                if (item.slug == 'main_image_url' 
                  || item.slug == 'secondary_image_url' 
                  || item.slug == 'additional_image_url' 
                  || item.slug == 'job_file' 
                  ) { 
                  Api.delete(`files/delete_file/${item.name}`)
                  .catch(error => this.errorHandling(error))
                }
              })
              */
              this.$swal.fire("Success", "Registro de " + this.sectionName + " cancelado", "success");
            } else {
              /*
              this.newSection.contents.forEach(function(item) {
                if (
                  (item.slug == 'main_image_url' 
                  || item.slug == 'secondary_image_url' 
                  || item.slug == 'additional_image_url' 
                  || item.slug == 'job_file')
                  && Object.prototype.hasOwnProperty.call(item, 'edit')) { 
                  Api.delete(`files/delete_file/${item.name}`)
                  .catch(error => this.errorHandling(error))
                }
              })
              */
              this.$swal.fire("Success", "Actualización de " + this.sectionName + " cancelada", "success");
            }

            this.$router.push({ 
              name: 'sections', 
              params: { 
                section: this.sectionName, 
                section_type_id: this.newSection.section_type_id 
              } 
            })
          }
        });
      },

      //Edit section functions
      editSection: function (sectionId) {
          Api.get(`section/${sectionId}/edit`)
          .then(response => {
            this.newSection = response.data;

            if (this.newSection != '') {
              this.newSection.contents.forEach(item => {
                if (item.slug == 'additional_image_url') {
                  if (item.priority > this.temporalValues.newImagePriority) { this.temporalValues.newImagePriority = item.priority }
                } else {
                  this.temporalValues[item.slug] = item.value;
                }
              });
              //if there's at least one image already uploaded, place the priority control variable 
              //one position after the upper priority read, so it won't be overwrited
              // if (this.temporalValues.newImagePriority >= 0) {
              //   this.temporalValues.newImagePriority ++;
              // }
            }

            Api.post(`section_category/get_categories`, {
              modelable_id: this.$route.params.section_id,
              modelable_type: "App\\Models\\Section"
            })
            .then(response => {
              if (response != undefined) {
                this.temporalValues.category = response.data[0].category_id;
                this.temporalValues.subcategory = response.data[0].subcategory_id;
                this.editSectionCategoryId = response.data[0].id;
                this.getSubcategories(this.temporalValues.category);
              }
            })
            .catch(error => this.errorHandling(error))

          })
          .catch(error => this.errorHandling(error));
      },
      updateSection: function () {
        if ((this.$route.params.section_type_id != 4 && this.$route.params.section_type_id != 12)
        || ((this.$route.params.section_type_id == 4 || this.$route.params.section_type_id == 12) && this.newSection.contents.find(element => element.slug === 'main_image_url') != null)
          ) {
          this.saveSectionLoading = !this.saveSectionLoading;
          Api.patch(`section/${this.newSection.id}`, this.newSection)
          .then(response => {
            this.resultMessage = response.data.message;

            if (this.$route.params.section_type_id != 11 && this.$route.params.section_type_id != 12) {

              Api.patch(`section_category/${this.editSectionCategoryId}`, {
                modelable_id: this.newSection.id,
                modelable_type: 'App\\Models\\Section',
                category_id: this.temporalValues.category,
                subcategory_id: this.temporalValues.subcategory
              })
              .then(() => {
                //this.resultMessage += '<br>' + response.data.message;
                this.$swal("¡Todo listo!", this.resultMessage, "success");
                //setTimeout(function () {
                  this.$router.push({ 
                    name: 'sections', 
                    params: { 
                      section: this.sectionName, 
                      section_type_id: this.newSection.section_type_id 
                    } 
                  })
                //}, 1500);
              })
              .catch(error => this.errorHandling(error))

            } else {

              this.$swal("¡Todo listo!", this.resultMessage, "success");
              //setTimeout(function () {
                this.$router.push({ 
                  name: 'sections', 
                  params: { 
                    section: this.sectionName, 
                    section_type_id: this.newSection.section_type_id 
                  } 
                })
              //}, 1500);

            }
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.saveSectionLoading = !this.saveSectionLoading;
          })
        } else {
          this.$swal('Datos faltantes', 'Debe añadir una imágen principal', "error");
        }
      }
    },
    created() {
      this.getUserInfo();
      this.sectionName = this.$route.params.section;
      this.newSection.section_type_id = this.$route.params.section_type_id;
      this.getCategories();
      if (this.$route.params.action == 'edit') {
        this.newSection.id = this.$route.params.section_id;
        this.editSection(this.newSection.id);
      }
      this.temporalValues.newImagePriority ++;
    }
  };
  </script>
  
  <style scoped>
    .main-img-container {
      width: 290px !important;
      height: 240px !important;
      background-color: #FFFFFF;
    }
    .img-view-size {
      width: 100%;
      height: 100%;
    }
  </style>