<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <div
          class="page-header main-image min-vw-100"
          :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/rocas.jpg')}) !important; background-size: cover; background-position: 50%;`"
        >
        </div>
      </v-col>
    </v-row>
    <v-row class="margin-view margin-text-box">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
        <v-card class="border-radius-xl card-dialog">
          <div class="padding-text">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <h2 v-if="actualIncentive != null" class="text-white mb-5">Formulario para postularse al incentivo"</h2>
                <h2 v-if="actualIncentive != null" class="text-white mb-5 notranslate">"{{actualIncentive.contents_info.title.value}}"</h2>
                <label class="text-white">
                  Para conocer más detalles sobre este incentivo y saber si eres candidato para recibir sus beneficios, necesitamos que nos proporciones la información del proyecto de filmografía con el que quieres participar.
                </label>
                <hr class="horizontal my-4 light line_gradient_background"/>
                <h3 class="text-white">Datos del proyecto de filmografía</h3>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <label class="text-white">Nombre:</label>    
                <v-text-field
                  v-model="newFilmProject.name"
                  placeholder="titulo"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Tipo:</label>    
                <v-select
                  v-model="newFilmProject.film_type"
                  :items="selectFilmTypes"
                  item-text="text"
                  item-value="value"
                  color="white"
                  background-color="#292b31"
                  class="text-color mb-2 font-size-input input-style input-white-text-public"
                  outlined
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Origen:</label>    
                <v-select
                  v-model="newFilmProject.film_origin"
                  :items="selectFilmOrigin"
                  item-text="text"
                  item-value="value"
                  color="white"
                  background-color="#292b31"
                  class="text-color mb-2 font-size-input input-style input-white-text-public"
                  outlined
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Etapa actual:</label>    
                <v-select
                  v-model="newFilmProject.film_stage"
                  :items="selectFilmStage"
                  item-text="text"
                  item-value="value"
                  color="white"
                  background-color="#292b31"
                  class="text-color mb-2 font-size-input input-style input-white-text-public"
                  outlined
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Presupuesto total en Durango (USD):</label>    
                <v-text-field
                  v-model="newFilmProject.total_budget_in_dgo"
                  type="number"
                  placeholder="000000000.00"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Presupuesto facturable en Durango (USD):</label>    
                <v-text-field
                  v-model="newFilmProject.total_billiable_in_dgo"
                  type="number"
                  placeholder="000000000.00"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Dias de filmación en interiores:</label>    
                <v-text-field
                  v-model="newFilmProject.film_days_indoors"
                  type="number"
                  placeholder="0"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Dias de filmación en exteriores:</label>    
                <v-text-field
                  v-model="newFilmProject.film_days_outdoors"
                  type="number"
                  placeholder="0"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                />
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Dias totales de filmación en Durango:</label>    
                <v-text-field
                  v-model="newFilmProject.total_film_days_in_dgo"
                  type="number"
                  placeholder="0"
                  color="white"
                  background-color="#292b31"
                  class="text-color input-style mb-5 input-white-text-public"
                  hide-details
                  outlined
                  clearable
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn 
                class="my-1 mx-0 button-gradient text-white text-transform-none" 
                :loading="btnLoading"
                @click="sendPostulation"
              >
                Enviar
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>  
  </v-container>
</template>
<script>
import Api from '@/api/Api';

export default {
    props: {
    },
    data() {
      return {
        incentive_id: null,
        user: {
          Personal_information: {
            complete_name: "  "
          },
          Roles: [
            null
          ]
        },
        actualIncentive: {
          contents_info: {
            title: {
              value: null
            }
          }
        },

        btnLoading: false,

        selectFilmTypes: [
          {text: 'Trabajo cinematográfico', value: 'cinematographic work'},
	        {text: 'Ficción/documental', value:'fiction/documental'},
          {text: 'Producto audiovisual', value: 'audiovisual product'},
          {text: 'Postproducción y VFX', value: 'postproduction & VFX'}
        ],
        selectFilmOrigin: [
          {text: 'Nacional (México)', value: 'national'},
	        {text: 'Extranjero', value:'foreign'},
          {text: 'Coproducción', value: 'coproduction'}
        ],
        selectFilmStage: [
          {text: 'Pre producción', value: 'pre-production'},
	        {text: 'Producción', value:'production'},
          {text: 'Post producción', value: 'post-production'}
        ],

        newFilmProject: {
          name: null,
          film_type: null,
          film_origin: null,
          film_stage: null,
          total_budget_in_dgo: 0.00,
          total_billiable_in_dgo: 0.00,
          film_days_indoors: 0,
          film_days_outdoors: 0,
          total_film_days_in_dgo: 0,
          user_id: null,
        }
        
      };
    },
    created() {
      this.$emit('navbarMainPage',false);
    },
    watch: {
      'newFilmProject.film_days_indoors' () {
        this.newFilmProject.total_film_days_in_dgo = parseInt(this.newFilmProject.film_days_indoors) + parseInt(this.newFilmProject.film_days_outdoors);
      },
      'newFilmProject.film_days_outdoors'() {
        this.newFilmProject.total_film_days_in_dgo = parseInt(this.newFilmProject.film_days_indoors) + parseInt(this.newFilmProject.film_days_outdoors);
      }
    },
    mounted() {
      this.getUserInfo();
      this.incentive_id = this.$route.params.incentive_id;
      this.getIncentiveInfo();
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/user_is_logged').then(response => {
          if (response.data === 'not_logged') {
            this.$router.push({ 
              name: 'SignUpCover'
            })
          } else {
            Api.get('/auth/logged_user').then(response => {
              this.user = response.data;
              this.newFilmProject.user_id = this.user.User.id;
              if (this.user.Roles[0] != 'Producer') {
                this.$swal('Acceso denegado', 'No se puede postular a un incentivo si no es una casa productora. Pruebe con otro usuario.', "error");
                this.$router.push({ 
                  name: 'Incentivos'
                })
              }
            })
            .catch(error => this.errorHandling(error))
          }
        })
        .catch(error => this.errorHandling(error))
      },
      getIncentiveInfo: function () {
        Api.post('/section/get_specific_contents', {
          section_id: this.incentive_id,
          contents: [
            'title'
          ]
        })
        .then(response => {
          this.actualIncentive = response.data;
        })
      },
      sendPostulation: function () {
        this.btnLoading = true;
        this.$swal({
          title: "Confirmación",
          text: "¿Desea postularse con este proyecto?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post('film_project',this.newFilmProject)
            .then(response => {
              if (response.data.status == true) {
                Api.post('vinculations', {
                  section_id: this.incentive_id,
                  slug: 'incentive',
                  user_id: this.user.User.id,
                  project_id: response.data.project_id,
                  status: 'interesado'
                })
                .then(response => {
                  if (response.data.status == true) {
                    this.$swal('¡Todo listo!', 'Se ha registrado tu información correctamente. Puedes consultar su estado desde la tabla de "postulaciones a incentivos" de tu panel de administrador.', "success");
                    this.navigateTo('/admin/incentive_full_information/'+response.data.vinculation_id);
                  }
                })
                .catch(error => this.errorHandling(error))
                .finally(() => {
                  this.btnLoading = false;
                })
              }
            })
            .catch(error => this.errorHandling(error))
          } else {
            this.btnLoading = false;
          }
        });
      }
    },
};
</script>
<style scoped>
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.margin-title{
  margin-bottom: 5vh;
}
.button-gradient{
    background: linear-gradient(90deg, #50005E 0%, #C71C4F 100%);
}
.margin-button{
  margin-top: 7vh;
}
.margin-text-box{
  margin-bottom: 20vh;
}
.fa-times{
    background-image: linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%) !important;
    color: transparent !important; 
    background-clip: text !important;
}
.card-dialog{
    background: #12141b;
}
.padding-text{
    padding-left: 5vh;
    padding-right: 5vh;
}
.color-line{
    color: #FFFFFF;
}
.text-color >>> .v-text-field__slot input{
  color: #666b7f;
}
</style>