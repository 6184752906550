<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="border-radius-xl py-1 card-centered card-background blur blur-transparent" width="400px">
        <div>
          <div class="card-padding text-center border-radius-lg mx-4"> 
            <h2 class="text-center font-weight-bold mb-2 text-secondary z-index-2 px-3 text-white text-centered">
              Iniciar sesión
            </h2>
          </div>
          <div class="card-padding text-left">
            <label class="text-white">Email</label>    
            <v-text-field
              v-model="email"
              color="#e91e63"
              outlined
              dense
              hide-details
              class="font-size-input input-style style_background"
            ></v-text-field>
            <label class="text-white">Contraseña</label>    
            <v-text-field
              v-model="password"
              color="#e91e63"
              outlined
              dense
              hide-details
              type="password"
              class="font-size-input input-style style_background"
            ></v-text-field>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
                text-white
                gradient_background
              "
              small
              :loading="loadingButton"
              @click="loginUser"
            >INGRESAR</v-btn>
            <!-- <v-switch color="#da2160">
              <template v-slot:label>
                <span style="color: white;">Recordarme</span>
              </template>
            </v-switch> -->
          </div>
          <p class="text-body text-center font-weight-light mt-3 mb-0 text-centered">
            <span class="account-question">
              ¿No tienes una cuenta?
            </span> 
            <a
              @click="navigateTo('/register')"
              class=" text-decoration-none font-weight-bold link-format"
            >
              Registrarse
            </a>
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';

  export default {
    name: "page-layout",
    components: {},
    data() {
      return {
        email: null,
        password: null,
        loadingButton: false,

        previousPath: null,
      };
    },
    methods: {
      loginUser: function() {
        Csrf.getCookie().then(() => {
          this.loadingButton = true;
          Api.post('auth/login', {
            email: this.email,
            password: this.password
          })
          .then(response => {
            if (response.data.status == true) {
              localStorage.setItem('token', response.data.token);
              this.navigateTo(this.previousPath);
            } else {
              this.$swal('', response.data.message, "error");
            }
          })
          .catch(error => {
            if (error.message.includes('status code 422')) {
              this.$swal('', 'Las credenciales ingresadas son incorrectas. Intentelo nuevamente', "error");
            } else {
              this.errorHandling(error)
            }
          })
          .finally(() => this.loadingButton = false)
        })
      }
    },
    mounted() {
      this.previousPath = localStorage.getItem('LS_ROUTE_KEY');
    }
  };
</script>

<style>
  :root {
    --social-network-gradient: linear-gradient(270deg, #990004 0%, #460017 100%);
  }

  html {
    background-color: #12141B;
    background-image: "@/assets/img/bg-pricing.jpg";
    background-repeat: no-repeat;
    background-attachment: fixed;
    /*background-position: bottom right;*/
    background-size: cover;
    height: 100%;
    font-family: 'roboto';
  }

  #film_footer a {
    text-decoration: none !important;
  }

  .link-format {
    text-decoration: none;
    color: #FF1361;
    font-weight: bold;
  }

  .card-centered {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
  }

  .card-background {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .gradient_background {
    background: var(--social-network-gradient);
    color: #FFFFFF;
  }

  .cancel_gradient_background {
    background: linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
    color: #FFFFFF;
  }

  .style_background {
    background-color: #FFFFFF;
    margin-bottom: 10px !important;
  }

  .text-white {
    color: #FFFFFF;
  }

  .text-centered {
    text-align: center;
  }

  .margin_bottom {
    margin-bottom: 10px !important;
  }

  .html_select_format {
    -webkit-appearance: auto;
    border-radius: 6px;
    display: inline-block;
    border: 1px solid #bbb;
    text-align: center;
    width: 70%;
    height: 40px;
    color: #7b809a;
    font-size: 11pt;
  }

  .background_format {
    background-attachment: fixed;
    background-size: cover;
  }

  .account-question {
    color: white;
    font-weight: bolder;
    font-family: 'Roboto';
  }
  
</style>