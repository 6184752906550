<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="user != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Editar información de perfil</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Información personal</label>
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.Personal_information.name"
                label="Nombre"
                placeholder="Nombre"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.Personal_information.last_name"
                label="Apellido paterno"
                placeholder="Apellido paterno"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.Personal_information.second_last_name"
                label="Apellido materno"
                placeholder="Apellido materno"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col v-if="user != null && (user.Roles[0] == 'Crew')" cols="12" lg="12" class="mb-5">
              <v-text-field
                v-model="user.Personal_information.imdb_url"
                label="Link perfil de IMDB"
                placeholder="imdb.com"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Información de contacto</label>
            </v-col>
            <v-col cols="12" lg="4" class="my-5">
              <label class="mr-3">Código de país</label>
              <vue-country-code 
                class="input-style style_background country-select-code"
                style="color: #12141B"
                v-model="user.Contact_information.phone_prefix"
                :disabledFetchingCountry="true"
                :enabledCountryCode="true"
                :preferredCountries="preferredCountries"
                @onSelect="selectCountryCode"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field
                v-model="user.Contact_information.phone"
                label="Teléfono"
                placeholder="6181234567"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>

            <v-col cols="12" lg="4">
              <div>
                <v-checkbox 
                  color="white"
                  v-model="user.Contact_information.phone_whatsapp"
                  class="wa-checkbox-container"
                >
                  <template v-slot:label>
                    <span class="text-white">¿Whatsapp?</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" lg="4">
              <v-autocomplete
                v-model="user.Contact_information.country"
                label="País"
                :items="countries"
                item-text="country"
                item-value="country"
                hide-details
                class="input-style mb-5 input-white-text"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="4">
              <v-autocomplete
                v-model="user.Contact_information.state"
                label="Estado"
                :items="states"
                hide-details
                class="input-style mb-5 input-white-text"
              ></v-autocomplete>
            </v-col>
          </v-row>
          
          <hr v-if="user != null && (user.Roles[0] == 'Crew')" class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="user != null && (user.Roles[0] == 'Crew')" class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Mis categorías de crew</label>
            </v-col>
            
            <!-- <v-col cols="12" lg="5" class="mb-5">
              <v-select
                v-model="newCategory.category"
                item-text="name"
                :items="categories"
                label="Categoría"
                required
                return-object
                class="font-size-input input-style input-white-text"
                @change="getSubcategories(newCategory.category.id)"
              />
            </v-col>   -->

            <v-col cols="12" lg="10" class="mb-5">
              <v-select
                v-model="newCategory.subcategory"
                item-text="name"
                :items="subcategories"
                label="Categoría"
                required
                return-object
                class="font-size-input input-style input-white-text"
                :disabled="subcategories.length < 1"
              />
            </v-col>

            <v-col cols="12" lg="2" class="mb-5">
              <v-btn icon text rel="tooltip" title="Añadir categoría" 
                class="btn btn-primary btn-simple btn-xs white-button"
                @click="addCategory()">
                <v-icon end class="material-icons-outlined me-sm-1" size="20">
                  add
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12"> <!-- Table body -->
              <table class="table-responsive table-sections text-white notranslate">
                <thead class="text-white">
                  <tr>
                    <th>Categoría</th>
                    <!-- <th>Subcategoría</th> -->
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(category, index) in user_categories" :key="index">
                    <!-- <td>
                      <div class="d-flex align-center ms-2">
                        <span class="font-weight-normal text-white">
                          {{ category.category_text }}
                        </span>
                      </div>
                    </td> -->

                    <td>
                      <div class="d-flex align-center ms-2">
                        <span class="font-weight-normal text-white">
                          {{ category.subcategory_text }}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="d-flex align-center ms-2">
                        <span class="font-weight-normal text-white">
                          <v-layout>                                              
                            <v-btn icon text rel="tooltip" title="Borrar categoría" 
                              class="btn btn-primary btn-simple btn-xs white-button"
                              @click="deleteCategory(index)">
                              <v-icon end class="material-icons-outlined me-sm-1" size="20">
                                close
                              </v-icon>
                            </v-btn>
                          </v-layout>
                        </span>
                      </div>
                    </td>

                  </tr>

                  <tr v-if="user_categories.length == 0">
                    <td colspan="5" class="pa-3 align-center text-secondary" style="text-align: center;">
                      Aún no hay Categorías
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>

          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1">
            <v-col cols="12" lg="9" class="d-flex align-right">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="3" class="d-flex align-right">
              <v-btn 
                class="my-5 mx-1 cancel_gradient_border text-white text-transform-none transparent-background"
                @click="cancelConfirm"
              >
                Cancelar
              </v-btn>
              <v-btn 
                class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="updateInfo"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--       
      <pre style="height: 300px; overflow-y: scroll;" class="text-white">
        user_categories: {{ user_categories }}
        user: {{ user }}
      </pre>  
       -->

    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';
  import axios from 'axios';

  import countries from '../../../util/countries';

  export default {
    name: "edit-personal-information",
    components: {
      //
    },
    data() {
      return {
        user: null,
        preferredCountries: [
          'mx',
          'us',
          'ca'
        ],
        countries: countries,


        categories: [],
        subcategories: [],


        user_categories: [],

        newCategory: {
          category: 'Crew',
          subcategory: null
        },

      };
    },
    computed: {
    states() {
        if(!this.user.Contact_information.country) return [];
        return this.countries.find(item => item.country == this.user.Contact_information.country).states;
      }
    },
    watch: {
      user(value) {
        if (value != null) {
          //
        }
      },
    },
    methods: {
      async loadMoreItems({ page }) {
        try {
          this.loading = true;
          const response = await axios.get(`your-api-endpoint?page=${page}&search=${this.searchInput}`);
          this.items = [...this.items, ...response.data.items];
          this.serverItemsLength = response.data.totalItems;
          this.loading = false;
        } catch (error) {
          console.error(error);
          this.loading = false;
        }
      },
      async searchItems() {
        this.pagination.page = 1;
        await this.loadMoreItems({ page: 1 });
      },
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.user = response.data;

          if (this.user != null && (this.user.Roles[0] == 'Crew')){
            this.getUserCategories(this.user.User.id);
            if (this.user.Personal_information != null 
            && 
              (this.user.Personal_information.name == null 
              || this.user.Personal_information.last_name == null
              || this.user.Personal_information.second_last_name == null
              || this.user.Personal_information.imdb_url == null)
            )
            this.$swal("Aviso", "Para poder aparecer en la lista pública de miembros de crew, es necesario que ingrese su nombre, apellidos, enlace a su perfil de imdb y por lo menos una categoría a la que pertenezca.", "warning");
          }
        })
        .catch(error => this.errorHandling(error))
      },

      selectCountryCode: function ({ name, iso2, dialCode }) {
        this.user.Contact_information.phone_prefix = name + iso2;
        this.user.Contact_information.phone_prefix = '+'+dialCode;
      },

      updateInfo: function () {
        Api.patch(`personal_information/${this.user.User.id}`, {
          name: this.user.Personal_information.name,
          last_name: this.user.Personal_information.last_name,
          second_last_name: this.user.Personal_information.second_last_name,
          imdb_url: this.user.Personal_information.imdb_url
        })
        .then(response => {
          if (response.data.status == true) {
            Api.patch(`contact_information/${this.user.User.id}`, {
              phone_prefix: this.user.Contact_information.phone_prefix,
              phone: this.user.Contact_information.phone,
              phone_whatsapp: this.user.Contact_information.phone_whatsapp,
              country: this.user.Contact_information.country,
              state: this.user.Contact_information.state
            })
            .then(() => {
              if (response.data.status == true) {
                if (this.user.Roles[0] == 'Crew') {
                  Api.post('/users/update_crew_user_categories', {
                    user_id: this.user.User.id,
                    user_categories: this.user_categories
                  }).then(() => {
                    if (response.data.status == true) {
                      this.$swal("¡Todo listo!", "Información actualizada", "success");
                      //setTimeout(function () {
                        this.$router.push({ 
                          name: 'Profile Overview', 
                        })
                      //}, 1500);
                    }
                  })
                  .catch(error => this.errorHandling(error))
                } else {
                  this.$swal("¡Todo listo!", "Información actualizada", "success");
                  //setTimeout(function () {
                    this.$router.push({ 
                      name: 'Profile Overview', 
                    })
                  //}, 1500);
                }
                
                
              }
            })
            .catch(error => this.errorHandling(error))
          }
        })
        .catch(error => this.errorHandling(error))
      },

      cancelConfirm: function () {
        this.$swal({
          title: "¿Esta seguro?",
          text: "Los cambios realizados no se guardarán",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.$router.push({ 
              name: 'Profile Overview'
            })
          }
        });
      },

      getUserCategories: function (user_id) {
        Api.get(`/users/get_crew_user_categories/${user_id}`).then(response => {
          this.user_categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      getCategories: function () {
        Api.get(`category/get_by_section_type/15`)
        .then(response => {
          this.categories = response.data;

          if (this.categories.length > 0) {
            this.newCategory.category = this.categories[0];

            this.getSubcategories(this.categories[0].id);
          }
        })
        .catch(error => this.errorHandling(error))
      },        
      getSubcategories: function (category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },   
      addCategory: function () {
        if (this.newCategory.category != null && this.newCategory.subcategory != null) {
          let newCat = {};
        
          newCat = {
            category_text: this.newCategory.category.name,
            category_id: this.newCategory.category.id,
            subcategory_text: this.newCategory.subcategory.name,
            subcategory_id: this.newCategory.subcategory.id
          }

          let previousCategory = this.user_categories.find(element => element.category_id == newCat.category_id && element.subcategory_id == newCat.subcategory_id);

          if (previousCategory == null) {
            this.user_categories.push(newCat);
          } else {
            this.$swal("", "Ya añadió esa categoría", "warning");
          } 
        } else {
          this.$swal("", "Debe elegir una categoría", "warning");
        }
      },
      deleteCategory: function (index) {
        this.user_categories.splice(index, 1);
      },
    },
    mounted() {
      this.getUserInfo();
      this.getCategories();
    }
  };
</script>