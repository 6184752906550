<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="user != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Detalles del usuario</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información personal</h3>
            </v-col>
            <v-col cols="12" lg="2" class="mt-0 pt-0 secondary-text">
              <v-avatar size="100" class="ma-0 my-2 rounded-avatar">
                <img :src="(user.personal_information.profile_image_url != null) ? user.personal_information.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
              </v-avatar>
            </v-col>
            <v-col cols="12" lg="10" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Nombre completo:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (user.personal_information.name != null) ? user.personal_information.complete_name : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ user.email }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (user.contact_information.phone != null) ? '('+user.contact_information.phone_prefix+') '+user.contact_information.phone : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Ubicación:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (user.contact_information.state != null && user.contact_information.country != null) ? user.contact_information.state+', '+user.contact_information.country : '---' }}</v-col>
                
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Tipo de usuario:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (user.roles[0].name_es != null) ? user.roles[0].name_es : '---' }}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información de casa productora</h3>
            </v-col>
            <v-col v-if="user.companies.length > 0" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Nombre:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (user.companies[0].company.name) ? user.companies[0].company.name : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (user.companies[0].company.phone) ? '('+user.companies[0].company.phone_prefix+') '+user.companies[0].company.phone : '---'}}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (user.companies[0].company.email) ? user.companies[0].company.email : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Tipo de persona:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (user.companies[0].company.person_type) ? user.companies[0].company.person_type : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Rol del usuario en la compañia:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (user.companies[0].user_role) ? user.companies[0].user_role_es : '---' }}</v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Este usuario no tiene una casa productora asociada.</b></p>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" :lg="(loggedUser.Roles[0] == 'Admininstrator' && user.approved == null && previousPath.includes('/admin/user_management')) ? 7 : 9" class="mt-1">
              <p v-if="user.approved != null && previousPath.includes('/admin/user_management')"
               class="my-5 text-body"
              >
                <b>Esta usuario ya fue {{ (user.approved != null) ? (user.approved == true) ? 'aprobado' : 'rechazado' : '' }}</b>
              </p>
            </v-col>
            <v-col cols="12" :lg="(loggedUser.Roles[0] == 'Admininstrator' && user.approved == null && previousPath.includes('/admin/user_management')) ? 5 : 3" class="mt-1">
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="goBackToPrevoiusPage"
              >
                Volver
              </v-btn>
              
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="loggedUser.Roles[0] == 'Admininstrator' && user.approved == null && previousPath.includes('/admin/user_management')"
                @click="approveOrReject(user_id, true)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  task_alt
                </v-icon>
                Aprobar
              </v-btn>

              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="loggedUser.Roles[0] == 'Admininstrator' && user.approved == null && previousPath.includes('/admin/user_management')"
                @click="approveOrReject(user_id, false)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  unpublished
                </v-icon>
                Rechazar
              </v-btn>    
            </v-col>
          </v-row>
          <!--
          <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
            previousPath: {{ previousPath }}
            user_id: {{ user_id }}
            user: {{ user }}
          </pre> 
          -->
        </v-col>
      </v-row>
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';

  export default {
    name: "user-details",
    components: {
      //
    },
    data() {
      return {
        previousPath: null,
        loggedUser: {
          Roles: [
            null
          ]
        },

        user: null,

        user_id: null,
      };
    },
    computed: {
      //
    },
    watch: {
      loggedUser(value) {
        if (value != null) {
          //
        }
      },
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getUserInformation: function () {
        Api.get(`users/${this.user_id}`)
        .then(response => {
          this.user = response.data;
          
          this.user.roles.forEach(element => {
            element.name_es = this.translateRoleName(element.name);
          });
        })
        .catch(error => this.errorHandling(error))
      },
      translateRoleName: function (name_en) {
        let name_es = '';
        switch(name_en) {
          case 'Admininstrator': 
            name_es = 'Administrador';
          break;
          case 'Location owner': 
            name_es = 'Propietario de locación';
          break;
          case 'Producer': 
            name_es = 'Casa productora';
          break;
          case 'Provider': 
            name_es = 'Proveedor';
          break;
          case 'Person': 
            name_es = 'Usuario común';
          break;
          case 'Crew': 
            name_es = 'Crew';
          break;
          default: 
            name_es = name_en;
          break;
        }

        return name_es;
      },
      approveOrReject: function(SectionId, value) {
        this.$swal({
          title: ((value) ? '¿Aprobar usuario?' : '¿Rechazar usuario?'),
          text: "",
          type: "info",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`users/approve_reject_user`, {
              user_id: SectionId,
              approved: value
            })
            .then(response => {
              this.$swal.fire("Success", response.data.message, "success");
              this.$router.push({ 
                name: 'Manage users'
              })
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      
      goBackToPrevoiusPage: function () {
        this.$router.back();
        /*
        this.$router.push({ 
          name: 'Manage users'
        })
        */
      }
    },
    mounted() {
      this.user_id = this.$route.params.user_id;
      this.previousPath = localStorage.getItem('LS_ROUTE_KEY');
      this.getUserInformation();
      this.getUserInfo();
    }
  };
</script>