<template>
  <v-app
    style="background-color: #12141B !important;"
  >
    <v-main style="padding-left: 0% !important; padding-right: 0% !important;">
      <nav-bar
        background="transparent"
        :linkColor="secondaryColor"
        :navbarFixed="true"
        :toggle-active="drawer"
        :hasBg="true"
        :navbarMainPage="navbarMainPage"
        :navElements="navBarElements"
        :dialogForm="dialog"
        barPadding="0"
      ></nav-bar>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view
        :key="$route.fullPath"
        :linkColor="secondaryColor"
        @navbarMainPage="updateNavbarMainPage"
        @dialog="dialogUpdate"
        ></router-view>
      </fade-transition>
      <film-dgo-footer
        linkColor="transparent"
      ></film-dgo-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import NavBar from "../Navigation/Navbar.vue";
import FilmDgoFooter from "../Navigation/Footer.vue";

export default {
  components: {
    NavBar,
    FadeTransition,
    FilmDgoFooter,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "dark",
      navbarFixed: true,
      secondaryColor: "#656565",
      navbarMainPage: false,
      dialog: false,
      navBarElements: [ 
        {
          title: "Locaciones",
          link: "/locations"
        },
        {
          title: "Incentivos",
          link: "/incentives"
        },
        {
          title: "Proveedores & Crew",
          link: "/suppliers"
        },
        {
          title: "Empleos y capacitación",
          link: "/jobs"
        },
        {
          title: "Cine en Durango",
          link: "/about_us"
        },
        {
          title: "Filmografía",
          link: "/filmography"
        },
        {
          title: "Nosotros",
          link: "/about-film-durango"
        },
        {
          title: "Noticias",
          link: "/news"
        },
        {
          title: "Permisos",
          link: "/permissions"
        },
        
      ]
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    toggleNavbarOpacity(value) {
      this.navbarMainPage = value;
    },
    updateNavbarMainPage(value){
      this.navbarMainPage = value;
    },
    dialogUpdate(value){
      this.dialog = value;
    }
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
