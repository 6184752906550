var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"container pa-0 mt-4"},[_c('v-row',{staticClass:"justify-content-center border-radius-lg my-0"},[_c('v-col',{attrs:{"cols":"12","lg":(_vm.loggedUser.Roles[0] == 'Admininstrator'
          || _vm.loggedUser.Roles[0] == 'Producer'
          || _vm.loggedUser.Roles[0] == 'Person'
          || _vm.loggedUser.Roles[0] == 'Crew') ? 4 : 6}},[_c('user-personal-information')],1),(_vm.loggedUser.Roles[0] == 'Admininstrator'
          || _vm.loggedUser.Roles[0] == 'Producer'
          || _vm.loggedUser.Roles[0] == 'Person'
          || _vm.loggedUser.Roles[0] == 'Crew'
        )?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","lg":"8"}},[_c('postulations-table')],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }