<template>
  <div>
    <template>
      <v-footer color="transparent" class="px-6 position-absolute w-100 bottom-0">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                  <div class="copyright text-body-2 ls-0 text-white">
                      © {{ new Date().getFullYear() }}, made with
                      <i class="fa fa-heart" aria-hidden="true" :style="{color: 'red'}"></i> by
                      <a href="https://www.creative-tim.com" class="
                        text-decoration-none text-white text-body-2
                        ls-0
                        font-weight-bold
                      " target="_blank">Virtua</a>
                      for a better web.
                  </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </div>
</template>

<script>
export default {
    name: "content-footer",
    props: {
        auth: Boolean,
    },
    data() {
        return {
            footer: [],
            icons: [],
        };
    },
};
</script>
