<template>
    <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="{ background:'#12141B' }"
          >
          </div>
          </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom margin-main-image">
        <v-col cols="12" class="text-white supplier-description d-flex flex-column">
          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información general</h3>
            </v-col>
            <v-col cols="12" lg="10" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Nombre completo:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (consulted_user != null && consulted_user.personal_information.name != null) ? consulted_user.personal_information.complete_name : '---' }}</v-col>
                
                <v-col v-if="(consulted_user != null && consulted_user.personal_information.imdb_url != null)" cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Perfil de IMDB:</v-col>
                <v-col v-if="(consulted_user != null && consulted_user.personal_information.imdb_url != null)" cols="12" lg="7" class="mt-1 py-0">
                  <a :href="consulted_user.personal_information.imdb_url" target="_blank" class="link-format">
                    {{ (consulted_user != null && consulted_user.personal_information.imdb_url != null) ? consulted_user.personal_information.imdb_url : 'Sin perfil de IMDB registrado' }}
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 my-5 border-radius-lg blur blur-transparent" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Formación profesional</h3>
            </v-col>
            <v-col v-if="consulted_user != null && consulted_user.proffesional_experience.length > 0" cols="12" class="mt-0 pt-0 secondary-text">
                <v-row v-for="(experience, index) in consulted_user.proffesional_experience" :key="index"
                  class="text-white ma-0 mb-5 pl-5" justify="space-around"
                >
                  <v-col cols="12" lg="12" class="mt-1 mb-1 py-0 ml-n5 font-weight-bold">
                    <h3>{{ experience.title_name }}</h3> <h5>({{ experience.slug_es }})</h5>
                  </v-col>

                  <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Organización donde se obtuvo</v-col>
                  <v-col cols="12" lg="6" class="mt-1 py-0">{{ experience.organization }}</v-col>

                  <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Fechas</v-col>
                  <v-col cols="12" lg="6" class="mt-1 py-0">De {{ formatDate(experience.start_date) }} a {{ formatDate(experience.end_date) }}</v-col>

                  <v-col cols="12" lg="12" class="mt-3 pa-2 border-radius-lg blur blur-transparent">
                    <span v-html="experience.description" class="text-white parrafo ql-editor"></span>
                  </v-col>
                </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Este miembro de crew todavia no ha rellenado su información de formación profesional</b></p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Api from '@/api/Api';
export default{
    props: {
    },
    data() {
        return {
            currentPage: 0,
            id: this.$route.params.user_id,
            consulted_user: null,
        };
    },
    created() {
      this.$emit('navbarMainPage',false);
      this.getCrewUser();
    },
    methods: {
      updatePage(page) {
          this.adjustedPage = page;
      },
      getCrewUser:function(){
        Api.get(`/users/get_specific_crew_information/${this.id}`)
        .then(response => {
          this.consulted_user = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      },
    },
    computed: {
        adjustedPage: {
            get() {
            return this.currentPage + 1;
            },
            set(value) {
            this.currentPage = value - 1;
            }
        },
    }
}
</script>
<style scoped>
.margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.main-image{
  min-height: 70vh;
}
.supplier-description{
    margin-top: 30vh;
}
.supplier-trailer{
    margin-top: 30vh;
}
.supplier-title{
    margin-bottom: 2vh;
}
.information{
    display: inline-flex;
    margin-bottom: 1vh;
    margin-left: -2.1vh;
}
.width-text{
    width: 10vw;
}
.margin-main-image{
    margin-bottom: 10vh;
}
.margin-bottom{
    margin-bottom: 10vh;
}
.margin-main-image{
  margin-top: -35vh;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-pagination{
    margin-top: 3vh;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
.table-sections.theme--light.v-data-table {
  background-color: transparent !important;
}
.v-expansion-panel-header {
  font-size: 1.3em !important;
}
.margin-table{
  margin-bottom: 15vh !important;
}
</style>