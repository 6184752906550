<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="application != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Detalles de postulación</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información del incentivo</h3>
            </v-col>
            <v-col v-if="application.incentive_information != null" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">{{ application.incentive_information.section_type_id == 2 ? 'Nombre del incentivo:' : 'Nombre de la solicitud:' }}</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0 notranslate">{{ (application.incentive_information.contents_info.title.value) ? application.incentive_information.contents_info.title.value : '---' }}</v-col>

                <v-col v-if="application.incentive_information.contents_info.main_description && application.incentive_information.contents_info.main_description.value != null" 
                  cols="12" lg="12" class="mt-3 pa-2 border-radius-lg blur blur-transparent"
                >
                  <span v-html="(application.incentive_information.contents_info.main_description.value) ? application.incentive_information.contents_info.main_description.value : '---'" class="text-white parrafo ql-editor"></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Esta postulación no tiene un incentivo asociado.</b></p>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información de postulante</h3>
            </v-col>
            <v-col cols="12" lg="2" class="mt-0 pt-0 secondary-text">
              <v-avatar size="100" class="ma-0 my-2 rounded-avatar">
                <img :src="(application.user.personal_information.profile_image_url != null) ? application.user.personal_information.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
              </v-avatar>
            </v-col>
            <v-col cols="12" lg="10" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Nombre completo:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (application.user.personal_information.name != null) ? application.user.personal_information.complete_name : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ application.user.email }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (application.user.contact_information.phone != null) ? '('+application.user.contact_information.phone_prefix+') '+application.user.contact_information.phone : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Ubicación:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (application.user.contact_information.state != null && application.user.contact_information.country != null) ? application.user.contact_information.state+', '+application.user.contact_information.country : '---' }}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="
            application.incentive_information.section_type_id == 1
            || application.incentive_information.section_type_id == 14" 
            class="text-white ma-1 my-5 border-radius-lg blur blur-transparent" justify="space-around"
          >
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Formación del postulante</h3>
            </v-col>
            <v-col v-if="application.user.proffesional_experience.length > 0" cols="12" class="mt-0 pt-0 secondary-text">
                <v-row v-for="(experience, index) in application.user.proffesional_experience" :key="index"
                  class="text-white ma-0 mb-5 pl-5" justify="space-around"
                >
                  <v-col cols="12" lg="12" class="mt-1 mb-1 py-0 ml-n5 font-weight-bold">
                    <h3>{{ experience.title_name }}</h3> <h5>({{ experience.slug_es }})</h5>
                  </v-col>

                  <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Organización donde se obtuvo</v-col>
                  <v-col cols="12" lg="6" class="mt-1 py-0">{{ experience.organization }}</v-col>

                  <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Fechas</v-col>
                  <v-col cols="12" lg="6" class="mt-1 py-0">De {{ formatDate(experience.start_date) }} a {{ formatDate(experience.end_date) }}</v-col>

                  <v-col cols="12" lg="12" class="mt-3 pa-2 border-radius-lg blur blur-transparent">
                    <span v-html="experience.description" class="text-white parrafo ql-editor"></span>
                  </v-col>
                </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Este usuario no ha rellenado su información de formación profesional</b></p>
            </v-col>
          </v-row>

          <v-row v-if="application.incentive_information.section_type_id == 2" class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información de casa productora</h3>
            </v-col>
            <v-col v-if="application.user.companies.length > 0" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Nombre:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.user.companies[0].company.name) ? application.user.companies[0].company.name : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.user.companies[0].company.phone) ? '('+application.user.companies[0].company.phone_prefix+') '+application.user.companies[0].company.phone : '---'}}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.user.companies[0].company.email) ? application.user.companies[0].company.email : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Tipo de persona:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.user.companies[0].company.person_type) ? application.user.companies[0].company.person_type : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Rol del usuario en la compañia:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.user.companies[0].user_role) ? application.user.companies[0].user_role_es : '---' }}</v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Este usuario no tiene una casa productora asociada.</b></p>
            </v-col>
          </v-row>

          <hr v-if="application.incentive_information.section_type_id == 2" class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="application.incentive_information.section_type_id == 2" class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Información del proyecto de filmografía</h3>
            </v-col>
            <v-col v-if="application.film_project != null" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Nombre del proyecto:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.name) ? '"'+application.film_project.name+'"' : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Tipo:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.film_type_es) ? application.film_project.film_type_es : '---' }}</v-col>
                
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Origen:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.film_origin_es) ? application.film_project.film_origin_es : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Etapa actual:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.film_stage_es) ? application.film_project.film_stage_es : '---' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Presupuesto total en Durango:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.total_budget_in_dgo) ? setBudgetFormat(application.film_project.total_budget_in_dgo)+' USD' : '0.00 USD' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Presupuesto facturable en Durango:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.total_billiable_in_dgo) ? setBudgetFormat(application.film_project.total_billiable_in_dgo)+' USD' : '0.00 USD' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Dias de filmación en interiores:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.film_days_indoors) ? application.film_project.film_days_indoors+' dias' : '0 dias' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Dias de filmación en exteriores:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.film_days_outdoors) ? application.film_project.film_days_outdoors+' dias' : '0 dias' }}</v-col>

                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Dias totales de filmación en Durango:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0">{{ (application.film_project.total_film_days_in_dgo) ? application.film_project.total_film_days_in_dgo+' dias' : '0 dias' }}</v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-5 text-body"><b>Esta postulación no tiene un proyecto de filmografía asociado</b></p>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" :lg="(loggedUser.User.email == application.section.user_owner.email && application.approved == null) ? 7 : 9" class="mt-1">
              <p v-if="application.approved != null"
               class="my-5 text-body"
              >
                <b>Esta postulación ya fue {{ (application.approved != null) ? (application.approved == true) ? 'aprobada.' : 'rechazada.' : '' }}</b>
              </p>
              <p v-else class="my-5 text-body">
                <b>Esta postulación aún no ha sido revisada.</b>
              </p>
            </v-col>
            <v-col cols="12" :lg="(loggedUser.User.email == application.section.user_owner.email && application.approved == null) ? 5 : 3" class="mt-1">
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="goBackToPrevoiusPage"
              >
                Volver
              </v-btn>
              
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="loggedUser.User.email == application.section.user_owner.email && application.approved == null"
                @click="approveOrReject(application_id, true)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  task_alt
                </v-icon>
                Aprobar
              </v-btn>

              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="loggedUser.User.email == application.section.user_owner.email && application.approved == null"
                @click="approveOrReject(application_id, false)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  unpublished
                </v-icon>
                Rechazar
              </v-btn>    
            </v-col>
          </v-row>
          <!--
          <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
            application_id: {{ application_id }}
            application: {{ application }}
          </pre> 
          -->
        </v-col>
      </v-row>
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';

  export default {
    name: "postulation-details",
    components: {
      //
    },
    data() {
      return {
        loggedUser: {
          Roles: [
            null
          ]
        },

        application: null,

        application_id: null,
      };
    },
    computed: {
      //
    },
    watch: {
      loggedUser(value) {
        if (value != null) {
          //
        }
      },
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getApplicationInformation: function () {
        Api.get(`vinculations/${this.application_id}`)
        .then(response => {
          this.application = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      setBudgetFormat: function (quantity) {
        return quantity.toLocaleString();
      },
      approveOrReject: function(SectionId, value) {
        this.$swal({
          title: ((value) ? '¿Aprobar postulación?' : '¿Rechazar postulación?'),
          text: "",
          type: "info",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`vinculations/approve_reject_vinculation`, {
              vinculation_id: SectionId,
              approved: value
            })
            .then(response => {
              this.$swal.fire("Success", response.data.message, "success");
              /*
              this.$router.push({ 
                name: 'Applications management'
              })
              */
              this.goBackToPrevoiusPage();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      },
      
      goBackToPrevoiusPage: function () {
        this.$router.back();
      }
    },
    mounted() {
      this.application_id = this.$route.params.application_id;
      this.getApplicationInformation();
      this.getUserInfo();
    }
  };
</script>