<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
        class="form-control" 
      />

      <v-row v-if="section != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title"
                v-if="page_id != 2"
              >{{ (page_id == 1) ? 'Editar la información de la página "Cine en Durango"' : 'Editar la información de la página de Inicio'}}</label>
              <label v-else class="text-white new-section-title">Editar la información de la página de Nosotros</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-col cols="12" lg="12" class="mt-5 py-0">
            <label class="text-white font-weight-bold"
              v-if="page_id != 2"
            >{{ (page_id == 1) ? 'Título principal' : 'Título del párrafo con imágen'}}</label>
            <label v-else class="text-white font-weight-bold">Título principal</label>
          </v-col>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mb-0 py-0">
              <v-text-field
                v-model="section.indexed_contents.title.value"
                label="Title"
                placeholder="Name"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
                @input="addContent('title', section.indexed_contents.title.value, null)"
              />
            </v-col>
          </v-row>

          <v-row class="text-white mx-1 my-0 border-radius-lg blur blur-transparent my-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white"
                v-if="page_id != 2"
              >{{ (page_id == 1) ? 'Texto principal' : 'Texto del párrafo con imágen'}}</label>
              <label v-else class="text-white">Texto principal</label>
            </v-col>
            <hr style="width: 100%; border: 1px solid #7b809a;">
            <v-col cols="12" lg="12" class="mt-1">
              <VueEditor 
                v-model="section.indexed_contents.main_description.value"
                class="input-style ma-0 pa-1 input-white-text white-background"
                @input="addContent('main_description', section.indexed_contents.main_description.value, null)"
                :editorOptions="optionsEditor"
              />
              <!-- <v-textarea
                v-model="section.indexed_contents.main_description.value"
                color="text-white"
                class="input-style ma-0 pa-1 input-white-text"
                hide-details
                auto-grow
                @input="addContent('main_description', section.indexed_contents.main_description.value, null)"
              /> -->
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-col cols="12" lg="12" class="mt-5 py-0"
            v-if="page_id != 2"
          >
            <label class="text-white font-weight-bold">{{ (page_id == 1) ? 'Título secundario' : 'Título del párrafo secundario'}}</label>
          </v-col>

          <v-row class="text-white ma-1 mt-5" justify="space-around"
            v-if="page_id != 2"
          >
            <v-col cols="12" lg="12" class="mb-0 py-0">
              <v-text-field
                v-model="section.indexed_contents.subtitle.value"
                label="Title"
                placeholder="Name"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
                @input="addContent('subtitle', section.indexed_contents.subtitle.value, null)"
              />
            </v-col>
          </v-row>

          <v-row class="text-white mx-1 my-0 border-radius-lg blur blur-transparent my-5" justify="space-around"
            v-if="page_id != 2"
          >
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white">{{ (page_id == 1) ? 'Texto secundario' : 'Texto del párrafo secundario'}}</label>
            </v-col>
            <hr style="width: 100%; border: 1px solid #7b809a;">
            <v-col cols="12" lg="12" class="mt-1">
              <VueEditor 
                v-model="section.indexed_contents.body.value"
                class="input-style ma-0 pa-1 input-white-text white-background"
                @input="addContent('body', section.indexed_contents.body.value, null)"
                :editorOptions="optionsEditor"
              />
              <!-- <v-textarea
                v-model="section.indexed_contents.body.value"
                color="text-white"
                class="input-style ma-0 pa-1 input-white-text"
                hide-details
                auto-grow
                @input="addContent('body', section.indexed_contents.body.value, null)"
              /> -->
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background" 
            v-if="page_id != 2"
          />

          <v-col cols="12" lg="12" class="mt-5 py-0"
            v-if="page_id != 2"
          >
            <label class="text-white font-weight-bold">Multimedia</label>
            <label class="text-secondary ml-5">(Tamaño máximo de 1.95 MB)</label>
          </v-col>

          <v-row class="text-white ma-1 mt-5" justify="space-around"
            v-if="page_id != 2"
          >
            <v-col cols="12" :lg="page_id == 1 ? 6 : 12" class="pa-0">
              <v-col cols="12">
                <label class="text-white mb-5">{{ (page_id == 1) ? 'Imágen de fondo' : 'Imágen de párrafo'}}</label>
              </v-col>
              <v-col cols="12">
                <div class="border-radius-lg main-img-container">
                  <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                  <v-img :src="(section.indexed_contents.secondary_image_url.value) ? section.indexed_contents.secondary_image_url.value : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                  class="img-view-size pa-2 border-radius-lg not-alter-image">
                    <v-btn 
                      icon
                      class="my-1 mx-0 text-white text-transform-none floating-button" 
                      @click="pickFile(null, 'secondary_image_url')"
                      title="Editar imágen de fondo"
                      :loading="ImageBtnLoading"
                    >
                      <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                        edit
                      </v-icon>
                    </v-btn>
                  </v-img>
                </div>
              </v-col>
            </v-col>

            
            <v-col 
              v-if="page_id == 1"
              cols="12" lg="6" class="pa-0"
            >
              <v-col cols="12">
                <label class="text-white mb-5">Imágen adicional</label>
              </v-col>
              <v-col cols="12">
                <div class="border-radius-lg main-img-container">
                  <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                  <v-img :src="(section.indexed_contents.main_image_url.value) ? section.indexed_contents.main_image_url.value : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                  class="img-view-size pa-2 border-radius-lg not-alter-image">
                    <v-btn 
                      icon
                      class="my-1 mx-0 text-white text-transform-none floating-button" 
                      @click="pickFile(null, 'main_image_url')"
                      title="Editar imágen adicional"
                      :loading="ImageBtnLoading"
                    >
                      <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                        edit
                      </v-icon>
                    </v-btn>
                  </v-img>
                </div>
              </v-col>
            </v-col>
          </v-row>

          <v-row 
            v-if="page_id == 1000"
            class="text-white mx-1 mt-1 border-radius-lg blur blur-transparent my-5" justify="space-around"
          >
            <v-col cols="12">
              <label class="text-white mb-5">Imágenes del carrusel</label>
            </v-col>
            <v-col cols="12" lg="12" class="mt-1">
              <v-btn 
                class="my-1 mx-0 main_gradient_background text-white text-transform-none" 
                @click="pickFile(newImagePriority, 'additional_image_url')"
                :loading="ImageBtnLoading"
              >
                Agregar imágen
              </v-btn>
            </v-col>
            <hr style="width: 100%; border: 1px solid #7b809a;">
            <v-col cols="12" lg="12" class="mt-1">
              <v-row>
                <template v-for="(image, index) in section.contents">
                <v-col
                  v-bind:key="index"
                  v-if="image.slug == 'additional_image_url'"
                  class="d-flex child-flex"
                  cols="2"
                >
                  <v-img
                    :src="image.value"
                    aspect-ratio="1"
                    cover
                    class="bg-grey-lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey-lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-btn 
                      v-if="image.slug == 'additional_image_url'"
                      icon
                      class='my-1 mx-0 text-white text-transform-none floating-button'
                      title="Remove image"
                      @click="removeImage(index, image)"
                    >
                      <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                        delete
                      </v-icon>
                    </v-btn>
                  </v-img>
                </v-col>
              </template>
              </v-row>
            </v-col>
          </v-row>

          <hr v-if="page_id == 1000" class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="page_id == 1000" class="text-white ma-1 mt-5 border-radius-lg" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Frases del carrusel</label>
            </v-col>

            <v-col cols="12" lg="10" class="mb-5">
              <v-text-field
                v-model="TemporalPhrase"
                label="Nueva frase"
                placeholder="Frase"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>  

            <v-col cols="12" lg="2" class="mb-5">
              <v-btn icon text rel="tooltip" title="Añadir frase" 
                class="btn btn-primary btn-simple btn-xs white-button"
                @click="addPhrase('main_page_phrase', TemporalPhrase, newPrasePriority)"
              >
                <v-icon end class="material-icons-outlined me-sm-1" size="20">
                  add
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12"> <!-- Table body -->
              <table class="table-responsive table-sections text-white notranslate">
                <thead class="text-white">
                  <tr>
                    <th>Frase</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(phrase, index) in section.contents">
                    <tr v-bind:key="index"
                      v-if="phrase.slug == 'main_page_phrase'"
                    >
                      <td>
                        <div class="d-flex align-center ms-2">
                          <span class="font-weight-normal text-white">
                            {{ phrase.value }}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div class="d-flex align-center ms-2">
                          <span class="font-weight-normal text-white">
                            <v-layout>                                              
                              <v-btn icon text rel="tooltip" title="Borrar frase" 
                                class="btn btn-primary btn-simple btn-xs white-button"
                                @click="deletePhrase(index)">
                                <v-icon end class="material-icons-outlined me-sm-1" size="20">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-layout>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </v-col>
          </v-row>

          <v-row class="text-white ma-1">
            <v-col cols="12" lg="9" class="d-flex align-right">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="3" class="d-flex align-right">
              <v-btn 
                class="my-5 mx-1 cancel_gradient_border text-white text-transform-none transparent-background"
                @click="cancelConfirm"
              >
                Cancelar
              </v-btn>
              <v-btn 
                class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="updateInfo"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>

      <!--
      <pre v-if="section != null" style="height: 300px; overflow-y: scroll;" class="text-white">
        page_id: {{ page_id }}
        indexed_contents: {{ section.indexed_contents }}
        contents: {{ section.contents }}
      </pre>   
      -->

    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';
  import { VueEditor } from "vue2-editor";

  export default {
    name: "edit-personal-information",
    components: {
      VueEditor
    },
    data() {
      return {
        page_id: null,
        section: null,

        ImageBtnLoading: false,
        newImagePriority: 1,
        newPrasePriority: 1,
        TemporalPhrase: null,

        fileSlug: null,

        optionsEditor: {
          theme: 'snow',
          formats: [
            'bold',
            'italic',
            'list',
            'align',
          ],
          modules: {
            toolbar: [
              ['bold', 'italic'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ align: "left" }, { align: "center" }, { align: "right"}, { align: "justify"}]
            ],
          }
        },
      };
    },
    computed: {
      //
    },
    watch: {
      //
    },
    methods: {
      editSection: function (sectionId) {
        Api.get(`section/${sectionId}/edit`)
        .then(response => {
          this.section = response.data;

          if (this.section != '') {
            this.section.contents.forEach(item => {
              if (item.slug == 'additional_image_url') {
                if (item.priority > this.newImagePriority) { this.newImagePriority = item.priority }
              }
              if (item.slug == 'main_page_phrase') {
                if (item.priority > this.newPrasePriority) { this.newPrasePriority = item.priority }
              }
            });
            //if there's at least one image already uploaded, place the priority control variable 
            //one position after the upper priority read, so it won't be overwrited
            if (this.newImagePriority > 1) {
              this.newImagePriority ++;
            }

            if (this.newPrasePriority > 1) {
              this.newPrasePriority ++;
            }
          }
        })
        .catch(error => this.errorHandling(error))
      },

      addContent: function (slug, value, priority) {
        //create object
        let newContent = {};
        newContent = {
          slug: slug,
          value: value,
          priority: priority
        }
        
        //check if the content object already exists in the array
        let exists = false;
        this.section.contents.forEach(function(item) {
          if (item.slug === slug) { exists = true; }
        })

        if (value != '') {

          //if doesn't exist, it's directly added to the array. 
          //If already exists, delete the previous version and add the new one
          if (exists == true) {
            let previousContent = this.section.contents.find(element => element.slug === slug);
           
            const index = this.section.contents.indexOf(previousContent);
            this.section.contents.splice(index, 1);
          }

          this.section.contents.push(newContent);

        } else {
          //if the input is empty, delete the content object from the array
          let result = this.section.contents.find(element => element.slug === slug);
          
          const index = this.section.contents.indexOf(result);
          this.section.contents.splice(index, 1);
        }
      },

      pickFile: function (filePriority, slug) {
        this.$refs.input_file.click();
        
        this.fileSlug = slug;
      },

      getFile: function (e) {
        this.ImageBtnLoading = !this.ImageBtnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {
        
          let formData = new FormData();
          formData.append('file', file)
          formData.append('slug', this.fileSlug)
          formData.append('path', (this.page_id == 1) ? 'sections/about_us/' : 'sections/main_page/')

          Api.post('files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.$swal(response.data.message, '', "success");

            if (this.fileSlug != 'additional_image_url') {
              this.section.indexed_contents[this.fileSlug].value = response.data.file_url;
              this.addContent(this.fileSlug, response.data.file_url, null);
            
            response.data.file_url;
            } else {
              this.addImage(response.data.file_url, this.newImagePriority, response.data.file_name, this.fileSlug);
              this.newImagePriority ++;
            }
            
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.ImageBtnLoading = !this.ImageBtnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.ImageBtnLoading = !this.ImageBtnLoading;
        }
      },

      addImage: function (value, priority, name, slug) {
        let newContent = {
          slug: slug,
          name: name,
          value: value,
          priority: priority,
          edit: 'yes'
        }

        this.section.contents.push(newContent);
      },

      removeImage: function (index, image) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {

            Api.post(`contents/mark_or_unmark_image_for_deletion`, {
              content_id: image.id,
              marked_for_deletion: true
            })
            .catch(error => this.errorHandling(error))

            this.section.contents.splice(index, 1);
          } 
        });
      },

      addPhrase: function (slug, value, priority) {
        let newContent = {
          slug: slug,
          value: value,
          priority: priority
        }
        this.newPrasePriority ++;

        this.section.contents.push(newContent);
      },

      deletePhrase: function (index) {
        this.section.contents.splice(index, 1);
      },

      updateInfo: function () {
        this.$swal({
          title: "",
          text: "¿Seguro que quieres guardar los cambios?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`section/update_about_us_section`, {
              section_id: this.page_id,
              contents: this.section.contents
            })
            .then(response => {
              this.$swal("¡Todo listo!", response.data.message, "success");
              this.editSection(this.page_id);
            })
            .catch(error => this.errorHandling(error))
          }
        });
      },

      cancelConfirm: function () {
        this.$swal({
          title: "¿Esta seguro?",
          text: "Los cambios realizados no se guardarán",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.$router.push({ 
              name: 'Profile Overview'
            })
          }
        });
      },
    },
    mounted() {
      this.page_id = this.$route.params.page_id;
      this.editSection(this.page_id);
    }
  };
</script>