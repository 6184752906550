import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes'
//import Api from '../api/Api';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history'
});

/*
function userIsLoggedIn() {
  Api.get('/auth/user_is_logged').then(response => {
    return response.data;
  })
  .catch(error => this.errorHandling(error))
}
*/

router.beforeEach((to, from, next) => {
  if (from.path != to.path) {
    localStorage.removeItem('LS_ROUTE_KEY');
    localStorage.setItem('LS_ROUTE_KEY', from.path);
  }

  /*
  let loggedUser = userIsLoggedIn();

  if (to.meta.requiresAuth == true && loggedUser == 'not_logged') {
    router.push({ 
      name: 'SignUpCover'
    })
  }
  */

  next(() => {
    //
  });
});

export default router;
