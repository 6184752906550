<template>
  <v-container fluid class="pa-0 margin-main-image">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <div
          class="page-header main-image min-vw-100"
          :style="[newsInformation.contents_info.secondary_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${newsInformation.contents_info.secondary_image_url.value})`,backgroundSize:'cover'} :{ background:'#12141B' }]"
        >
        </div>
        </v-col>
    </v-row>
    <v-row class="margin-view margin-bottom margin-main-image">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="text-white new-description d-flex flex-column">
        <h1 class="text-white new-title">{{ newsInformation.contents_info.title ? newsInformation.contents_info.title.value : '-' }}</h1>
        <br>
        <div class="information">
          <h2 class="text-white width-text">Autor</h2>
          <p class="text-white fix-p">{{ newsInformation.contents_info.new_writer ? newsInformation.contents_info.new_writer.value : '-'}}</p>
        </div>
        <div class="information">
          <h2 class="text-white width-text">Fecha de publicación</h2>
          <p class="text-white fix-p">{{ newsInformation.contents_info.new_date ? newsInformation.contents_info.new_date.value : '-' }}</p>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <!-- <v-img class="img-main" :src="newsInformation.contents_info.main_image_url ? newsInformation.contents_info.main_image_url.value : ''"></v-img> -->
        <v-carousel class="img-main" v-model="currentPage" :show-arrows="false" hide-delimiters>
          <v-carousel-item
          :src="item" 
          v-for="(item, index) in additionalImages" :key="index"
          ></v-carousel-item>
        </v-carousel>
        <br>
        <v-pagination
              class="pagination table-pagination margin-pagination" 
              circle v-model="adjustedPage" 
              :length="additionalImages.length" 
              total-visible="6"
              @input="updatePage">
        </v-pagination>
      </v-col>
    </v-row>
    <v-row class="margin-view margin-bottom">
        <v-col>
            <h1 class="text-white">Descripción</h1>
            <br>
            <p v-html="newsInformation.contents_info.main_description ? newsInformation.contents_info.main_description.value : '-'" class="text-white text-justify ql-editor"></p>
        </v-col>
    </v-row>
    <v-row class="margin-view margin-bottom" justify="center">
      <v-col cols="12" xs="12" sm="12" md="5" lg="6">
        <v-card class="text-center text-white" outlined color="transparent">
          <h1 class="justify-center mb-5">
            Mira otras noticias.
          </h1>
          <p class="parrafo">Sumérgete en el apasionante mundo del cine en Durango con nuestra colección exclusiva de noticias de filmografía. Descubre los secretos detrás de las cámaras mientras las deslumbrantes locaciones de esta región cobran vida en la gran pantalla.</p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="margin-view">
      <v-col v-for="(noticia, index) in news.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
        <v-card
          class="text-white"
          outlined color="transparent"
        >
          <div class="mt-n11 card-header position-relative z-index-2">
            <div class="d-block blur-shadow-image">
              <v-img
                :src="noticia.contents_info.main_image_url ? noticia.contents_info.main_image_url.value : ''"
                class="img-middle img-fluid shadow border-radius-lg"
              />
            </div>
          </div>
          <h2 class="mt-7 mb-2 px-4 text-white">
            {{ noticia.contents_info.title ? noticia.contents_info.title.value : '-' }}
          </h2>
          <div class="text-broken">
            <p v-html="noticia.contents_info.main_description ? noticia.contents_info.main_description.value : ''" class="mb-7 px-4 text-justify"></p>
          </div>  
          <v-card-actions>
            <v-btn class="btn-gradient" large :href="`/newInformation/${noticia.id}`">
              Ver más
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Api from '@/api/Api';
export default {
  props: {
  },
  data() {
      return {
        newsInformation: [],
        id: this.$route.params.id,
        news: [],
        currentPage: 0,
        additionalImages: []
      };
  },
  created() {
    this.$emit('navbarMainPage',false);
    this.getNews();
    this.getNew();
  },
  methods: {
    getNew(){
      Api.post(`section/get_specific_contents`,
      {
        section_id: this.id,
        contents: [
          'title',
          'main_image_url',
          'main_description',
          'secondary_image_url',
          'additional_image_url',
          'new_writer',
          'new_date',
        ]
      })
      .then(response => {
        if(response.data != 'Sección desactivada') {
          this.newsInformation = response.data;
          this.getAdditionalImages();
        } else {
          this.$router.back();
        }
      })
      .catch(error => this.errorHandling(error))
    },
    getNews:function (page = 1){
      Api.get('section/get_section_by_section_type/3', {
        params: {
          page: page,
          query: this.search,
          paginate: this.itemsPerPage,
          contents: ['title','main_description','main_image_url'],
          pagination_required: 'Yes',
          category: this.temporalCategory,
          subCategory: this.temporalSubCategory
        }
      })
      .then(response => {
        this.news = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    updatePage(page) {
          this.adjustedPage = page;
    },
    getAdditionalImages(){
      const slug = "additional_image_url";
      Object.entries(this.newsInformation.contents_info).forEach(value => {
        var additionalImage = {};
        additionalImage = value.find(element => element.slug === slug);
        if(additionalImage != null){
          this.additionalImages.push(additionalImage.value);
        }
      }) 
    }
  },
  computed: {
      adjustedPage: {
          get() {
            return this.currentPage + 1;
          },
          set(value) {
            this.currentPage = value - 1;
          }
      },
  }
};
</script>
<style scoped>
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
}
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.main-image{
  height: 70vh;
}
.new-description{
  margin-top: 30vh;
}
.img-main{
  margin-top: 30vh;
  height: 40vh;
  background-position: 50%;
}
.new-title{
  margin-bottom: 2vh;
}
.information{
  display: inline-flex;
  margin-bottom: 1vh;
}
.width-text{
  width: 15vw;
}
.margin-main-image{
  margin-bottom: 10vh;
}
.margin-bottom{
  margin-bottom: 10vh;
}
.margin-main-image{
  margin-top: -35vh;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.card-padding{
  padding-right: 3% !important;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
.fix-p{
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
</style>