<template>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/rocas.jpg')}) !important; background-size: cover; background-position: 50%;`"
          >
          </div>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-text-box">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
            <h1 class="text-white margin-title">The Film Tax Credit</h1>
            
            <h2 class="text-white">0% TAX</h2>
            <p class="text-white text-justify">All film/video productions shot in Mexico and whose primary exploination will be done outside Mexico are considered an "export product" and will be exempt from Value Added Tax (VAT).All you expenses for a 0% VAT have to be billed by one company, and that company needs an authorization from the IRS (SAT) prior invoicing the project.</p>\
            
            <h2 class="text-white">EFICINE</h2>
            <p class="text-white text-justify">A tax is granted to the income taxoayers, consisting in a fiscal credit equivalent to the amount that these taxpayers contribute during the corresponding fiscal year to investment projects for domestic cinematographic production, against the income tax or the asset tax charged to them in the fiscal year said credit is determined. Such incentive may in no case exceed 10% of the income tax charged for the fiscal year immediately preceding the one of its application</p>
            <p class="text-white text-justify">For more information on EFICINE, please do not hesitate to contact us</p>
            
            <h2 class="text-white">OTHER INCENTIVES</h2>
            <p class="text-white text-justify">Some states and cities also offer small incentives, especially for feature film projects shot in their jurisdiction. These incentives may include lodging and transportation during location scouting, special discounts on some services, etc. These incenvites are not alwats available and need to be reviewed on a particular basis.</p>
            <v-btn 
                class="mb-5 mx-0 margin-button button-gradient text-white text-transform-none main-image-button"
                x-large
                @click="dialog = true"
            >
                Postularme
            </v-btn>
        </v-col>
      </v-row>  
        <v-dialog
          v-model="dialog"
          max-width="900px"
        >
        <v-card class="card-dialog">
              <v-toolbar
              flat
              dark
              color="#12141b"
              >
                <v-toolbar-title class="padding-text">The Film Tax Credit</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-toolbar>
            <hr class="color-line">
          <div class="padding-text">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <h1 class="text-white">Datos personales</h1>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="8">
                <label class="text-white">Nombre completo:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Email:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Pais:</label>    
                <v-select
                  background-color="#292b31"
                  class="text-color"
                  color="white"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Estado:</label>    
                <v-select
                  background-color="#292b31"
                  class="text-color"
                  color="white"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Telefono:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                    type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <h1 class="text-white">Datos de casa productora</h1>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Nombre de la empresa:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Email de la empresa:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Telefono:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                    type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <v-btn 
                    class="my-1 mx-0 button-gradient text-white text-transform-none" 
                    @click="pickFile(temporalValues.newImagePriority, 'additional_image_url')"
                    :loading="btnLoading"
                  >
                    Escoge un archivo
                </v-btn>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <h1 class="text-white">Datos del proyecto</h1>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Nombre del proyecto:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                <label class="text-white">Tipo de proudccion:</label>    
                <v-text-field
                    outlined
                    clearable
                    background-color="#292b31"
                    class="text-color"
                    color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Genero:</label>    
                <v-select
                  background-color="#292b31"
                  class="text-color"
                  color="white"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="4">
                <label class="text-white">Horas de grabación en Durango:</label>    
                <v-select
                  background-color="#292b31"
                  class="text-color"
                  color="white"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn 
                class="my-1 mx-0 button-gradient text-white text-transform-none" 
                :loading="btnLoading"
                >
                Enviar
              </v-btn>
            </v-row>
          </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
            dialog: false,
        };
    },
    created() {
      this.$emit('navbarMainPage',false);
    },
    methods: {
    },
    watch:{
      dialog(){
        this.$emit('dialog',this.dialog);
      }
    }
};
</script>
<style scoped>
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.margin-title{
  margin-bottom: 5vh;
}
.button-gradient{
    background: linear-gradient(90deg, #50005E 0%, #C71C4F 100%);
}
.margin-button{
  margin-top: 7vh;
}
.margin-text-box{
  margin-bottom: 20vh;
}
.fa-times{
    background-image: linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%) !important;
    color: transparent !important; 
    background-clip: text !important;
}
.card-dialog{
    background: #12141b;
}
.padding-text{
    padding-left: 5vh;
    padding-right: 5vh;
}
.color-line{
    color: #FFFFFF;
}
.text-color >>> .v-text-field__slot input{
  color: #666b7f;
}
</style>