<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="user != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Nuevo usuario</label>
            </v-col>
          </v-row>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.name"
                label="Nombre"
                placeholder="Nombre"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.last_name"
                label="Apellido paterno"
                placeholder="Apellido paterno"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.second_last_name"
                label="Apellido materno"
                placeholder="Apellido materno"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.email"
                label="Email"
                placeholder="example@filmdgo.com"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.password"
                label="Contraseña"
                color="text-white"
                type="password"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="user.password_confirmation"
                label="Confirmar contraseña"
                color="text-white"
                type="password"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="4" class="my-5">
              <label class="mr-3">Código de país</label>
              <vue-country-code 
                class="input-style style_background country-select-code"
                style="color: #12141B"
                v-model="user.phone_prefix"
                :disabledFetchingCountry="true"
                :enabledCountryCode="true"
                :preferredCountries="preferredCountries"
                @onSelect="selectCountryCode"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field
                v-model="user.phone"
                label="Teléfono"
                placeholder="6181234567"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>

            <v-col cols="12" lg="4">
              <div>
                <v-checkbox 
                  color="white"
                  v-model="user.phone_whatsapp"
                  class="wa-checkbox-container"
                >
                  <template v-slot:label>
                    <span class="text-white">¿Whatsapp?</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" lg="4">
              <v-autocomplete
                v-model="user.country"
                label="País"
                :items="countries"
                item-text="country"
                item-value="country"
                hide-details
                class="input-style mb-5 input-white-text"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="4">
              <v-autocomplete
                v-model="user.state"
                label="Estado"
                :items="states"
                hide-details
                class="input-style mb-5 input-white-text"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" lg="4">
              <v-select
                v-model="user.user_type"
                class="font-size-input input-style mb-5 style_background"
                hide-details
                color="#e91e63"
                outlined
                dense
                label="Tipo de cuenta"
                :items="roles"
                item-text="name_es"
                item-value="name"
              />
            </v-col>

          </v-row>

          <v-row class="text-white ma-1">
            <v-col cols="12" lg="9" class="d-flex align-right">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="3" class="d-flex align-right">
              <v-btn 
                class="my-5 mx-1 cancel_gradient_border text-white text-transform-none transparent-background"
                @click="cancelConfirm"
              >
                Cancelar
              </v-btn>
              <v-btn 
                class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="createUser"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--       
      <pre style="height: 300px; overflow-y: scroll;" class="text-white">
        user: {{ user }}
        roles: {{ roles }}
      </pre>   
      -->
      

    </div>
  </section>
</template>

<script>
//import Csrf from '@/api/Csrf';
import Api from '@/api/Api';

import axios from 'axios';
import countries from '../../../util/countries';

export default {
  name: "create-new-user",
  components: {
    //
  },
  data() {
    return {
      roles: [],
      user: {
        name: null,
        last_name: null,
        second_last_name: null,
        email: null,
        password: null,
        password_confirmation: null,

        phone_prefix: null,
        phone: null,
        phone_whatsapp: false,

        country: null,
        state: null,
        user_type: null
      },
      preferredCountries: [
        'mx',
        'us',
        'ca'
      ],
      countries: countries,
    };
  },
  computed: {
    states() {
      if(!this.user.country) return [];
      return this.countries.find(item => item.country == this.user.country).states;
    }
  },
  watch: {
    user(value) {
      if (value != null) {
        //
      }
    },
  },
  methods: {
    async loadMoreItems({ page }) {
      try {
        this.loading = true;
        const response = await axios.get(`your-api-endpoint?page=${page}&search=${this.searchInput}`);
        this.items = [...this.items, ...response.data.items];
        this.serverItemsLength = response.data.totalItems;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async searchItems() {
      this.pagination.page = 1;
      await this.loadMoreItems({ page: 1 });
    },

    selectCountryCode: function ({ name, iso2, dialCode }) {
      this.user.phone_prefix = name + iso2;
      this.user.phone_prefix = '+'+dialCode;
    },

    getRoles: function () {
      Api.get('roles')
      .then(response => {
        this.roles = response.data;
      })
      .catch(error => this.errorHandling(error))
    },

    createUser: function () {
      Api.post('auth/create_user_as_admin', this.user)
      .then(response => {
        this.$swal("", response.data.message, "success");
        this.$router.push({ 
          name: 'Manage users', 
        })
      })
      .catch(error => this.errorHandling(error))
    },

    cancelConfirm: function () {
      this.$swal({
        title: "¿Esta seguro?",
        text: "Los cambios realizados no se guardarán",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ 
            name: 'Manage users'
          })
        }
      });
    },
  },
  created() {
    this.getRoles();
  }
};
</script>