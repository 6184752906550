<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="contact != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Detalles de mensaje de contacto</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="contact.user != null" class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Usuario que contacta</h3>
            </v-col>
            <v-col cols="12" lg="2" class="mt-0 pt-0 secondary-text">
              <v-avatar size="100" class="ma-0 my-2 rounded-avatar">
                <img :src="(contact.user.personal_information.profile_image_url != null) ? contact.user.personal_information.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
              </v-avatar>
            </v-col>
            <v-col cols="12" lg="10" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Nombre completo:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.user.personal_information.name != null) ? contact.user.personal_information.complete_name : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ contact.user.email }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.user.contact_information.phone != null) ? '('+contact.user.contact_information.phone_prefix+') '+contact.user.contact_information.phone : '---' }} {{ contact.phone_whatsapp ? '(con Whatsapp)' : '(sin Whatsapp)' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Ubicación:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.user.contact_information.state != null && contact.user.contact_information.country != null) ? contact.user.contact_information.state+', '+contact.user.contact_information.country : '---' }}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-else class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Usuario que contacta</h3>
            </v-col>
            <v-col cols="12" lg="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Nombre completo:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.complete_name != null) ? contact.complete_name : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.email != null) ? contact.email : '---' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ contact.complete_phone }} {{ contact.phone_whatsapp ? '(con Whatsapp)' : '(sin Whatsapp)' }}</v-col>

                <v-col cols="12" lg="5" class="mt-1 py-0 font-weight-bold">Ubicación:</v-col>
                <v-col cols="12" lg="7" class="mt-1 py-0">{{ (contact.state != null && contact.country != null)  ? contact.state+', '+contact.country : '---' }}</v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white">Mensaje</h3>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="6" class="mt-1 py-0 font-weight-bold">Motivo:</v-col>
                <v-col cols="12" lg="6" class="mt-1 py-0"
                  :class="contact.motive != null ? 'text-white' : 'text-body'"
                >
                  {{ (contact.motive != null) ? contact.motive : 'Sin motivo' }}
                </v-col>

                <v-col cols="12" lg="12" class="mt-1 pa-5 border-radius-lg blur blur-transparent">
                  <span v-html="(contact.body != null) ? contact.body : '---'" class="text-white parrafo ql-editor"></span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="9" class="mt-1 mb-5 py-0">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="3" class="mt-1 mb-5 py-0">
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="goBackToPrevoiusPage"
              >
                Volver
              </v-btn>
            </v-col>
          </v-row>

          

          <!--
          <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
            contact_id: {{ contact_id }}
            contact: {{ contact }}
          </pre> 
          -->
        </v-col>
      </v-row>
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';

  export default {
    name: "postulation-details",
    components: {
      //
    },
    data() {
      return {
        contact: null,

        contact_id: null,
      };
    },
    computed: {
      //
    },
    watch: {
      //
    },
    methods: {
      getContactInformation: function () {
        Api.get(`contacto/${this.contact_id}`)
        .then(response => {
          this.contact = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      setBudgetFormat: function (quantity) {
        return quantity.toLocaleString();
      },
      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      },
      
      goBackToPrevoiusPage: function () {
        this.$router.back();
      }
    },
    mounted() {
      this.contact_id = this.$route.params.contact_id;
      this.getContactInformation();
    }
  };
</script>