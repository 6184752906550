<template>
  <v-app-bar
    v-if="dialogForm == false"
    absolute
    height="auto"
    class="position-fixed z-index-sticky"
    :class="!navbarMainPage ? 'blur blur-transparent' : ''"
    flat
    :width="barWidth"
    :padding="barPadding"
    :color="!navbarMainPage ? opaqueNavbar : background"
    min-width="100%"
  >
    <v-container class="py-0">
      <v-row>
        <v-col cols="11" class="d-flex align-center navbar-center">
          <a href="/"
            @click="this.$emit('navbarMainPage', true)">
            <img
              src="@/assets/img/logo-filmmx.svg"
              alt="Film_Dgo_logo"
              height="40"
              class="mr-10"
            />
          </a>
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              v-for="(page, index) in navElements" :key="index"
              open-on-hover
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white btn-menu px-2"
                  depressed
                  link
                  :to="page.link"
                >
                <v-icon v-if="page.link == currentRoutePath" class="icon-active">fa-solid fa-play</v-icon>
                  {{ page.title }}
                </v-btn>
              </template>
            </v-menu>
            <v-menu
              transition="slide-y-transition"
              offset-y
              min-width="125px"
              max-width="125px"
              v-if="user == null"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-avatar size="30" class="my-0 ms-0">
                    <img src="https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png" alt="Profile Image" />
                  </v-avatar>
                </v-btn>
              </template>
              <v-list class="blur blur-transparent">
                <v-list-item
                  class="border-radius-md"
                  to="/login"
                >
                  <v-list-item-title class="text-white">Iniciar sesión</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="border-radius-md"
                  to="/register"
                >
                  <v-list-item-title class="text-white">Registrarse</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              transition="slide-y-transition"
              offset-y
              min-width="125px"
              max-width="125px"
              v-if="user != null"
            >
              <template v-slot:activator="{ on }">
              <v-btn
                :ripple="false"
                title="Go to admin page"
                color="transparent"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="text-white btn-menu d-flex my-1 px-0"
                depressed
                v-on="on"
              >
              <v-row class="ma-0 pa-0 d-flex text-center">
                <v-col cols="12" class="pa-0 ma-0 text-center">
                  <v-avatar size="30" class="my-0 ms-0">
                    <img :src="(user.profile_image_url) ? user.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
                  </v-avatar>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0 text-center">
                  <label class="text-xs">Mi perfil</label>
                </v-col>
              </v-row>
              </v-btn>
              </template>
              <v-list class="blur blur-transparent">
                <v-list-item
                class="
                border-radius-md"
                to="/admin/profile_overview"
                >
                  <v-list-item-title class="text-white">Ir a mi perfil</v-list-item-title>
                </v-list-item>
                <v-list-item
                @click="logoutUser"
                >
                  <v-list-item-title class="text-white">Cerrar sesión</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
            <!-- <v-btn v-if="user != null"
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="px-5 text-white btn-gradient"
              color="linear-gradient(to right, #FEB301, #C4114F)"
              style="text-transform: none;"
              large
              @click="logoutUser"
            >
              Cerrar sesión
            </v-btn>
            <v-btn v-else
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="px-5 text-white btn-gradient"
              color="linear-gradient(to right, #FEB301, #C4114F)"
              style="text-transform: none;"
              large
              outlined
              href="/login"
            >
              Ingresar
            </v-btn> -->


            
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white px-3 font-weight-bold"
              small
              :href="regularDomain+$route.fullPath"
            >
            <!-- @click="window.location.host = regularDomain" -->
              <label class="ma-0 pa-0 notranslate"
                :class="!actualDomain.includes('en.') ? 'selected-language font-weight-bold': ''"
              >
                ES
              </label>
            </v-btn>
            <p class="pa-0 ma-0" style="color: #FFFFFF; display: inline;">/</p>
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg } + ' ' + actualDomain.includes('en.') ? 'selected-language': ''"
              class="text-white px-3"
              small
              :href="englishDomain+$route.fullPath"
            >
            <!-- @click="window.location.host = englishDomain" -->
              <label class="ma-0 pa-0 notranslate"
                :class="actualDomain.includes('en.') ? 'selected-language font-weight-bold': ''"
              >
                EN
              </label>
            </v-btn>
            


          </template>
        </v-col>
        
      <v-col cols="1" class="d-flex justify-end" v-if="$vuetify.breakpoint.mobile">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding" dark>
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bolder
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                <a 
                  @click="navigateTo('/')">
                  <img
                    src="@/assets/img/logo-filmmx.svg"
                    alt="Film_Dgo_logo"
                    height="50"
                  />
                </a>
                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="20">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-btn
                  v-for="(page, index) in navElements"
                  :key="index"
                  :ripple="false"
                  text
                  class="text-capitalize ls-0"
                  depressed
                  link
                  :to="page.link"
                  color="white"
                  @click="dialog = false"
                >
                <v-icon v-if="page.link == currentRoutePath" class="icon-active">fa-solid fa-play</v-icon>
                  {{ page.title }}
                  <v-spacer></v-spacer>
                </v-btn>
                <div class="border-bottom my-7"></div>
              </v-card-text>
              <v-card-actions>

                <v-btn
                  v-if="user != null"
                  :ripple="false"
                  title="Go to admin page"
                  color="transparent"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white btn-menu d-flex my-1 px-0"
                  depressed
                  to="/admin/profile_overview"
                >
                  <v-row class="ma-0 pa-0 d-flex text-center">
                    <v-col cols="12" class="pa-0 ma-0 text-center">
                      <v-avatar size="30" class="my-0 ms-0">
                        <img :src="(user.profile_image_url) ? user.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0 text-center">
                      <label class="text-xs">Mi perfil</label>
                    </v-col>
                  </v-row>
                </v-btn>
                
                <v-btn v-if="user != null"
                :ripple="false"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="px-5 text-white btn-gradient"
                color="linear-gradient(to right, #FEB301, #C4114F)"
                style="text-transform: none;"
                @click="logoutUser"
                >
                  Cerrar sesión
                  <v-spacer></v-spacer>
                </v-btn>
                <v-btn v-else
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="px-5 text-white btn-gradient"
                  style="text-transform: none;"
                  outlined
                  to="/login"
                >
                  Ingresar / Registrarse
                </v-btn>

                <v-btn
                  icon
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-white px-3 font-weight-bold"
                  small
                  :href="regularDomain+$route.fullPath"
                >
                <!-- @click="window.location.host = regularDomain" -->
                  <label class="ma-0 pa-0 notranslate"
                    :class="!actualDomain.includes('en.') ? 'selected-language font-weight-bold': ''"
                  >
                    ES
                  </label>
                </v-btn>
                <p class="pa-0 ma-0" style="color: #FFFFFF; display: inline;">/</p>
                <v-btn
                  icon
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg } + ' ' + actualDomain.includes('en.') ? 'selected-language': ''"
                  class="text-white px-3"
                  small
                  :href="englishDomain+$route.fullPath"
                >
                <!-- @click="window.location.host = englishDomain" -->
                  <label class="ma-0 pa-0 notranslate"
                    :class="actualDomain.includes('en.') ? 'selected-language font-weight-bold': ''"
                  >
                    EN
                  </label>
                </v-btn>

                <!--
                <v-btn
                  icon
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-body px-5 font-weight-bold"
                  color="#C4114F"
                  small
                >
                  ES
                  <v-spacer></v-spacer>
                </v-btn>
                <p class="pa-0 ma-0" style="color: #FFFFFF; display: inline;">/ <v-spacer></v-spacer></p>
                <v-btn
                  icon
                  :ripple="false"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  class="text-body px-5"
                  :color="linkColor"
                  small
                >
                  EN
                  <v-spacer></v-spacer>
                </v-btn>
                -->
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!--       
      <pre>
        actualDomain: {{ actualDomain }}
        englishDomain: {{ englishDomain }}
        regularDomain: {{ regularDomain }}
      </pre> 
      -->
    </v-container>
  </v-app-bar>
</template>
<script>
//import Csrf from '@/api/Csrf';
import Api from '@/api/Api';

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "nav-bar",
  props: {
    navElements: Array,
    barWidth: String,
    barPadding: String,
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
    navbarMainPage: {
      type: Boolean,
      default: false,
    },
    dialogForm: Boolean
  },
  data() {
    return {
      //opaqueNavbar: 'rgba(255, 255, 255, 0.2) !important',
      //opaqueNavbar: 'rgba(123, 128, 154, 0.2) !important',
      opaqueNavbar: 'rgba(62, 62, 62, 0.6) !important',
      drawer: false,
      togglerActive: false,
      dropdown: [
        {
          icon: "email",
          title: "Check new messages",
        },
        {
          icon: "podcasts",
          title: "Manage podcast session",
        },
        {
          icon: "shopping_cart",
          title: "Payment successfully completed",
        },
      ],
      user: null,
      isLoggedIn: false,
      userEmail: null,
      dialog: false,
    };
  },
  
  methods: {
    getUserInfo: function () {
      Api.get('/auth/logged_user').then(response => {
        this.user = response.data.Personal_information;
      })
      .catch(error => this.errorHandling(error))
    },
    logoutUser: function () {
      Api.post('auth/logout')
      .then(() => {
        this.$swal("Hasta luego", "Sesion cerrada correctamente", "success");
        localStorage.removeItem("auth");
        localStorage.removeItem("userInfo");
        setTimeout(function () {
          location.reload();
        }, 1500);
      })
      .catch(error => this.errorHandling(error))
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('auth');
    this.userEmail = localStorage.getItem('userInfo');
    this.getUserInfo();
  },
  computed: {
    currentRoutePath() {
        return this.$route.path;
    },
    englishDomain () {
      return process.env.VUE_APP_ENGLISH_DOMAIN_URL;
    },
    regularDomain () {
      return process.env.VUE_APP_REGULAR_DOMAIN_URL;
    },
    actualDomain () {
      return window.location.host;
    }
  },
  watch: {
    //
  },
};
</script>
<style scoped>
  .container{
    max-width: 90%;
  }
  .rainbow_gradient {
    background-image: linear-gradient(to right, #FECB26, #D0212b, #8D2C6f, #0165FD) !important;
  }
  .v-tab:before {
    background-color: transparent !important;
  }
  .margin-view {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
  .btn-gradient{
    border-radius: 10px;
    font-weight: 500;
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #12141B inset;
  }
  .btn-menu{
    text-transform: none !important;
    font-weight: 400 !important;
  }
  .margin-logo{
    margin-right: 12vw;
  }
  .v-toolbar__content{
    padding: 0px, 0px !important;
  }
  .v-btn:not(.v-btn--round).v-size--large{
    height: 40px !important;
    max-width: 100px !important;
  }
  .fa-play{
    background-image: linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%) !important;
    color: transparent !important; 
    background-clip: text !important;
  }
  .icon-active{
    margin-right: 0.5vw;
  }
  .navbar-center{
    justify-content: center !important;
  }
  .container{
    padding: inherit;
  }
  @media (max-width: 1366px) {
    .container{
      max-width: 100%;
    }
    .navbar-center{
      justify-content: inherit !important;
    }
    .container{
      padding: 3vw 3vw;
    }
  }
</style>
