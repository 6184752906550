<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 mt-4">
      <v-row class="justify-content-center border-radius-lg my-0">
        <v-col cols="12" :lg="(loggedUser.Roles[0] == 'Admininstrator'
            || loggedUser.Roles[0] == 'Producer'
            || loggedUser.Roles[0] == 'Person'
            || loggedUser.Roles[0] == 'Crew') ? 4 : 6">
          <user-personal-information/>
        </v-col>

        <v-col cols="12" lg="8" class="d-flex justify-end" 
          v-if="loggedUser.Roles[0] == 'Admininstrator'
            || loggedUser.Roles[0] == 'Producer'
            || loggedUser.Roles[0] == 'Person'
            || loggedUser.Roles[0] == 'Crew'
          "
        >
          <postulations-table/>
        </v-col>
      </v-row>
    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  //import Api from '@/api/Api';

  import UserPersonalInformation from './UserInformation.vue';
  import PostulationsTable from './PostulationsTable.vue';
  import Api from '@/api/Api';

  export default {
    name: "Dashboard",
    components: {
      UserPersonalInformation,
      PostulationsTable
    },
    data() {
      return {
        loggedUser: {
          Roles: [
            null
          ]
        },
      };
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
    },
    created() {
      this.getUserInfo();
    }
  };
</script>  