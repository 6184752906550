<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <div
          class="page-header main-image min-vw-100"
          :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/Dunasbilba.jpg')}) !important; background-size: cover; background-position: 50%;`"
        >
        </div>
      </v-col>
    </v-row>

    <v-row class="margin-view margin-search">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-card class="border-radius-xl blur blur-transparent">
          <v-row class="card-margin" justify="center">
            <v-col cols="12" xs="12" sm="12" md="12" lg="3"
              v-if="!actualDomain.includes('en.')"
            >
              <label class="ms-0 text-white">Buscar</label>
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar aquí"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" :lg="!actualDomain.includes('en.') ? 3 : 4">
              <label class="ms-0 text-white">Tipo se solicitud</label>
              <v-select
                v-model="temporalSectionType" 
                color="white" 
                dark 
                item-text="text"
                item-value="value"
                :items="selectSectionType"
                label="Tipo de solicitud"
                @change="getCategories(temporalSectionType)">
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" :lg="!actualDomain.includes('en.') ? 3 : 4">
              <label class="ms-0 text-white">Categoria</label>
              <v-select
                v-model="temporalCategory" 
                color="white" 
                dark 
                clearable
                item-text="name"
                item-value="id"
                :items="categories"
                label="Categoría"
                :disabled="categories.length < 1"
                @change="getSubcategories(temporalCategory)">
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" :lg="!actualDomain.includes('en.') ? 3 : 4">
              <label class="ms-0 text-white">Subcategoria</label>
              <v-select
                v-model="temporalSubCategory" 
                color="white" 
                dark 
                clearable
                item-text="name"
                item-value="id"
                :items="subcategories"
                label="Subcategoría"
                :disabled="subcategories.length < 1"
                @change="getJobs()"
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3">
              <label class="ms-0 text-white">Depart</label>
              <v-select color="white" dark items="example">
              </v-select>
            </v-col> -->
            <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3" class="pt-8">
              <v-btn 
                class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                x-large
                @click="getJobs()"
              >
                Buscar
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="margin-card margin-view">
        <v-col v-for="(empleo, index) in jobs.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
          <v-card
            class="text-white"
            outlined color="transparent"
          >
            <div class="mt-n11 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  :src="empleo.contents_info.main_image_url ? empleo.contents_info.main_image_url.value : ''"
                  class="img-middle img-fluid shadow border-radius-lg not-alter-image"
                />
              </div>
            </div>
            <h5 class="my-2 px-4 text-white">
              {{ (empleo.section_type_id == 1) ? 'Empleo' : (empleo.section_type_id == 14) ? 'Proveedores': 'Solicitud' }}
            </h5>
            <h2 class="mt-0 mb-2 px-4 text-white notranslate">
              {{ empleo.contents_info.title ? empleo.contents_info.title.value : '-' }}
            </h2>
            <div class="text-broken">
              <p v-html="empleo.contents_info.main_description ? empleo.contents_info.main_description.value : '-'" class="mb-7 px-4 text-justify"></p>
            </div>
            <v-card-actions>
              <v-btn class="btn-gradient" large :to="`/jobsInformation/${empleo.id}`">
                Ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-card">
        <v-col cols="12" lg="12" class="d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination table-pagination"
                total-visible="6"
                :length="jobs.last_page"
                v-model="jobs.current_page"
                circle
                @input="getJobs"
              />
        </v-col>
      </v-row>
  </v-container>  
</template>

<script>
import Api from '@/api/Api';

export default {
    props: {
    },
    data() {
        return {
            visible: false,
            index: 0,
            categories: [],
            subcategories: [],
            temporalSectionType: 1,
            selectSectionType: [
              { text: 'Proveedor', value: 13 },
              { text: 'Empleo', value: 1 }
            ],
            temporalCategory: 0,
            temporalSubCategory: 0,
            jobs: [],
            search: '',
            itemsPerPage: 9
        };
    },
    computed: {
      actualDomain () {
        return window.location.host;
      }
    },
    watch: {
      temporalSectionType (value) {
        this.temporalCategory = 0;
        this.temporalSubCategory = 0;
        this.getJobs();
        this.getCategories(value);
      },
      temporalCategory (value) {
        this.temporalSubCategory = 0;
        this.getJobs();
        this.getSubcategories(value);
      },
    },
    created() {
      this.$emit('navbarMainPage',false);
      this.getJobs();
      this.getCategories(this.temporalSectionType);
    },
    methods: {
      getCategories(sectionTypeId){
        Api.get(`category/get_by_section_type/${sectionTypeId}`)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getSubcategories(category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      getJobs:function (page = 1){
        Api.get(`section/get_section_by_section_type/${this.temporalSectionType}`, {
          params: {
            page: page,
            query: this.search,
            paginate: this.itemsPerPage,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
            category: this.temporalCategory,
            subCategory: this.temporalSubCategory
          }
        })
        .then(response => {
          this.jobs = response.data;
        })
        .catch(error => this.errorHandling(error))
      }
    }
};
</script>
<style scoped>
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.card-margin{
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-search{
  margin-bottom: 20vh;
}
.margin-card{
  margin-bottom: 20vh;
}
.v-btn:not(.v-btn--round).v-size--x-large {
    min-width: 100% !important;
}
.card-padding{
  padding-right: 3% !important;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}

.content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  overflow: hidden;
}
</style>