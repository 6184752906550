<template style="font-family: 'roboto';">
  <section :class="$route.fullPath.includes('/admin/applications_management') ? 'mt-8' : ''">
    <div class="container border-radius-lg blur blur-transparent full-height">
      <v-row class="justify-content-center full-height">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-1">
              <h5 class="text-h5 text-white mb-0">
                {{ (loggedUser.Roles[0] == 'Admininstrator') ? 'Postulaciones' : 'Mis postulaciones' }}
              </h5>
              <p class="text-body mb-0">
                {{ application_type_text_es }}
              </p>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                  search-input-height
                "
                dense
                flat
                filled
                solo
                v-model="search"
                placeholder="Buscar por nombre/correo de usuario"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="11" lg="6" class="align-left">
              <v-select
                v-if="application_type_text"
                v-model="incentive_id"
                item-text="`${data.item.contents_info.title.value}`"
                :items="existingIncentives"
                label="Convocatoria a la que se postula"
                required
                clearable
                class="font-size-input input-style input-white-text my-0 py-0 notranslate"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.contents_info.title.value }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    {{ data.item.contents_info.title.value }}
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white notranslate">
            <thead class="text-white">
              <tr>
                <th>
                  <v-btn class="text-white btn-sort-table text-transform-none"
                    :ripple="false"
                    @click="sorting('id')"
                  >
                    Id
                    <v-icon v-if="sortBy == 'id' && isSort">
                      {{ order  ? 'arrow_drop_up' : 'arrow_drop_down' }}
                    </v-icon>
                  </v-btn>
                </th>
                <!-- <th>Tipo de postulación</th> -->
                <th>
                  Usuario que se postula
                </th>
                <th v-if="application_type_id == 2">Nombre de proyecto</th>
                <th v-if="application_type_id == 1 || application_type_id == 14">
                  Empresa que publicó la solicitud
                </th>
                <th v-if="application_type_id == 1 || application_type_id == 14" >Fecha de postulación</th>
                <th>
                  Estado de la vinculación
                </th>
                <th>
                  <v-btn class="text-white btn-sort-table text-transform-none"
                    :ripple="false"
                    @click="sorting('approved')"
                  >
                    {{ application_type_id == 2 ? 'Estado de la postulación' : 'Estado'}}
                    <v-icon v-if="sortBy == 'approved' && isSort">
                      {{ order  ? 'arrow_drop_up' : 'arrow_drop_down' }}
                    </v-icon>
                  </v-btn>
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(application, index) in applications.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ application.id }}
                    </span>
                  </div>
                </td>

                <!-- 
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ application.application_type_es }}
                    </span>
                  </div>
                </td> 
                -->

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (application.user.personal_information.name != null) ? application.user.personal_information.complete_name : application.user.email }}
                    </span>
                  </div>
                </td>

                <td v-if="application_type_id == 2">
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal"
                    :class="(application.film_project != null) ? 'text-white' : 'text-body'"
                    >
                      {{ (application.film_project != null) ? application.film_project.name : 'n/a' }}
                    </span>
                  </div>
                </td>

                <td v-if="application_type_id == 1 || application_type_id == 14">
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (application.section.user_owner.companies[0] &&  application.section.user_owner.companies[0].company.name != null) ? application.section.user_owner.companies[0].company.name : (application.section.user_owner.personal_information.name != null) ? application.section.user_owner.personal_information.complete_name : application.section.user_owner.email }}
                    </span>
                  </div>
                </td>

                <td v-if="application_type_id == 1 || application_type_id == 14">
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ formatDate(application.created_at) }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (application.status != null) ? application.status : '---' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ (application.approved == null) ? 'Pendiente' : (application.approved == true) ? 'Aprobada' : 'Rechazada' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn 
                          icon text rel="tooltip" title="Revisar detalles de la postulación"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/application_details/'+application.id"
                        >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            visibility
                          </v-icon>
                        </v-btn>
                        <v-btn 
                          v-if="application.slug == 'incentive'"
                          icon text rel="tooltip" title="Revisar información completa del incentivo"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/incentive_full_information/'+application.id"
                        >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            preview
                          </v-icon>
                        </v-btn>      
                        <v-btn icon text rel="tooltip" title="Aprobar postulación"
                          v-if="
                            (
                              loggedUser.User.email == application.section.user_owner.email 
                              && 
                              application.approved == null
                            ) 
                            && 
                            (
                              (application_type_id == 2 && application.status == 'postulado') 
                              || 
                              (application_type_id != 2 && application.status == 'Entrevista pendiente')
                            )
                          "
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="approveOrReject(application.id, true)">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            task_alt
                          </v-icon>
                        </v-btn>   
                        <v-btn icon text rel="tooltip" title="Rechazar postulación"
                          v-if="
                            (
                              loggedUser.User.email == application.section.user_owner.email 
                              && 
                              application.approved == null
                            ) 
                            && 
                            (
                              (application_type_id == 2 && application.status == 'postulado') 
                              || 
                              (application_type_id != 2 && application.status == 'Entrevista pendiente')
                            )
                          "
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="approveOrReject(application.id, false)">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            unpublished
                          </v-icon>
                        </v-btn>
                        <v-btn icon text rel="tooltip" title="Marcar para agendar entrevista"
                          v-if="loggedUser.User.email == application.section.user_owner.email && application.approved == null && application.status == 'Solicitud de entrevista'" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="bookInterview(application.id)">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            calendar_month
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
              <tr v-if="applications.data.length == 0">
                <td colspan="5" class="pa-3 align-center text-secondary" style="text-align: center;">
                  Aún no hay registros
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ applications.from }} a {{ applications.to }} de {{ applications.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="applications.current_page"
                :length="applications.last_page"
                circle
                @input="getApplications"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--         
        <v-col cols="12">
          <pre style="width: 600px; height: 300px; overflow-y: scroll; overflow-x: scroll;" class="text-white">
            incentive_id: {{ incentive_id }}
            applications: {{ applications }}
          </pre>
        </v-col>    
        -->
        

      </v-row>
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  export default {
    name: "applications-management",
    components: {
      //
    },
    data() {
      return {
        applications: {
          data: []
        },
        loggedUser: {
          Personal_information: {
            complete_name: "  "
          },
          Roles: [
            null
          ]
        },

        application_type_text: null,
        application_type_id: null,

        application_type_text_es: null,

        //Used to consult specific info
        search: null,
        isSort: true,
        sortBy: 'id',
        order: false,

        //Used to filter application y incentive
        incentive_id: null,
        existingIncentives: [],
      };
    },
    watch: {
      search () {
        this.debounceSearchFunction();
      },
      sortBy: function () {
        this.getApplications();
      },
      order: function () {
        this.getApplications();
      },
      incentive_id: function () {
        this.getApplications();
      }
    },
    methods: {
      getSectionTypeTraduction: function() {
        Api.get(`section_type/${this.application_type_id}`)
        .then(response => {
          this.application_type_text_es = response.data.section_type_es;
        })
        .catch(error => this.errorHandling(error))
      },
      getApplications: function (page = 1) {
        Api.get(`vinculations?page=${page}`, {
          params: {
            search: this.search,
            paginate: 10,
            sortBy: this.sortBy,
            order: this.order ? 'asc' : 'desc',
            section_owner_id: (this.incentive_id != null) ? this.incentive_id.id : 0, 
            application_type: this.application_type_text
          }
        }).then(response => {
          this.applications = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      sorting: function(column) {
        if(column == this.sortBy) {
          this.order = !this.order;
        }
        this.sortBy = column;
        this.isSort = true;
      },
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;

          if (this.$route.fullPath.includes('applications_management')) {
            this.application_type_text = this.$route.params.application_type;
            this.application_type_id = this.$route.params.section_type_id;
          } else {

            if (this.loggedUser.Roles[0] != null && (this.loggedUser.Roles[0] == 'Person' || this.loggedUser.Roles[0] == 'Crew') ) {
              this.application_type_text = 'job_offer';
              this.application_type_id = 1;
            } else {
              this.application_type_text = 'incentive';
              this.application_type_id = 2;
            }
          }

          this.getSectionTypeTraduction();
          this.getApplications();
          this.getIncentiveApplications();

        })
        .catch(error => this.errorHandling(error))
      },

      getIncentiveApplications: function () {
        Api.get(`section/show_by_section_type/${this.application_type_id}`, {
          params: {
            contents: ['title'],
            pagination_required: 'No'
          }
        })
        .then(response => {
          this.existingIncentives = response.data;
          this.incentive_id = null;
        })
        .catch(error => this.errorHandling(error))
      },

      approveOrReject: function(SectionId, value) {
        this.$swal({
          title: ((value) ? '¿Aprobar postulación?' : '¿Rechazar postulación?'),
          text: "",
          type: "info",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`vinculations/approve_reject_vinculation`, {
              vinculation_id: SectionId,
              approved: value
            })
            .then(response => {
              this.$swal.fire("Success", response.data.message, "success");
              this.getApplications();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },

      bookInterview: function (applicationId) {
        this.$swal({
          title: '¿Desea programar una entrevista para esta postulación?',
          text: "",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
              Api.post(`vinculations/change_vinculation_status/${applicationId}?status=${'Entrevista pendiente'}`)
              .then(response => {
                if (response.data.status == true) {
                  this.$swal('', response.data.message, "success");
                  this.getApplications();
                } else {
                  this.$swal('', response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error));
          } 
        });
      },

      formatDate: function (raw_date) {
        let day_birth = '00';
        let month_birth = '00';
        let year_birth = '0000';
        
        year_birth = raw_date.substring(0, 4);
        month_birth = raw_date.substring(5, 7);
        day_birth = raw_date.substring(8, 10);
        
        let month_birth_text = '';

        let final_date = '';

        switch (month_birth) {
          case '01': month_birth_text = 'enero'; break;
          case '02': month_birth_text = 'febrero'; break;
          case '03': month_birth_text = 'marzo'; break;
          case '04': month_birth_text = 'abril'; break;
          case '05': month_birth_text = 'mayo'; break;
          case '06': month_birth_text = 'junio'; break;
          case '07': month_birth_text = 'julio'; break;
          case '08': month_birth_text = 'agosto'; break;
          case '09': month_birth_text = 'septiembre'; break;
          case '10': month_birth_text = 'octubre'; break;
          case '11': month_birth_text = 'noviembre'; break;
          case '12': month_birth_text = 'diciembre'; break;
          default: month_birth_text = 'Mes'; break;
        }
        
        final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

        return final_date;
      }
    },
    created() {
      this.getUserInfo();

      this.debounceSearchFunction = debounce(() => {
        this.getApplications();
      }, 500);
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>