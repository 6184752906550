<template>
    <v-container>
        <v-row class="margin-view margin-navbar">
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" class="margin-menu">
                <v-card
                    color="#282c39"
                >
                    <v-list
                    rounded
                    dense
                    color="#282c39"
                    >
                    <v-list-item
                        v-for="(permission,index) in permissions.data" :key="index"
                        link
                        @click="scrollToPermission(permission.id)"
                    >
                        <v-list-item-content class="margin-list-content">
                        <v-list-item-title class="text-white text-wrap" v-if="permission.contents_info.title">{{ permission.contents_info.title ? permission.contents_info.title.value : '' }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="9" lg="9">
                <h1 class="text-white text-center margin-title">Permisos</h1>
                <div class="margin-permission" :ref="`permiso${permission.id}`" v-for="(permission,index) in permissions.data" :key="index">
                <h2 class="text-white">{{ permission.contents_info.title ? permission.contents_info.title.value : '' }}</h2>
                <br>
                <span class="ql-editor text-white text-justify" v-html="permission.contents_info.main_description ? permission.contents_info.main_description.value : ''"></span>
                <br>
                <div v-if="permission.contents_info.permit_file">
                <span class="text-white mb-5">
                Descargar archivo
                </span>
                <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="4" class="pt-8">
                    <v-btn 
                        class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                        x-large
                        target="_blank"
                        :href="permission.contents_info.permit_file.value"
                    >
                        Descargar
                    </v-btn>
                </v-col>
                </v-row>
                </div>
                <v-divider color="white"></v-divider>
                </div>
            </v-col>
        </v-row>
        <v-row class="margin-view margin-pagination">
        <v-col cols="12" lg="12" class="d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination table-pagination"
                :length="permissions.last_page"
                total-visible="6"
                v-model="permissions.current_page"
                circle
                @input="getPermissionsSection"
              />
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Api from '@/api/Api';
export default {

    data(){
        return{
            permissions: [],
            itemsPerPage: 10
        }
    },
    created(){
        this.getPermissionsSection();
    },
    mounted(){
    },
    methods: {
        getPermissionsSection:function (page = 1){
            Api.get('section/get_section_by_section_type/11', {
                params: {
                    page: page,
                    paginate: this.itemsPerPage,
                    pagination_required: 'Yes',
                    contents: ['title','main_description',"permit_file"]
                }
            })
            .then(response => {
            this.permissions = response.data;
            })
            .catch(error => this.errorHandling(error))
        },
        scrollToPermission(id) {
            const element = "permiso" + id;
            if (element) {
                this.getScrollIntoView(element);
            }
        },
        getScrollIntoView(element) {
            const targetElement = this.$refs[element];
            if (targetElement) {
                targetElement[0].scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>
<style scoped>
.margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
}
.margin-navbar{
    margin-top: 5vh;
}
.margin-permission{
    margin-bottom: 10vh;
}
.margin-title{
    margin-bottom: 5vh;
}
.margin-menu{
    margin-top: 5vh;
}
.margin-list-content{
    margin-bottom: 10px;
}
.margin-pagination{
    margin-bottom: 10vh;
}
</style>