<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Información completa del incentivo</label>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col v-if="incentive != null" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="12" class="mt-1 py-0 incentive_body_span d-flex justify-center">
                  <p>
                    A continuación se muestra la información completa del incentivo en el que mostró interés, así como un teléfono y correo de contacto para cualquier aclaración.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col v-if="incentive != null" cols="12" lg="12" class="mt-1 mb-5 py-0">
              <h3 class="text-white notranslate">Información del incentivo {{ (incentive.contents_info.title && incentive.contents_info.title.value != null) ? '"'+incentive.contents_info.title.value+'"' : '' }}</h3>
            </v-col>
            <v-col v-if="incentive != null" cols="12" class="mt-0 pt-0 secondary-text">
              <v-row class="text-white ma-0" justify="space-around">
                <v-col cols="12" lg="12" class="mt-1 py-0">
                  <span v-if="incentive.contents_info.body_incentive && incentive.contents_info.body_incentive.value != null" 
                    class="incentive_body_span" 
                    v-html="incentive.contents_info.body_incentive.value"
                  ></span>
                </v-col>

                <v-col cols="12" lg="12" class="mt-1 py-0">
                  <br>
                </v-col>

                <v-col cols="12" lg="3" class="mt-1 py-0 font-weight-bold">Teléfono:</v-col>
                <v-col cols="12" lg="9" class="mt-1 py-0">{{ (incentive.contents_info.phone && incentive.contents_info.phone.value != null) ? incentive.contents_info.phone.value : '---' }}</v-col>

                <v-col cols="12" lg="3" class="mt-1 py-0 font-weight-bold">Email:</v-col>
                <v-col cols="12" lg="9" class="mt-1 py-0">{{ (incentive.contents_info.email && incentive.contents_info.email.value != null) ? incentive.contents_info.email.value : '---' }}</v-col>
                
              </v-row>
            </v-col>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" :lg="(loggedUser.Roles[0] == 'Admininstrator' && incentive.approved == null) ? 7 : 9" class="mt-1">
              <!--  -->
            </v-col>
            <v-col cols="12" :lg="(loggedUser.Roles[0] == 'Admininstrator' && incentive.approved == null) ? 5 : 3" class="mt-1">
              <v-btn class="my-5 mx-2 cancel_gradient_background text-white text-transform-none"
                @click="goBackToPrevoiusPage"
              >
                Volver
              </v-btn>
              <v-btn
                v-if="application != null && application.user.email == loggedUser.User.email && application.status == 'interesado'" 
                class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="applyToIncentive"
              >
                Postularme
              </v-btn>
            </v-col>
          </v-row>
          <!--
          <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
            incentive_id: {{ incentive_id }}
            incentive: {{ incentive }}
          </pre> 
          -->
        </v-col>
      </v-row>
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';

  export default {
    name: "postulation-details",
    components: {
      //
    },
    data() {
      return {
        loggedUser: {
          Roles: [
            null
          ]
        },

        application_id: null,
        application: null,


        incentive_id: null,
        incentive: null,
      };
    },
    computed: {
      //
    },
    watch: {
      loggedUser(value) {
        if (value != null) {
          //
        }
      },
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.loggedUser = response.data;

          this.getApplicationInformation()
        })
        .catch(error => this.errorHandling(error))
      },
      getApplicationInformation: function () {
        Api.get(`vinculations/${this.application_id}`)
        .then(response => {
          this.application = response.data;

          if (this.application.user.email == this.loggedUser.User.email) {
            this.incentive_id = this.application.incentive_information.id;
            this.getIncentiveInformation();
          } else {
            this.$swal('Acceso denegado', 'Usted no es dueño de la postulación consultada', "error");
            this.$router.push({ 
              name: 'Applications management'
            })
          }
        })
        .catch(error => this.errorHandling(error))
      },
      applyToIncentive: function () {
        this.$swal({
          title: '¿Desea postularse a este incentivo?',
          text: "",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
              Api.post(`vinculations/change_vinculation_status/${this.application_id}?status=postulado`)
              .then(response => {
                if (response.data.status == true) {
                  this.$swal('', response.data.message, "success");
                  this.goBackToPrevoiusPage();
                } else {
                  this.$swal('', response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error));
          } 
        });
      },
      getIncentiveInformation: function () {
        Api.post(`section/get_specific_contents`, {
          section_id: this.incentive_id,
          contents: [
            'title',
            'body_incentive',
            'phone',
            'email'
          ],
          origin: 'incentive_full_info'
        })
        .then(response => {
          this.incentive = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      
      goBackToPrevoiusPage: function () {
        this.navigateTo('/admin/applications_management/incentive/2');
        /*
        this.$router.push({ 
          name: 'Applications management'
        })
        */
      }
    },
    beforeMount() {
      this.application_id = this.$route.params.application_id;
      this.getUserInfo();
      //this.getApplicationInformation();
    },
    mounted() {
      //
    }
  };
</script>