<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 py-10 ">
      <v-row class="justify-content-center border-radius-lg">
        <v-col cols="12" class="pa-0">
          <v-btn 
            class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
            @click="openAddCategoryModal"
          >
            Nueva categoría
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                Categorías de secciones
              </h5>
              <p class="text-body mb-0">
                Página para gestionar las categorías y subcategorías de sección
              </p>
            </v-col>
            <v-col cols="11" lg="3" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar por nombre"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="11" lg="3" class="d-flex align-left">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <v-select
                id="select_filter_section_type"
                v-model="filter_section_type"
                class="font-size-input input-style input-white-text my-0 py-0"
                hide-details
                clearable
                label="Tipo de sección"
                :items="sectionTypes"
                item-text="section_type_es"
                item-value="id"
                @input="getCategories"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white notranslate">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Tipo de sección</th>
                <th>Categoría</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(category, index) in categories.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ category.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ category.section_type.section_type_es }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ category.name }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Editar nombre de categoría"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="openChangeCategoryNameModal(category)"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            edit
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Gestionar subcategorías" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/categories_management/subcategory/'+category.id"
                        >
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            table_view
                          </v-icon>
                        </v-btn>
                        <v-btn icon text rel="tooltip" title="Borrar categoría" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteCategory(category.id)"
                          v-if="category.section_type_id != 4"
                        > 
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ categories.from }} a {{ categories.to }} de {{ categories.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                id="paginator_categories"
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="actualTablePage"
                :length="categories.last_page"
                circle
                @input="getCategories"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--         
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            currentCategory: {{ currentCategory }}
            categories: {{ categories }}
            search: {{ search }}
            page: {{ page }} 
          </pre>
        </v-col>   
         -->

      </v-row>

      <!-- Modal for add new category or change category name -->
      <v-dialog v-model="showNewNameModal" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="400px" height="100%">
          <v-card-text>
            <v-row class="my-5">
              <v-col cols="12" class="d-flex">
                <label class="text-white new-section-title">{{ (categoryAction == 'edit') ? 'Editar nombre de categoría' : 'Añadir nueva categoría' }}</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showNewNameModal = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>

              <v-col cols="12" class="text-white"
                v-if="categoryAction == 'edit'"
              >
                <p class="my-1"><b>id:</b> &nbsp;&nbsp; {{ currentCategory.id }}</p>
                <p class="my-1"><b>Nombre actual:</b> &nbsp;&nbsp; {{ currentCategory.name }}</p>
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-select
                  v-model="currentCategory.section_type_id"
                  class="font-size-input input-style mb-0 style_background"
                  hide-details
                  color="#e91e63"
                  outlined
                  dense
                  label="Tipo de sección"
                  :items="sectionTypes"
                  item-text="section_type_es"
                  item-value="id"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="currentCategory.newName"
                  :label="(categoryAction == 'edit') ? 'Nuevo nombre' : 'Nombre de categoría'"
                  placeholder="categoría"
                  color="text-white"
                  class="input-style input-white-text"
                  :class="(categoryAction == 'edit') ? 'mb-5' : ''"
                  hide-details
                />
              </v-col>
              <v-col cols="12" v-if="categoryAction == 'new'">
                <v-text-field
                  v-model="currentCategory.newSubcategory"
                  label="Subcategoría"
                  placeholder="subcategoría"
                  color="text-white"
                  class="input-style mb-5 input-white-text"
                  hide-details
                />
                <p class="text-white">
                  Debe añadir al menos una subcategoría para la nueva categoría
                </p>
              </v-col>
            </v-row>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="storeCategory"
              :loading="btnLoadingNewName"
              v-if="categoryAction == 'new'"
            >
              Guardar
            </v-btn>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="changeCategoryName"
              :loading="btnLoadingNewName"
              v-if="categoryAction == 'edit'"
            >
              Actualizar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </section>
</template>
  
  <script>
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  export default {
    name: "categories-management",
    components: {
      //
    },
    data() {
      return {
        actualTablePage: 1,
        sectionTypes: [],

        filter_section_type: null,

        categories: {
          data: []
        },
        search: '',
        page: 1,

        categoryAction: null,

        btnLoadingNewName: false,
        showNewNameModal: false,
        currentCategory: {
          id: null,
          name: null,
          newName: null,
          newSubcategory: null,
          section_type_id: 1
        },
      };
    },
    watch: {
      search () {
        this.debounceSearchCategory();
      },
      showNewNameModal (value) {
        if (value == false) {
          this.currentCategory = {
            id: null,
            name: null,
            newName: null,
            newSubcategory: null,
            section_type_id: 1
          }
        }
      }
      
    },
    methods: {
      getSectionTypes: function () {
        Api.get(`section_type`)
        .then(response => {
          this.sectionTypes = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getCategories: function () {
        Api.get('category', {
          params: {
            page: this.actualTablePage,
            sectionTypeId: (this.filter_section_type != null) ? this.filter_section_type : 0,
            search: this.search
          }
        }).then(response => {
          this.categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      openAddCategoryModal: function () {
        this.categoryAction = "new";
        this.currentCategory.newName = null;

        this.showNewNameModal = true;
      },
      storeCategory: function () {
        this.btnLoadingNewName = true;
        Api.post('category', this.currentCategory)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.showNewNameModal = false;
          this.getCategories();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewName = false;
        })
      },

      openChangeCategoryNameModal: function (category) {
        this.currentCategory.id = category.id;
        this.currentCategory.name = category.name;
        this.currentCategory.newName = category.name;
        this.currentCategory.section_type_id = category.section_type_id;

        this.categoryAction = "edit";

        this.showNewNameModal = true;
      },
      changeCategoryName: function () {
        this.btnLoadingNewName = true;
        Api.patch(`category/${this.currentCategory.id}`, this.currentCategory)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.showNewNameModal = false;
          this.getCategories();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewName = false;
        })
      },
      
      deleteCategory: function (itemId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`category/${itemId}`)
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getCategories();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      
    },
    created() {
      this.getCategories();
      this.getSectionTypes();
      this.debounceSearchCategory = debounce(() => {
        this.getCategories();
      }, 500);
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>