<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10 pa-5 mt-5">
        <v-col cols="12" class="d-flex">
          <label class="text-white new-section-title">{{ (action_edit == 'edit') ? 'Actualizar información de experiencia profesional' : 'Añadir nueva experiencia profesional' }}</label>
        </v-col>

        <v-col cols="12" lg="6" class="mb-5">
          <v-text-field
            v-model="currentRecord.title_name"
            label="Nombre"
            placeholder="Certificado de preparatoria"
            color="text-white"
            class="input-style mb-0 input-white-text"
            hide-details
          />
        </v-col>

        <v-col cols="12" lg="6" class="mb-5">
          <v-text-field
            v-model="currentRecord.organization"
            label="Organización / centro educativo en donde se obtuvo"
            placeholder="ITD"
            color="text-white"
            class="input-style mb-0 input-white-text"
            hide-details
          />
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-select
            v-model="currentRecord.slug"
            label="Tipo de experiencia"
            :items="selectPersonType"
            item-text="text"
            item-value="value"
            required
            class="font-size-input input-style mb-0 input-white-text"
          />
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-text-field
            v-model="currentRecord.start_date"
            type="datetime-local"
            label="Fecha de inicio"
            class="input-style mb-5 input-white-text"
          />
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-text-field
            v-model="currentRecord.end_date"
            type="datetime-local"
            label="Fecha de término"
            class="input-style mb-5 input-white-text"
          />
        </v-col>

        <v-col cols="12" lg="12" class="mb-5 px-0">
          <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white">Descripción de la experiencia / certificación / funciones laborales</label>
            </v-col>
            <hr style="width: 100%; border: 1px solid #7b809a;">
            <v-col cols="12" lg="12" class="mt-1">
              <VueEditor 
                v-model="currentRecord.description"
                class="input-style ma-0 pa-1 input-white-text white-background"
                :editorOptions="optionsEditor"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="8" class="d-flex align-right">
          <!--  -->
        </v-col>
        <v-col cols="12" lg="4" class="d-flex align-right">
          <v-btn class="my-5 mx-2 cancel_gradient_background text-white text-transform-none"
            @click="goBackToPrevoiusPage"
          >
            Cancelar
          </v-btn>

          <v-btn 
            class="my-5 mx-2 main_gradient_background text-white text-transform-none ma-2" 
            @click="storeRecord"
            :loading="btnLoadingNewRecord"
            v-if="action_edit == 'new'"
          >
            Guardar
          </v-btn>
          <v-btn 
            class="my-5 mx-2 main_gradient_background text-white text-transform-none ma-2" 
            @click="updateRecord"
            :loading="btnLoadingNewRecord"
            v-if="action_edit == 'edit'"
          >
            Actualizar
          </v-btn>
        </v-col>
      </v-row>
      <!--
      <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
        action_edit: {{ action_edit }}
        experience_id: {{ experience_id }}
        currentRecord: {{ currentRecord }}
      </pre> 
      -->
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  import { VueEditor } from "vue2-editor";
  import debounce from "lodash.debounce";

  export default {
    name: "actions-company-modal",
    components: {
      VueEditor
    },
    data() {
      return {
        action_edit: null,
        experience_id: null,

        localShow: false,

        //used to crud operations on a record
        btnLoadingNewRecord: false,
        
        currentRecord: {
          slug: null,
          title_name: null,
          organization: null,
          description: null,
          start_date: null,
          end_date: null
        },

        btnLoading: false,
        
        selectPersonType: [
          { text: 'Académica', value: 'academic'},
          { text: 'Laboral', value: 'laboral'},
          { text: 'Certificación', value: 'certification'}
        ],

        optionsEditor: {
          theme: 'snow',
          formats: [
            'bold',
            'italic',
            'list',
            'align',
          ],
          modules: {
            toolbar: [
              ['bold', 'italic'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ align: "left" }, { align: "center" }, { align: "right"}, { align: "justify"}]
            ],
          }
        },
      };
    },
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      //
    },
    watch: {
      //
    },
    methods: {
      getItem: function() {
        Api.get(`proffesional_experience/${this.experience_id}/edit`)
        .then(response => {
          this.currentRecord = response.data;
        })
        .catch(error => this.errorHandling(error));
      },

      updateRecord: function () {
        this.btnLoadingNewRecord = true;
        Api.patch(`proffesional_experience/${this.currentRecord.id}`, this.currentRecord)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.goBackToPrevoiusPage();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewRecord = false;
        })
      },

      storeRecord: function () {
        this.btnLoadingNewRecord = true;
        Api.post('proffesional_experience', this.currentRecord)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.goBackToPrevoiusPage();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewRecord = false;
        })
      },
      
      goBackToPrevoiusPage: function () {
        this.$router.back();
        /*
        this.$router.push({ 
          name: 'Manage users'
        })
        */
      }
    },
    created() {
      this.action_edit = this.$route.params.action;
      this.experience_id = this.$route.params.experience_id;

      if (this.action_edit == 'edit' && this.experience_id != null) {
        this.getItem();
      }

      this.debounceVerifyName = debounce(() => {
        this.checkIfCompanyExists();
      }, 500);
    }
  };
</script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }

  .upload-image-card {
    width: initial !important;
  }
</style>