<template>
    <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="[jobsInformation.contents_info.secondary_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${jobsInformation.contents_info.secondary_image_url.value})`,backgroundSize:'cover'} :{ background:'#12141B' }]"
          >
            <span class="mask opacity-2"></span>
          </div>
          </v-col>
      </v-row>
      <v-row class="margin-view margin-main-image mb-1">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="text-white movie-description d-flex flex-column">
          <h1 class="text-white movie-title notranslate">{{ (jobsInformation.contents_info.title) ? jobsInformation.contents_info.title.value : '---' }}</h1>
          <br>
          <v-row class="my-3">
            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Categoría</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ jobsInformation.categories_subcategories[0].category.name }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Subcategoría</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ jobsInformation.categories_subcategories[0].subcategory.name }}</p>
            </v-col>

            <v-col cols="12" class="my-0 mt-5 mb-3 py-0">
              <h3 class="text-white text-font-size">Información de contacto</h3>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Nombre</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.job_provider_request_contact_name && jobsInformation.contents_info.job_provider_request_contact_name.value != null) ? jobsInformation.contents_info.job_provider_request_contact_name.value : '---' }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Teléfono</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.phone && jobsInformation.contents_info.phone.value != null) ? jobsInformation.contents_info.phone.value : '---' }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Email</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.email && jobsInformation.contents_info.email.value != null) ? jobsInformation.contents_info.email.value : '---' }}</p>
            </v-col>

            <v-col cols="12" class="my-0 mt-5 mb-3 py-0">
              <h3 class="text-white text-font-size">Detalles de la oferta</h3>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Ubicación / dirección</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.job_provider_request_location && jobsInformation.contents_info.job_provider_request_location.value != null) ? jobsInformation.contents_info.job_provider_request_location.value : '---' }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Fecha de inicio</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.job_provider_request_start_date && jobsInformation.contents_info.job_provider_request_start_date.value != null) ? formatDate(jobsInformation.contents_info.job_provider_request_start_date.value) : '---' }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Duración del contrato</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.job_provider_request_duration && jobsInformation.contents_info.job_provider_request_duration.value != null) ? jobsInformation.contents_info.job_provider_request_duration.value : '---' }}</p>
            </v-col>

            <v-col cols="5" class="my-0 py-0">
              <h3 class="text-white text-title">Salario</h3>
            </v-col>
            <v-col cols="7" class="my-0 py-0">
              <p class="text-white fix-p text-description">{{ (jobsInformation.contents_info.job_provider_request_salary && jobsInformation.contents_info.job_provider_request_salary.value != null) ? '$'+jobsInformation.contents_info.job_provider_request_salary.value : '---' }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="movie-trailer">
          <!-- <v-img class="img-main" :src="(jobsInformation.contents_info.main_image_url && jobsInformation.contents_info.main_image_url != null) ? jobsInformation.contents_info.main_image_url.value : 'https://radio.uabc.mx/sites/default/files/imagenes/podcast/casting.jpg'"/> -->
          <v-carousel class="img-main" v-model="currentPage" :show-arrows="false" hide-delimiters>
            <v-carousel-item :src="item" v-for="(item, index) in additionalImages" :key="index"/>
          </v-carousel>
          <br>
          <v-pagination
            class="pagination table-pagination margin-pagination" 
            circle v-model="adjustedPage" 
            :length="additionalImages.length"
            total-visible="6" 
            @input="updatePage">
          </v-pagination>
          <v-btn 
            class="mb-5 mx-0 main_gradient_background text-white text-transform-none main-image-button bottom-button"
            x-large
            @click="applyForOffer(jobsInformation)"
            :loading="loadingBtn"
            style="text-align: center;"
          >
            Postularse
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom margin-description">
        <v-col cols="12">
          <h1 class="text-white">Descripción</h1>
          <br>
          <p v-html="jobsInformation.contents_info.main_description ? jobsInformation.contents_info.main_description.value : '---'" class="text-white text-justify ql-editor text-description"></p>
        </v-col>
        <v-col cols="12" v-if="jobsInformation.contents_info.job_file || jobsInformation.contents_info.job_url">
          <v-row class="text-white justify-center">
            <v-col cols="12" class="d-flex justify-center">
              <h2 class="parrafo">Para más información sobre este empleo:</h2>
            </v-col>
            <v-col v-if="jobsInformation.contents_info.job_file"
              cols="5" class="my-0 py-0 justify-center" style="text-align: center; !important;"
            >
              <p class="parrafo">Descargue el siguiente archivo:</p>
              <v-btn class="btn-gradient" large :href="(jobsInformation.contents_info.job_file) ? jobsInformation.contents_info.job_file.value : ''" target="/blank">
                archivo de solicitud
              </v-btn>
            </v-col>
            <v-col v-if="jobsInformation.contents_info.job_file && jobsInformation.contents_info.job_url" cols="2" class="my-0 py-0 d-flex justify-center">
              <h2 class="justify-center my-auto text-white">
                O
              </h2>
            </v-col>
            <v-col v-if="jobsInformation.contents_info.job_url" cols="5" class="my-0 py-0 justify-center" style="text-align: center; !important;">
              <p class="parrafo">Entre al siguiente enlace:</p>
              <v-btn v-if="jobsInformation.contents_info.job_url" class="btn-gradient" large :href="(jobsInformation.contents_info.job_url) ? jobsInformation.contents_info.job_url.value : '#'" target="/blank">
                Enlace del empleo
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Mira otras ofertas de empleo.
            </h1>
            <p class="parrafo">Busca una oferta de empleo para la cinematografia que se realiza aqui en Durango.</p>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-view">
        <v-col v-for="(noticia, index) in jobs.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
          <v-card
            class="text-white"
            outlined color="transparent"
          >
            <div class="mt-n11 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  :src="noticia.contents_info.main_image_url ? noticia.contents_info.main_image_url.value : ''"
                  class="img-middle img-fluid shadow border-radius-lg not-alter-image"
                />
              </div>
            </div>
            <h2 class="mt-7 mb-2 px-4 text-white notranslate">
              {{ noticia.contents_info.title ? noticia.contents_info.title.value : '-' }}
            </h2>
            <div class="text-broken">
              <p v-html="noticia.contents_info.main_description ? noticia.contents_info.main_description.value : ''" class="mb-7 px-4 text-justify"></p>
            </div>  
            <v-card-actions>
              <v-btn class="btn-gradient" large :href="`/jobsInformation/${noticia.id}`">
                Ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Api from '@/api/Api';

export default {
  props: {
  },
  data() {
      return {
        job_id: null,
        jobsInformation: [],
        jobs: [],
        currentPage: 0,
        additionalImages: [],

        loadingBtn: false
      };
  },
  created() {
    this.$emit('navbarMainPage',false);
    this.job_id = this.$route.params.job_id;
    this.getJobs();
    this.getJob();
  },
  computed: {
    adjustedPage: {
      get() {
        return this.currentPage + 1;
      },
      set(value) {
        this.currentPage = value - 1;
      }
    },
  },
  methods: {
    getJob(){
      Api.post(`section/get_specific_contents`,
      {
        section_id: this.job_id,
        contents: [
          'title',
          'main_image_url',
          'secondary_image_url',
          'additional_image_url',
          'job_file',
          'job_url',
          'job_provider_request_location',
          'job_provider_request_start_date',
          'job_provider_request_duration',
          'job_provider_request_salary',
          'job_provider_request_contact_name',
          'main_description',
          'email',
          'phone',
        ]
      })
      .then(response => {
        if(response.data != 'Sección desactivada') {
          this.jobsInformation = response.data;
          this.getAdditionalImages();
        } else {
          this.$router.back();
        }
      })
      .catch(error => this.errorHandling(error))
    },
    getJobs:function (page = 1){
      Api.get('section/get_section_by_section_type/1', {
        params: {
          page: page,
          query: this.search,
          paginate: this.itemsPerPage,
          contents: ['title','main_description','main_image_url'],
          pagination_required: 'Yes',
          category: this.temporalCategory,
          subCategory: this.temporalSubCategory
        }
      })
      .then(response => {
        this.jobs = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    updatePage(page) {
        this.adjustedPage = page;
    },
    getAdditionalImages(){
      const slug = "additional_image_url";
      Object.entries(this.jobsInformation.contents_info).forEach(value => {
        var additionalImage = {};
        additionalImage = value.find(element => element.slug === slug);
        if(additionalImage != null){
          this.additionalImages.push(additionalImage.value);
        }
      }) 
    },
    formatDate: function (raw_date) {
      let day_birth = '00';
      let month_birth = '00';
      let year_birth = '0000';
      
      year_birth = raw_date.substring(0, 4);
      month_birth = raw_date.substring(5, 7);
      day_birth = raw_date.substring(8, 10);
      
      let month_birth_text = '';

      let final_date = '';

      switch (month_birth) {
        case '01': month_birth_text = 'enero'; break;
        case '02': month_birth_text = 'febrero'; break;
        case '03': month_birth_text = 'marzo'; break;
        case '04': month_birth_text = 'abril'; break;
        case '05': month_birth_text = 'mayo'; break;
        case '06': month_birth_text = 'junio'; break;
        case '07': month_birth_text = 'julio'; break;
        case '08': month_birth_text = 'agosto'; break;
        case '09': month_birth_text = 'septiembre'; break;
        case '10': month_birth_text = 'octubre'; break;
        case '11': month_birth_text = 'noviembre'; break;
        case '12': month_birth_text = 'diciembre'; break;
        default: month_birth_text = 'Mes'; break;
      }
      
      final_date = day_birth+' de '+month_birth_text+' de '+year_birth;

      return final_date;
    },

    applyForOffer: function (offer) {
      this.loadingBtn = true;
      this.$swal({
        title: "¿Esta seguro?",
        text: "¿Está seguro que quiere postularse a esta oferta? Recuerde rellenar su información de experiencia profesional en su perfil.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Api.get('/auth/user_is_logged').then(response => {
            if (response.data === 'not_logged') {
              this.$swal('Acceso denegado', 'Para postularse, necesita iniciar sesión con un usuario', "error");
              this.$router.push({ 
                name: 'SignUpCover'
              })
            } else {
              Api.get('/auth/logged_user').then(loggedUser => {
                Api.post('vinculations', {
                  section_id: offer.id,
                  slug: (offer.section_type_id == 1) ? 'job_offer' : 'provider_request',
                  user_id: loggedUser.data.User.id,
                  status: 'Solicitud de entrevista'
                })
                .then(response => {
                  if (response.data.status == true) {
                    this.$swal('¡Todo listo!', response.data.message, "success");
                    this.navigateTo('/jobs');
                  } else {
                    this.$swal('', response.data.message, "error");
                  }
                })
                .catch(error => this.errorHandling(error))
                .finally(() => {
                  this.loadingBtn = false;
                });
              })
              .catch(error => {
                this.errorHandling(error);
                this.loadingBtn = false;
              })
            }
          })
          .catch(error => {
            this.errorHandling(error);
            this.loadingBtn = false;
          })
        } else {
          this.loadingBtn = false;
        }
      });
    }
  }
};
</script>
<style scoped>
.margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
}
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.main-image{
  min-height: 70vh;
}
.movie-description{
    margin-top: 30vh;
}
.movie-trailer{
    margin-top: 30vh;
}
.movie-trailer img{
    max-width: 50vw;
    max-height: 50vh;
}
.movie-title{
    font-size: 1.5em;
    margin-bottom: 2vh;
}
.information{
    display: inline-flex;
    margin-bottom: 1vh;
}
.width-text{
    width: 10vw;
}
.margin-main-image{
    margin-bottom: 10vh;
    margin-top: 10vh;
}
.margin-bottom{
    margin-bottom: 15vh;
}
.margin-main-image{
  margin-top: -35vh;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.card-padding{
  padding-right: 3% !important;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
.text-font-size{
  font-size: 1.5em;
}
.text-description{
  font-size: 1em;
  font-weight: 300;
}
.text-title{
  font-size: 1.2em;
  font-weight: 500;
}
.margin-description{
  margin-top: 15vh;
}
</style>