<template>
    <div>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <div
              class="page-header main-image min-vw-100"
              :style="[aboutUsSection.indexed_contents.secondary_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${aboutUsSection.indexed_contents.secondary_image_url.value})`,backgroundSize: 'cover', backgroundPosition: '50%'} : {background:'#12141B'}]"
            >
            </div>
          </v-col>
        </v-row>
        <v-row class="margin-text-main">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="text-white margin-text margin-view">
            <h1 class="text-white main-image-title">{{ aboutUsSection.indexed_contents.title ? aboutUsSection.indexed_contents.title.value : '-' }}</h1>
            <span v-html="aboutUsSection.indexed_contents.main_description ? aboutUsSection.indexed_contents.main_description.value : '-'" class="text-white incentive_body_span main-image-description parrafo text-justify ql-editor">
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="margin-view">
        <v-row class="margin-card">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex justify-content-center flex-column">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-img :src="aboutUsSection.indexed_contents.main_image_url ? aboutUsSection.indexed_contents.main_image_url.value : ''" alt="img-blog-entry" class="img-middle border-radius-lg not-alter-image" ></v-img>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="1" lg="1"></v-col>
              <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="text-white">
                <h1 class="mb-5">{{ aboutUsSection.indexed_contents.subtitle ? aboutUsSection.indexed_contents.subtitle.value : '-' }}</h1>
                <p class="parrafo text-justify" v-html="aboutUsSection.indexed_contents.body ? aboutUsSection.indexed_contents.body.value : '-'">
                </p>
                <v-card-actions class="mt-12">
                  <a class="text-white icon-move-right">
                    Ver más
                    <i class="fas fa-arrow-right text-xs ms-1"></i>
                  </a>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="margin-view margin-parrafo">
        <v-row justify="center">
          <v-col cols="12" xs="12" sm="12" md="5" lg="6">
            <v-card class="text-center text-white" outlined color="transparent">
              <h1 class="justify-center mb-5">
                Lo más reciente en producciones
              </h1>
              <p class="parrafo text-center">Durango tiene una gran historia conforme a películas concierne. Ha sido sede de una gran cantidad de peliculas de talla internacional, con actores reconocidos.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="margin-view margin-card-movie">
        <v-row>
          <v-col v-for="(movie, index) in movies.data" :key="index" cols="12" xs="12" sm="12" md="3" lg="3" class="padding-margin-card-movie">
            <a :href="`/movie/${movie.id}`">
                <div class="card border-radius-lg movie" :style="[movie.contents_info.main_image_url ? {backgroundImage:`linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%),url(${movie.contents_info.main_image_url.value})`} : {background:'#282c39'}]" >
                  <div class="content">
                      <h2 class="text-white title-movie notranslate">{{ movie.contents_info.title ? movie.contents_info.title.value : '-'}}</h2>
                      <span v-html="movie.contents_info.main_description ? movie.contents_info.main_description.value : '-'" class="text-white parrafo ql-editor">
                      </span>
                  </div>
                </div>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';

  export default {
    name: "Dashboard",
    props: {
      //
    },
    data() {
      return {
        aboutUsSection: [],
        movies: [],
        itemsPerPage: 8
      };
    },
    methods: {
      getAboutUsSection: function () {
        Api.get('section/1')
        .then(response => {
          this.aboutUsSection = response.data;
        })
        .catch(error => this.errorHandling(error))
        
      },
      getMovies:function (page = 1){
        Api.get('section/get_section_by_section_type/5', {
          params: {
            page: page,
            paginate: this.itemsPerPage,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
          }
        })
        .then(response => {
          this.movies = response.data;
        })
        .catch(error => this.errorHandling(error))
    },
    },
    created() {
      this.getAboutUsSection();
      this.getMovies();
      this.$emit('navbarMainPage',false);
    }
  };
  </script>
  <style scoped>
  .main-image-title{
    font-size: 2.5em;
    margin-bottom: 1vh;
  }
  .main-image-description{
    font-size: 1.2em;
  }
  .margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
  }
  *{
    font-family: "Roboto", sans-serif !important;
  }
  .fa, .fas {
    font-family: 'Font Awesome 5 Free' !important;
  }
  .margin-card{
    margin-bottom: 15vh;
  }
  .margin-parrafo{
    margin-bottom: 10vh;
  }
  .margin-text{
    margin-top: 50vh;
  }
  .movie{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    padding: 0vw 1vw;
    width : auto;
    height : 50vh;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-size: cover;
  }
  .content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
  }
  .content:active{
    display: block;
  }
  .parrafo{
    font-weight: 300 !important;
  }
  .margin-card-movie{
    margin-bottom: 20vh;
  }
  .title-movie{
    font-size: 1.3em;
  }
  .padding-margin-card-movie{
    padding-right: 3.7%;
    margin-bottom: 10vh;
  }
  .padding-margin-card-movie a{
    text-decoration: none;
  }
  .img-middle{
    height: 50vh;
    background-size: cover; 
    background-position: 50%;
  }
  .main-image{
    min-height: 70vh;
  }
  .margin-text-main{
    margin-top: -80vh;
    margin-bottom: 20vh;
  }
  @media (max-width: 600px) {
    .main-image-title{
      font-size: 2.5em;
    }
    .main-image-description{
      font-size: 1.1em;
    }
  }
  </style>
  