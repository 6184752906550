<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 py-10 ">
      <v-row class="justify-content-center border-radius-lg">
        <v-col cols="12" class="pa-0">
          <v-layout>
            <v-btn 
              class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
              @click="openAddSubcategoryModal"
            >
              Nueva subcategoría
            </v-btn>
            <v-btn 
              class="mb-5 mx-0 ml-5 cancel_gradient_background text-white text-transform-none" 
              to="/admin/categories_management"
            >
              Volver a "Categorías de secciones"
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                Subcategorías de "{{ actualCategory.name }}"
              </h5>
              <p class="text-body mb-0">
                Gestionar subcategorías
              </p>
            </v-col>
            <v-col cols="11" lg="3" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar aquí"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections notranslate">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Subcategoría</th>
                <th
                  v-if="actualCategory.section_type_id == 4"
                >
                  Imágen
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subcategory, index) in subcategories.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ subcategory.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ subcategory.name }}
                    </span>
                  </div>
                </td>

                <td v-if="actualCategory.section_type_id == 4" style="max-width: 20vw !important;">
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal"
                      :class="(subcategory.image_name != null) ? 'text-white' : 'text-secondary'"
                    >
                      {{ (subcategory.image_name != null) ? subcategory.image_name : 'sin imágen' }}
                    </span>
                  </div>
                </td>
                
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Editar nombre de subcategoría"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="openChangeSubcategoryNameModal(subcategory)"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            edit
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Editar imágen de la subcategoría"
                          v-if="actualCategory.section_type_id == 4"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="openAddImageModal(subcategory)"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            image
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Borrar subcategoría" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteSubcategory(subcategory.id)">
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ subcategories.from }} a {{ subcategories.to }} de {{ subcategories.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="subcategories.current_page"
                :length="subcategories.last_page"
                circle
                @input="getSubcategories"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--         
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            actualCategory: {{ actualCategory }}
            currentSubcategory: {{ currentSubcategory }}
            subcategories: {{ subcategories }}
            search: {{ search }}
          </pre>
        </v-col>
        -->
      </v-row>

      <!-- Modal for add new subcategory or change subcategory name -->
      <v-dialog v-model="showNewNameModal" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="400px" height="100%">
          <v-card-text>
            <v-row class="my-5">
              <v-col cols="12" class="d-flex">
                <label class="text-white new-section-title">{{ (subcategoryAction == 'edit') ? 'Cambiar nombre de subcategoría' : 'Añadir nueva subcategoría' }}</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showNewNameModal = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>

              <v-col cols="12" class="text-white"
                v-if="subcategoryAction == 'edit'"
              >
                <p class="my-1"><b>id:</b> &nbsp;&nbsp; {{ currentSubcategory.id }}</p>
                <p class="my-1"><b>Nombre actual:</b> &nbsp;&nbsp; {{ currentSubcategory.name }}</p>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="currentSubcategory.newName"
                  :label="(subcategoryAction == 'edit') ? 'Nuevo nombre' : 'Nombre de la nueva subcategoría'"
                  placeholder="Subcategoría"
                  color="text-white"
                  class="input-style input-white-text"
                  :class="(subcategoryAction == 'edit') ? 'mb-5' : ''"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="storeCategory"
              :loading="btnLoadingNewName"
              v-if="subcategoryAction == 'new'"
            >
              Guardar
            </v-btn>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="updateSubcategory"
              :loading="btnLoadingNewName"
              v-if="subcategoryAction == 'edit'"
            >
              Actualizar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Modal for add an image to a selected subcategory -->
      <v-dialog v-model="showAddImageModal" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="500px" height="100%">
          <v-card-text>
            <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
              class="form-control" 
            />
            <v-row class="my-5">
              <v-col cols="12" class="d-flex text-center">
                <label class="text-white new-section-title">Editar imágen de la subcategoría "{{ currentSubcategory.name }}"</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showAddImageModal = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>

              <v-col cols="12">
                <div class="border-radius-lg main-img-container text-center mx-auto">
                  <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                  <v-img :src="(currentSubcategory.image_url != null) ? currentSubcategory.image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                    class="img-view-size pa-2 border-radius-lg"
                  >
                    <v-btn 
                      icon
                      class="my-1 mx-0 text-white text-transform-none floating-button" 
                      @click="pickFile(null, 'main_image_url')"
                      title="Editar imágen adicional"
                      :loading="ImageBtnLoading"
                    >
                      <v-icon class="material-icons-round me-sm-1 secondary_gradient_background border-radius-lg" size="20">
                        edit
                      </v-icon>
                    </v-btn>
                  </v-img>
                </div>
              </v-col>
              <v-col cols="10" class="text-center">
                <span class="font-weight-normal"
                  :class="(currentSubcategory.image_name != null) ? 'text-white' : 'text-secondary'"
                >
                  {{ (currentSubcategory.image_name != null) ? currentSubcategory.image_name : 'sin imágen' }}
                </span>
              </v-col>
              <v-col cols="2" v-if="currentSubcategory.image_url">
                <v-layout class="text-right">
                  <v-btn icon color="primary" :href="currentSubcategory.image_url" target="_blank" title="Descargar imágen">
                    <v-icon outlined 
                      class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                      file_download
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </section>
</template>
  
  <script>
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  export default {
    name: "subcategories-management",
    components: {
      //
    },
    data() {
      return {
        actual_category_id: null,

        actualCategory: {},
          
        subcategories: {
          data: []
        },
        search: '',

        currentSubcategory: {
          id: null,
          name: null,
          image_name: null,
          image_url: null,
          newName: null,
          category_id: null,
        },


        subcategoryAction: null,

        btnLoadingNewName: false,
        showNewNameModal: false,


        showAddImageModal: false,
        ImageBtnLoading: false,

      };
    },
    watch: {
      search () {
        this.debounceSearchCategory();
      },
      showNewNameModal (value) {
        if (value == false) {
          this.currentSubcategory = {
            id: null,
            name: null,
            image_name: null,
            image_url: null,
            newName: null,
            category_id: this.$route.params.category_id
          }
        }
      },
      showAddImageModal (value) {
        if (value == false) {
          this.currentSubcategory = {
            id: null,
            name: null,
            image_name: null,
            image_url: null,
            newName: null,
            category_id: this.$route.params.category_id
          }
        }
      },
    },
    methods: {
      getCurrentCategory: function () {
        Api.get(`category/${this.actual_category_id}`)
        .then(response => {
          this.actualCategory = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getSubcategories: function (page = 1) {
        Api.get(`subcategory/${this.actual_category_id}?page=${page}&search=${this.search}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      openAddSubcategoryModal: function () {
        this.subcategoryAction = "new";
        this.currentSubcategory.newName = null;

        this.showNewNameModal = true;
      },
      storeCategory: function () {
        this.btnLoadingNewName = true;
        Api.post('subcategory', this.currentSubcategory)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.showNewNameModal = false;
          this.getSubcategories();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewName = false;
        })
      },
      openChangeSubcategoryNameModal: function (subcategory) {
        this.currentSubcategory.id = subcategory.id;
        this.currentSubcategory.name = subcategory.name;
        this.currentSubcategory.newName = subcategory.name;

        this.subcategoryAction = "edit";

        this.showNewNameModal = true;
      },
      updateSubcategory: function () {
        this.btnLoadingNewName = true;
        Api.patch(`subcategory/${this.currentSubcategory.id}`, this.currentSubcategory)
        .then(response => {
          this.$swal("", response.data.message, "success");
          this.showNewNameModal = false;
          this.getSubcategories();
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewName = false;
        })
      },

      openAddImageModal: function (subcategory) {
        this.currentSubcategory.id = subcategory.id;
        this.currentSubcategory.name = subcategory.name;
        this.currentSubcategory.newName = subcategory.name;
        this.currentSubcategory.image_name = subcategory.image_name;
        this.currentSubcategory.image_url = subcategory.image_url;

        this.showAddImageModal = true;
      },
      
      pickFile: function () {
        this.$refs.input_file.click();
      },

      getFile: function (e) {
        this.ImageBtnLoading = !this.ImageBtnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {
        
          let formData = new FormData();
          formData.append('file', file)
          formData.append('slug', 'subcategory_image')
          formData.append('path', 'sections/subcategories/')

          Api.post('files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.currentSubcategory.image_url = response.data.file_url;
            this.currentSubcategory.image_name = response.data.file_name;

            this.$swal(response.data.message, '', "success");
            this.updateSubcategory();
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.ImageBtnLoading = !this.ImageBtnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.ImageBtnLoading = !this.ImageBtnLoading;
        }
      },

      
      
      deleteSubcategory: function (itemId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "No se podrá revertir",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`subcategory/${itemId}`)
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getSubcategories();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      
    },
    created() {
      this.actual_category_id = this.$route.params.category_id;
      this.currentSubcategory.category_id = this.$route.params.category_id;

      this.getCurrentCategory();
      this.getSubcategories();
      this.debounceSearchCategory = debounce(() => {
        this.getSubcategories();
      }, 500);
      
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>