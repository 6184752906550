<template>
  <div>
    <template>
      <v-footer id="film_footer" style="background-color: transparent !important; ">
        <v-row class="margin-view">
          <v-col cols="12" xs="12" sm="12" md="4" lg="4">
            <v-row>
              <v-col cols="12" class="margin-logo">
                <img
                  src="@/assets/img/logo-filmmx.svg"
                  alt="Film_Dgo_logo"
                  height="60"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  v-for="item in icons"
                  :key="item.icon"
                  icon
                  width="31"
                  :ripple="false"
                  class="mx-3 no-default-hover"
                  link
                  href="#"
                  target="_blank"
                  style="color: rgba(0,0,0,0) !important"
                >
                  <v-icon class="icon-gradient">{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="8" class="d-flex justify-space-around margin-footer">
            <ul
              v-for="item in footer"
              :key="item.column_name"
              class="d-flex list-style-none"
            >
              <li>
                <p class="text-white">{{ item.column_name }}</p>
                <ul class="list-style-none pl-0">
                  <li v-for="link in item.column_content" :key="link.linkName">
                      <a :href="link.link"
                      class="
                        text-decoration-none 
                        text-body-2
                        ls-0
                        no-default-hover
                        list-style-none
                      "
                      style="text-decoration: none !important; color: #7B809A;"
                      target="_blank"
                      >
                        {{ link.linkName }}
                      </a>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <div class="copyright text-body-2 ls-0 text-copy" width="100%"  style="color: #7B809A;">
              Copyright © {{ new Date().getFullYear() }} Film Durango by <a href="https://virtua.mx/" class="text-white">Virtua Mx.</a>
            </div>
          </v-col>
        </v-row>
      </v-footer>
    </template>
  </div>
</template>
<script>
export default {
  name: "film-dgo-footer",
  props: {
    auth: Boolean,
    linkColor: String
  },
  data() {
    return {
      footer: [
        {
          column_name: "Company",
          column_content: [
            {
              linkName: "Locaciones",
              link: "/locations",
            },
            {
              linkName: "Filmografía",
              link: "/filmography",
            },
            {
              linkName: "Cine en Durango",
              link: "/about_us",
            },
            {
              linkName: "Empleos y capacitación",
              link: "/jobs",
            },
            {
              linkName: "Incentivos",
              link: "/incentives",
            },
            {
              linkName: "Proveedores & Crew",
              link: "/suppliers",
            },
            {
              linkName: "Noticias",
              link: "/news",
            },
            {
              linkName: "Permisos",
              link: "/permissions",
            },
            {
              linkName: "Nosotros",
              link: "/about-film-durango",
            }
          ]
        },
        {
          column_name: "Legal",
          column_content: [
            {
              linkName: "Terms",
              link: "#",
            },
            {
              linkName: "About us",
              link: "/about-film-durango",
            },
            {
              linkName: "Privacy",
              link: "#",
            }
          ],
        },
        {
          column_name: "Pages",
          column_content: [
            {
              linkName: "Login",
              link: "/login",
            },
            {
              linkName: "Register",
              link: "/register",
            },
            {
              linkName: "Contact",
              link: "#",
            }
          ],
        }
      ],
      icons: [
        {
          icon: "fab fa-facebook",
        },
        {
          icon: "fab fa-twitter",
        },
        {
          icon: "fab fa-instagram",
        },
        {
          icon: "fab fa-youtube",
        },
        /*
        {
          icon: "fab fa-pinterest",
        },
        {
          icon: "fab fa-github",
        },
        {
          icon: "fab fa-dribbble",
        },
        */
      ],
    };
  },
};
</script>
<style>
.icon-gradient{
  background-image: var(--social-network-gradient);
  -webkit-background-clip: text;
}
.v-footer{
  padding: 0px !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}
.margin-logo{
  margin-left: 2.5vw;
}
.margin-footer{
  margin-bottom: 50px;
}
.text-copy{
  font-weight: 200 !important;
}
</style>
