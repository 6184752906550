<template style="font-family: 'roboto';">
  <section class="full-height">
    <div class="container border-radius-lg blur blur-transparent px-7 full-height">
      <v-row v-if="user != null" class="justify-content-center mb-10 text-white">
        <v-col cols="12" class="d-flex mb-3">
          <v-row>
            <v-col cols="3">
              <v-avatar size="55" class="ma-0 my-2 rounded-avatar">
                <img :src="(user.Personal_information.profile_image_url != null) ? user.Personal_information.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
              </v-avatar>
            </v-col>
            <v-col cols="7" class="pt-4">
              <p class="my-1">{{ (user.Personal_information.name != null) ? user.Personal_information.complete_name : 'User' }}</p>
              <p class="my-1 text-caption p-0 d-none-mini">
                {{ user.Roles[0] }}
              </p>
            </v-col>
            <v-col cols="2" class="pt-4">
              <v-btn 
                icon
                class="ma-0 text-transform-none" 
                @click="showLoadImage = !showLoadImage"
                title="Editar imágen de perfil"
              >
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  image
                </v-icon>
              </v-btn>

              <v-btn 
                icon
                class="ma-0 text-transform-none" 
                @click="showChangePassword = !showChangePassword"
                title="Cambiar contraseña"
              >
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  lock
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="d-flex">
          <v-row>
            <v-col cols="10">
              <p class="my-1 font-weight-bold">Información de perfil</p>
            </v-col>
            <v-col cols="2">
              <v-btn 
                icon
                class="ma-0 text-transform-none" 
                title="Editar información de perfil"
                :to="'/admin/profile_overview/edit_information/'+user.User.id"
              >
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  manage_accounts
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-1"><b>Nombre completo:</b> &nbsp;&nbsp; {{ (user.Personal_information.name != null) ? user.Personal_information.complete_name : '---' }}</p>
              <p class="my-1"><b>Teléfono móvil:</b> &nbsp;&nbsp; {{ (user.Contact_information.phone != null) ? '('+user.Contact_information.phone_prefix+') '+user.Contact_information.phone : '---' }}</p>
              <p class="my-1"><b>Email:</b> &nbsp;&nbsp; {{ user.User.email }}</p>
              <p class="my-1"><b>Ubicación:</b> &nbsp;&nbsp; {{ (user.Contact_information.state != null && user.Contact_information.country != null) ? user.Contact_information.state+', '+user.Contact_information.country : '---' }}</p>
              <p v-if="user != null && (user.Roles[0] == 'Crew')" class="my-1"><b>Perfil de IMDB:</b> &nbsp;&nbsp; {{ (user.Personal_information.imdb_url != null) ? user.Personal_information.imdb_url : '---' }}</p>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>

      <hr v-if="user != null && (user.Roles[0] != 'Person' && user.Roles[0] != 'Crew')" class="horizontal mb-0 light line_gradient_background mx-n7"/>

      <v-row v-if="user != null && (user.Roles[0] != 'Person' && user.Roles[0] != 'Crew')" class="justify-content-center mb-10 text-white mt-5">
        <v-col cols="12" class="d-flex">
          <v-row>
            <v-col cols="10">
              <p class="my-1 font-weight-bold">Información de empresa</p>
            </v-col>
            <v-col cols="2">
              <v-btn 

                v-if="currentCompany == ''"

                icon
                class="ma-0 text-transform-none" 
                title="Add company information"
                :to="'/admin/profile_overview/edit_company_information/'+user.User.id"
              >
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  manage_accounts
                </v-icon>
              </v-btn>

              <v-btn 
                v-else
                icon
                class="ma-0 text-transform-none" 
                title="Edit company information"
                :to="'/admin/companies_management/edit/'+currentCompany.company_id"
              >
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  manage_accounts
                </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="currentCompany != ''" cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-1"><b>Nombre:</b> &nbsp;&nbsp; {{ (currentCompany.company.name) ? currentCompany.company.name : '---' }}</p>
              <p class="my-1"><b>Teléfono:</b> &nbsp;&nbsp; {{ (currentCompany.company.phone) ? '('+currentCompany.company.phone_prefix+') '+currentCompany.company.phone : '---'}}</p>
              <p class="my-1"><b>Email:</b> &nbsp;&nbsp; {{ (currentCompany.company.email) ? currentCompany.company.email : '---' }}</p>
              <p class="my-1"><b>Tipo de persona:</b> &nbsp;&nbsp; {{ (currentCompany.company.person_type) ? currentCompany.company.person_type : '---' }}</p>
              <p class="my-1"><b>Puesto en la empresa:</b> &nbsp;&nbsp; {{ (currentCompany.user_role) ? currentCompany.user_role : '---' }}</p>
            </v-col>
            <v-col v-else cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-1">Este usuario no está en ninguna empresa todavia.</p>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>

      <v-dialog v-if="user != null" v-model="showLoadImage" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="400px" height="100%">
          <v-card-text>
            <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
              class="form-control" 
            />
            <v-row class="my-5">
              <v-col cols="12" class="d-flex">
                <label class="text-white new-section-title">Editar imágen de perfil</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showLoadImage = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>
              <v-col cols="9" v-if="user.Personal_information.profile_image_url">
                <label class="text-white">{{ user.Personal_information.profile_image_name }}</label>
              </v-col>
              <v-col cols="3" v-if="user.Personal_information.profile_image_url">
                <v-layout class="text-right">
                  <v-btn icon color="primary" :href="user.Personal_information.profile_image_url" target="_blank" title="Descargar imágen">
                    <v-icon outlined 
                      class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                      file_download
                    </v-icon>
                  </v-btn>
                  <!--
                  <v-btn icon color="red" @click="deleteFile" title="Delete image">
                    <v-icon outlined 
                      class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                      delete
                    </v-icon>
                  </v-btn>
                  -->
                </v-layout>
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <label class="text-white text-center">Subir una imágen desde su dispositivo (Tamaño máximo de 1.95 MB)</label>
              </v-col>
            </v-row>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="pickFile"
              :loading="btnLoading"
            >
              Subir imágen
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="user != null" v-model="showChangePassword" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="400px" height="100%">
          <v-card-text>
            <v-row class="my-5">
              <v-col cols="12" class="d-flex">
                <label class="text-white new-section-title">Cambiar contraseña</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showChangePassword = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="changePassword.current_password"
                  url=""
                  label="Contraseña actual"
                  color="text-white"
                  class="input-style mb-5 input-white-text"
                  hide-details
                  type="password"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="changePassword.new_password"
                  url=""
                  label="Nueva contraseña"
                  color="text-white"
                  class="input-style mb-5 input-white-text"
                  hide-details
                  type="password"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="changePassword.confirm_password"
                  url=""
                  label="Confirmar nueva contraseña"
                  color="text-white"
                  class="input-style mb-5 input-white-text"
                  hide-details
                  type="password"
                />
              </v-col>
            </v-row>
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="changeUserPassword"
              :loading="btnLoading"
            >
              Cambiar contraseña
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--         
      <pre style="height: 300px; overflow-y: scroll;" class="text-white">
        user: {{ user }}
        currentCompany: {{ currentCompany }}
      </pre>   
      -->
    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';

  export default {
    name: "user-personal-information",
    components: {
      //
    },
    data() {
      return {
        user: null,
        currentCompany: {
          company: ''
        },
        btnLoading: false,

        showLoadImage: false,
        showChangePassword: false,

        changePassword: {
          current_password: null,
          new_password: null,
          confirm_password: null
        }
      };
    },
    watch: {
      user(value) {
        if (value != null) {
          this.getCompanyInfo(value.User.id);
        }
      },
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.user = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getCompanyInfo: function (userId) {
        Api.get(`company/get_company_by_user/${userId}`)
        .then(response => {
          this.currentCompany = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      pickFile: function () {
        this.$refs.input_file.click();
      },
      getFile: function (e) {
        this.btnLoading = !this.btnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {

          let formData = new FormData();
          formData.append('file', file)

          Api.post(`personal_information/store_profile_image/${this.user.User.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.$swal(response.data.message, '', "success");
            location.reload();
            //this.getUserInfo();
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoading = !this.btnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.btnLoading = !this.btnLoading;
        }
      },

      deleteFile: function () {
        this.$swal({
          title: '¿Borrar imágen de perfil?',
          text: "",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`personal_information/delete_profile_image/${this.user.Personal_information.profile_image_name}`)
            .then(() => {
              location.reload();
              //this.getUserInfo();
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },

      changeUserPassword: function () {
        Api.post(`auth/change_password`, this.changePassword)
        .then(response => {
          if (response.data.status == true) {
            this.$swal(response.data.message, '', "success");
            location.reload();
          } else {
            this.$swal(response.data.message, '', "error");
          }
        })
        .catch(error => this.errorHandling(error))
      }

    },
    mounted() {
      this.getUserInfo();
    }
  };
</script>

<style scoped>
  .rounded-avatar {
    border-radius: 5px;
  }

  .secondary-text {
    font-size: 0.90rem !important;
  }
</style>