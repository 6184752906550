<template>
    <div>
        <v-container fluid class="pa-0 margin-image">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <div
                    class="page-header main-image min-vw-100"
                    :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/MVT_2434.jpg')}) !important; background-size: cover; background-position: 50%;`"
                >
                </div>
                </v-col>
            </v-row>
            <v-row class="margin-text-main">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="text-white margin-text margin-view">
                <h1 class="text-white main-image-title">{{ aboutFilmDurango.indexed_contents.title ? aboutFilmDurango.indexed_contents.title.value : '-' }}</h1>
                <span v-html="aboutFilmDurango.indexed_contents.main_description ? aboutFilmDurango.indexed_contents.main_description.value : ''" class="text-white main-image-description parrafo text-justify ql-editor">
                </span>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="margin-image margin-view">
            <v-row class="">
                <v-col v-for="(person, index) in directory.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
                <v-card
                    class="text-white"
                    outlined color="transparent"
                >
                    <div class="mt-n11 card-header position-relative z-index-2">
                    <div class="d-block blur-shadow-image">
                        <v-img
                        :src="person.contents_info.main_image_url ? person.contents_info.main_image_url.value : ''"
                        class="img-middle img-fluid shadow border-radius-lg not-alter-image"
                        />
                    </div>
                    </div>
                    <h2 class="mt-7 mb-2 px-4 text-white">
                        {{ person.contents_info.directory_job_role ? person.contents_info.directory_job_role.value : '' }}
                    </h2>
                    <h3 class="mt-7 mb-2 px-4 text-white">
                        {{ person.contents_info.title ? person.contents_info.title.value : '-' }}
                    </h3>
                    <p v-html="person.contents_info.main_description ? person.contents_info.main_description.value : ''" class="mb-7 px-4 text-justify"></p>
                </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row class="margin-view margin-image">
                <v-col cols="12" lg="12" class="d-flex justify-end">
                    <v-pagination
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination table-pagination"
                        :length="directory.last_page"
                        v-model="directory.current_page"
                        total-visible="6"
                        circle
                        @input="getDirectory"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Api from '@/api/Api';
export default {
  name: "Dashboard",
  props: {
    //
  },
  data() {
    return {
      aboutFilmDurango: [],
      directory: [],
      itemsPerPage: 6
    };
  },
  methods: {
    getAboutFilmDurango: function () {
      Api.get('section/2')
      .then(response => {
        this.aboutFilmDurango = response.data;
      })
      .catch(error => this.errorHandling(error))
      
    },
    getDirectory:function (page = 1){
        Api.get('section/get_section_by_section_type/12', {
          params: {
            page: page,
            paginate: this.itemsPerPage,
            contents: ['title','directory_job_role','main_image_url','main_description'],
            pagination_required: 'Yes',
          }
        })
        .then(response => {
          this.directory = response.data;
        })
        .catch(error => this.errorHandling(error))
    },
  },
  created() {
    this.getAboutFilmDurango();
    this.getDirectory();
    this.$emit('navbarMainPage',false);
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 1;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 1;
      }

      if (this.$vuetify.breakpoint.md) {
        return 1;
      }

      return 1;
    },
  },
};
</script>
<style scoped>
.main-image-title{
  font-size: 2.5em;
  margin-bottom: 1vh;
}
.main-image-description{
  font-size: 1.2em;
  font-weight: 300 !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
}
.main-image{
  min-height: 70vh;
}
.margin-text-main{
  margin-top: -20vh;
}
.margin-image{
    margin-bottom: 15vh;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
@media (max-width: 600px) {
  .main-image-title{
    font-size: 2.5em;
  }
  .main-image-description{
    font-size: 1.1em;
  }
}
</style>
  