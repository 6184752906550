<template>
    <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="{ background:'#12141B' }"
          >
          </div>
          </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom margin-main-image">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="text-white supplier-description d-flex flex-column">
          <h1 class="text-white supplier-title notranslate">{{ productService.contents_info.title ? productService.contents_info.title.value : '' }}</h1>
          <div class="">
            <h2 class="text-white width-text">Descripción</h2>
            <br>
            <p class="text-white text-justify" v-html="productService.contents_info.main_description ? productService.contents_info.main_description.value : '-'"></p>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="supplier-trailer">
          <v-carousel v-model="currentPage" :show-arrows="false" hide-delimiters>
            <v-carousel-item
              :src="item" 
              v-for="(item, index) in additionalImages"
              :key="index"
            ></v-carousel-item>
          </v-carousel>
          <v-pagination
                class="pagination table-pagination margin-pagination" 
                circle v-model="adjustedPage" 
                :length="additionalImages.length"
                total-visible="6" 
                @input="updatePage">
          </v-pagination>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Mira otros productos y servicios
            </h1>
            <p class="parrafo">Busca la variedad de productos y servicios que tenemos en Durango.</p>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-view">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" v-for="(productService, index) in productsServices" :key="index">
          <v-card
            class="text-white"
            outlined color="transparent"
          >
            <div class="mt-n11 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  :src="productService.contents_info.main_image_url ? productService.contents_info.main_image_url.value : ''"
                  class="img-middle img-fluid shadow border-radius-lg"
                />
              </div>
            </div>
            <h2 class="mt-7 mb-2 px-4 text-white notranslate">
              {{ productService.contents_info.title ? productService.contents_info.title.value : '' }}
            </h2>
            <div class="text-broken">
              <p class="mb-7 px-5 text-justify" v-html="productService.contents_info.main_description ? productService.contents_info.main_description.value : ''">
              </p>
            </div>
            <v-card-actions>
              <v-btn class="btn-gradient" large :href="`/productService/${productService.id}/${productService.user_id}`">
                Ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Api from '@/api/Api';
export default{
    props: {
    },
    data() {
        return {
            currentPage: 0,
            id: this.$route.params.id,
            user_id: this.$route.params.user_id,
            productService: [],
            additionalImages: [],
            productsServices: []
        };
    },
    created() {
      this.$emit('navbarMainPage',false);
      this.getProductsServices();
      this.getProducts();
    },
    methods: {
        updatePage(page) {
            this.adjustedPage = page;
        },
        getProductsServices:function(){
          Api.post(`section/get_specific_contents`,
          {
            section_id: this.id,
            contents: [
              'title',
              'main_image_url',
              'main_description',
              'additional_image_url',
              'new_writer',
              'new_date',
            ]
          })
          .then(response => {
            if(response.data != 'Sección desactivada') {
              this.productService = response.data;
              this.getAdditionalImages();
            } else {
              this.$router.back();
            }
          })
          .catch(error => this.errorHandling(error))
        },
        getAdditionalImages(){
          const slug = "additional_image_url";
          Object.entries(this.productService.contents_info).forEach(value => {
            var additionalImage = {};
            additionalImage = value.find(element => element.slug === slug);
            if(additionalImage != null){
              this.additionalImages.push(additionalImage.value);
            }
          });
          const mainImageSlug = "main_image_url" 
          Object.entries(this.productService.contents_info).forEach(value => {
            var mainImage = {};
            mainImage = value.find(element => element.slug === mainImageSlug);
            if(mainImage != null){
              this.additionalImages.push(mainImage.value);
            }
          });
        },
        getProducts:function (page = 1){
          Api.get('section/get_section_by_section_type/6', {
          params: {
            page: page,
            paginate: 2,
            user_id: this.user_id,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
          }
          })
          .then(response => {
            this.productsServices = response.data;
            console.log(this.productsServices);
            this.getServices();
          })
          .catch(error => this.errorHandling(error))
        },
        getServices:function (page = 1){
          Api.get('section/get_section_by_section_type/7', {
          params: {
            page: page,
            paginate: 1,
            user_id: this.user_id,
            contents: ['title','main_description','main_image_url'],
            pagination_required: 'Yes',
          }
          })
          .then(response => {
            var services = [];
            services = response.data;
            this.productsServices = this.productsServices.data.concat(services.data);
          })
          .catch(error => this.errorHandling(error))
        },
    },
    computed: {
        adjustedPage: {
            get() {
            return this.currentPage + 1;
            },
            set(value) {
            this.currentPage = value - 1;
            }
        },
    }
}
</script>
<style scoped>
.margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.main-image{
  min-height: 70vh;
}
.supplier-description{
    margin-top: 30vh;
}
.supplier-trailer{
    margin-top: 30vh;
}
.supplier-title{
    margin-bottom: 2vh;
}
.information{
    display: inline-flex;
    margin-bottom: 1vh;
    margin-left: -2.1vh;
}
.width-text{
    width: 10vw;
}
.margin-main-image{
    margin-bottom: 10vh;
}
.margin-bottom{
    margin-bottom: 10vh;
}
.margin-main-image{
  margin-top: -35vh;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-pagination{
    margin-top: 3vh;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
</style>