<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <v-carousel class="main-image" height="100vh" cycle hide-delimiters interval="7000">
            <v-carousel-item
             v-for="(item, index) in getRandomImage" :key="index" fade
            >
            <v-img height="100vh" :src="item.image" gradient="0deg, #12141B 0%, rgba(0, 20, 27, 0) 25%"/>
            <div class="margin-view">
              <h1 class="text-white main-image-title text-uppercase">{{ item.title }}</h1>
              <!-- <p class="text-white main-image-description lead">The time is now</p> -->
            </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view">
      <v-row justify="center" class="margin-secciones">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <h1 class="text-white text-center mb-5">
            ¿Por qué Durango?
          </h1>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4">
          <v-card class="card-shadow border-radius-xl py-4 card-background-main main-card" height="35vh" href="/incentives">
            <div class="text-center">
              <h1 class="card-text-color card-text-title">
                  Incentivos
              </h1>
              <p class="mb-0 card-text-color card-text-description text-justify">
                Conoce qué incentivo se adapta mejor a tus necesidades y filma en nuestro mágico Estado.
              </p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4">
          <v-card class="card-shadow border-radius-xl py-4 mb-4 card-background-main d-flex align-center justify-center card-middle" height="47%" href="/suppliers">
            <div class="text-center">
              <h1 class="card-text-color card-text-title">
                  Proveedores
              </h1>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl py-4 mt-4 card-background-main d-flex align-center justify-center card-middle" height="46%" href="/jobs">
            <div class="text-center">
              <h1 class="card-text-color card-text-title">
                  Bolsa de trabajo
              </h1>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4">
          <v-card class="card-shadow border-radius-xl py-4 card-background-main main-card" height="35vh" href="/locations">
            <div class="text-center">
              <h1 class="card-text-color card-text-title">
                  Locaciones
              </h1>
              <p class="mb-0 card-text-color card-text-description text-justify">
                Descubre los diferentes tipos de locaciones con las que contamos, ¡Todo en un mismo lugar!
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view margin-btn-register">
      <v-row justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6" class="mb-5 mt-2">
          <v-card class="text-center text-white" outlined color="transparent">
            <v-btn class="btn-gradient" large href="/register">
              Regístrate
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view">
        <v-row class="margin-card">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex justify-content-center flex-column">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <!-- <v-img src="@/assets/img/LaJoya.jpg" alt="img-blog-entry" class="img-middle border-radius-lg"></v-img> -->
                <v-img 
                v-if="mainPageSection.indexed_contents.secondary_image_url && mainPageSection.indexed_contents.secondary_image_url.value != null"
                :src="mainPageSection.indexed_contents.secondary_image_url.value" alt="img-blog-entry" class="img-middle border-radius-lg"/>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="1" lg="1"></v-col>
              <v-col cols="12" xs="12" sm="12" md="5" lg="5" class="text-white">
                <h1 class="mb-5 ql-align-center">{{ (mainPageSection.indexed_contents.title && mainPageSection.indexed_contents.title.value != null) ? mainPageSection.indexed_contents.title.value : 'Durango Tierra del cine' }}</h1>
                <span class="ql-editor text-white" v-html="(mainPageSection.indexed_contents.main_description && mainPageSection.indexed_contents.main_description.value != null) ? mainPageSection.indexed_contents.main_description.value : '<p>¡Te damos la bienvenida a Durango, la tierra del cine en México! Filmar con nosotros es sencillo ya que contamos con incentivos, locaciones, crew y solo nos faltas ¡TU! Durango, muestra escenarios variados, calidad en sus servicios y siempre la mejor disposición para adaptar las locaciones a la necesidad de las producciones.</p>'"></span>
                <!-- <p class="text-justify">
                  ¡Te damos la bienvenida a Durango, la tierra
                  del cine en México! Filmar con nosotros es sencillo ya que contamos con
                  incentivos, locaciones, crew y solo nos faltas ¡TU!
                  Durango, muestra escenarios variados, calidad en sus servicios y siempre la
                  mejor disposición para adaptar las locaciones a la necesidad de las
                  producciones.
                </p> -->
                <v-card-actions class="mt-5">
                  <a class="text-white icon-move-right">
                    Ver más
                    <i class="fas fa-arrow-right text-xs ms-1"></i>
                  </a>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    </v-container>
    <v-container class="margin-view">
      <v-row class="margin-card" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-white" outlined color="transparent">
            <h1 class="mb-5 ql-align-center">
              {{ (mainPageSection.indexed_contents.subtitle && mainPageSection.indexed_contents.subtitle.value != null) ? mainPageSection.indexed_contents.subtitle.value : 'Encuentra todo en Durango' }}
            </h1>
            <span class="ql-editor text-white" v-html="(mainPageSection.indexed_contents.body && mainPageSection.indexed_contents.body.value != null) ? mainPageSection.indexed_contents.body.value : 'El Centro Histórico con sus edificios y monumentos, que van desde el neoclásico y barroco, hasta el neogótico. Podrás encontrar desde un puente colgante en medio de la sierra de mapimi completamente desértica hasta diferentes tipos de bosques en nuestra Sierra Madre Occidental, contando así, con casi todos los ambientes y ecosistemas que buscas para tu filmación.'"></span>
            <!-- <p class="text-center">El Centro Histórico con sus edificios y monumentos, que van desde el
              neoclásico y barroco, hasta el neogótico. Podrás encontrar desde un puente
              colgante en medio de la sierra de mapimi completamente desértica hasta
              diferentes tipos de bosques en nuestra Sierra Madre Occidental, contando así,
              con casi todos los ambientes y ecosistemas que buscas para tu filmación.</p> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="margin-view">
      <v-row class="margin-card margin-ver-mas">
        <template v-for="(location, index) in locations.data">
          <v-col v-if="index < 3" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
            <v-card
              class="text-white"
              outlined color="transparent"
            >
              <div class="mt-n11 card-header position-relative z-index-2">
                <div class="d-block blur-shadow-image">
                  <template v-for="(image, imageIndex) in location.image">
                    <v-col v-if="image.slug == 'main_image_url'" :key="imageIndex">
                      <v-img
                        :src="image.value"
                        class="img-middle img-fluid shadow border-radius-lg"
                      />
                    </v-col>
                  </template>
                </div>
              </div>
              <h2 class="mt-7 mb-2 px-4 text-white ql-align-center notranslate">
                {{ (location.other_contents.title && location.other_contents.title.value != null) ? location.other_contents.title.value : '' }}
              </h2>
              <div class="text-broken">
                <p v-html="(location.other_contents.main_description && location.other_contents.main_description.value != null) ? location.other_contents.main_description.value : ''" class="mb-7 px-4 ql-editor text-white"></p>
              </div>
              <v-card-actions>
                <v-btn class="btn-gradient" large href="/locations">
                  Ver más
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Api from '@/api/Api';

export default {
  name: "Dashboard",
  components: {
  },
  props: {
    linkColor: String,
  },
  data() {
    return {
      mainPageSection: {},
      imagesSlider: [],
      phrasesSlider: [],

      images: [
        {
          name: "image-1",
          url: require("@/assets/img/product-1-min.jpeg")
        },
        {
          name: "image-2",
          url: require("@/assets/img/product-2-min.jpeg")
        },
        {
          name: "image-3",
          url: require("@/assets/img/product-3-min.jpeg")
        },
        {
          name: "image-4",
          url: require("@/assets/img/office-dark.jpg")
        },
        {
          name: "image-4",
          url: require("@/assets/img/meeting.jpg")
        },
      ],
      carouselItems: [ 
        {
            id: 1,
            title: 'Trabajamos hombro con hombro en tu producción',
            description: '',
            image: require("@/assets/img/cabañatinajas.jpg")
        },
        {
            id: 2,
            title: 'Somos tu One Stop Shop para tu producción',
            description: '',
            image: require("@/assets/img/MVT_3934.jpg")
        },
        {
            id: 3,
            title: 'Durango es el estado cinematográfico más amigable en México',
            description: '',
            image: require("@/assets/img/IMG_5612.jpg")
        },
        {
            id: 4,
            title: 'Nos especializamos en apoyar a tu producción',
            description: '',
            image: require("@/assets/img/MVT_2434.jpg")
        },
        {
            id: 5,
            title: 'Tenemos una gran variedad de locaciones para tu proyecto',
            description: '',
            image: require("@/assets/img/LaPerlaRecortada.jpg")
        },
        {
            id: 6,
            title: 'Entendemos la industria cinematográfica en sus diferentes etapas',
            description: '',
            image: require("@/assets/img/rocas.jpg")
        }
        
      ],

      locations: []
    };
  },
  created() {
    this.$emit('navbarMainPage',false);
    this.getImagesAll();
    this.getAboutUsSection();
    /* window.addEventListener('scroll', this.handleScroll); */
  },
  unmounted () {
    /* window.removeEventListener('scroll', this.handleScroll); */
  },
  methods: {
    /* handleScroll (event) {
      this.$emit('navbarMainPage',false);
    } */
    getImagesAll(){
      Api.get('/section/4/get_section_images_all').then( response => {
        this.locations = response.data;
      }).catch(error => this.errorHandling(error))
    },

    getAboutUsSection: function () {
      Api.get('section/1000')
      .then(response => {
        this.mainPageSection = response.data;
        this.getArrayableContents();
      })
      .catch(error => this.errorHandling(error))
    },

    getArrayableContents(){
      this.imagesSlider = [];
      this.phrasesSlider = [];
      this.carouselItems = [];

      let arrayIndexedContents = Object.values(this.mainPageSection.indexed_contents);

      arrayIndexedContents.forEach(element => {
        if (element.slug == 'additional_image_url') {
          this.imagesSlider.push(element.value);
        } else if (element.slug == 'main_page_phrase') {
          this.phrasesSlider.push(element.value);
        }
      });

      this.imagesSlider.forEach((element, index) => {
        let imageObject = {
          id: index,
          title: this.phrasesSlider[index],
          image: this.imagesSlider[index]
        };

        this.carouselItems.push(imageObject);
      });
    },
    
  },
  computed: {
    getRandomImage(){
        var currentIndex = this.carouselItems.length;
        var temporaryValue;
        var randomIndex;
        var myRandomizedList;

        myRandomizedList = this.carouselItems.slice(0)

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = myRandomizedList[currentIndex];
            myRandomizedList[currentIndex] = myRandomizedList[randomIndex];
            myRandomizedList[randomIndex] = temporaryValue;
        }
        return myRandomizedList;
    }
  }
};
</script>
<style scoped>
.container {
  padding: 0 !important;
}
.main-image-title{
  position: absolute;
  top: 78vh;
  font-size: 2.5em;
}
.main-image-description{
  position: absolute;
  top: 88vh;
  font-size: 1.3rem;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
}
.margin-card{
  margin-top: 10vh;
  margin-bottom: 15vh;
}
.main-card{
  word-wrap: break-word;
  padding: 30px 20px 0px 20px !important;
  display: flex;
  align-items: center;
}
.main-card:hover{
  border-radius: 6px !important;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #272A34 inset !important;
}
.card-middle:hover{
  border-radius: 6px !important;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #272A34 inset !important;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
p{
  font-weight: 300 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-card-last {
  margin-bottom: 5vh;
}
*{
  font-family: "Roboto", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.card-text-title {
  font-size: 1.6rem;
  font-weight: 500 !important;
  margin-bottom: 1vh;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.card-padding{
  padding-right: 3% !important;
}
.main-image{
  min-height: 100vh !important;
}
.img-middle{
  height: 35vh;
  background-size: cover; 
  background-position: 50%;
}
.main-image{
  margin-bottom: 15vh;
}
.margin-secciones{
  margin-bottom: 2vh;
}
.margin-btn-register{
  margin-top: 5vh;
}
.margin-ver-mas{
  margin-top: -5vh;
}
@media (max-width: 599px) {
  .margin-view{
    margin-left: auto;
    margin-right: auto;
  }
  .main-image-title{
    position: absolute;
    top: 72vh;
    font-size: 2rem;
  }
  .main-image-description{
    position: absolute;
    top: 77vh;
    font-size: 1.3rem;
  }
}
</style>
