<template data-app>
  <div>
    <v-row class="align-center d-flex main-card mb-10">
      <v-col lg="7" md="7" class="mx-auto">
        <v-card class="border-radius-xl py-1 card-centered card-background blur blur-transparent" width="800px">
          <v-toolbar flat color="transparent" dark>
            <v-toolbar-title color="white" class="text-title-card">Registrate</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="4">
                <label class="text-white">Email</label>    
                <v-text-field
                  v-model="userData.email"
                  example="ejemplo@film.mx"
                  color="#e91e63"
                  outlined
                  dense
                  hide-details
                  class="font-size-input input-style mb-5 style_background"
                ></v-text-field>
              </v-col>
  
              <v-col cols="12" lg="4">
                <label class="text-white">Contraseña</label>
                <v-text-field
                  v-model="userData.password"
                  color="#e91e63"
                  outlined
                  dense
                  hide-details
                  type="password"
                  class="font-size-input input-style mb-5 style_background"
                ></v-text-field>
              </v-col>
  
              <v-col cols="12" lg="4">
                <label class="text-white">Confirmar contraseña</label>
                <v-text-field
                  v-model="userData.password_confirmation"
                  color="#e91e63"
                  outlined
                  dense
                  hide-details
                  type="password"
                  class="font-size-input input-style mb-5 style_background"
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" lg="4">
                <label class="text-white">País</label>
                <v-autocomplete
                  v-model="userData.country"
                  :items="countries"
                  item-text="country"
                  item-value="country"
                  color="#e91e63"
                  outlined
                  dense
                  hide-details
                  class="font-size-input input-style mb-5 style_background"
                ></v-autocomplete>
              </v-col>
  
              <v-col cols="12" lg="4">
                <label class="text-white">Estado</label>
                <v-autocomplete
                  v-model="userData.state"
                  :items="states"
                  color="#e91e63"
                  outlined
                  dense
                  hide-details
                  class="font-size-input input-style mb-5 style_background"
                ></v-autocomplete>
              </v-col>
  
              <v-col cols="12" lg="4">
                <label class="text-white">Tipo de cuenta</label>
                <v-select
                  v-model="userData.userType"
                  class="font-size-input input-style mb-5 style_background"
                  hide-details
                  color="#e91e63"
                  outlined
                  dense
                  :items="roles"
                  item-value="name"
                  item-text="name_es"
                />
              </v-col>
  
              <v-col>
                <label class="text-white">Código de país</label>
                <vue-country-code 
                  class="input-style style_background country-select-code"
                  v-model="userData.phone_prefix"
                  :disabledFetchingCountry="true"
                  :enabledCountryCode="true"
                  :preferredCountries="preferredCountries"
                  @onSelect="selectCountryCode"
                />
              </v-col>
  
              <v-col cols="12" lg="4">
                <label class="text-white">Teléfono</label>
                <v-text-field
                  v-model="userData.phone"
                  color="#e91e63"
                  outlined
                  mask="###.###.###-##"
                  dense
                  max="10"
                  hide-details
                  class="font-size-input input-style mb-5 style_background"
                ></v-text-field>
              </v-col>
  
              <v-col cols="12" lg="4">
                <div>
                  <v-checkbox 
                    color="white"
                    v-model="userData.phone_whatsapp"
                    class="wa-checkbox-container"
                  >
                    <template v-slot:label>
                      <span style="color: white; font-weight: bold;">¿Whatsapp?</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              
              <v-col cols="12" lg="4" v-if="userData.userType == 'Provider'">
                <label class="text-white">Registro en el padrón de proveedores</label>
                <v-text-field
                    v-model="file.name"
                    @click="pickFile"
                    color="#e91e63"
                    readonly
                    outlined
                    dense
                    hide-details
                    class="font-size-input input-style mb-5 style_background"
                ></v-text-field>
                <input accept="application/pdf" @change="getFile" style="display: none;" type="file" ref="file" class="form-control">
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <p class="text-centered text-white mt-6">
              ¿Ya tienes una cuenta?
              <a
                @click="navigateTo('/login')"
                class="text-decoration-none font-weight-bolder link-format"
                >Iniciar sesión</a
              >
            </p>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                w-20
                text-white
                cancel_gradient_background
                margin_bottom
                ma-2
              "   
              small
              href="/login"
              style="margin-right: 5%;"
            >
              Cancelar
            </v-btn>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                w-20
                text-white
                gradient_background
                margin_bottom
                ma-2
              "
              color="#F0F1F1"
              small
              :loading="loadingButton"
              @click="registerUser()"
  
            >
              registrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import Csrf from '@/api/Csrf';
import Api from '@/api/Api';
import axios from 'axios';
import countries from '../../../util/countries';

import Vue from "vue";
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

export default {
  name: "sign-up-basic",
  data() {
    return {
      userData: {
        email: null,
        password: null,
        password_confirmation: null,
        phone_prefix: null,
        phone: null,
        phone_whatsapp: false,
        country: null,
        state: null,
        userType: 'Person',
        files: []
      },
      file: {
        name: null,
        document: null
      },
      loadingButton: false,
      countries: countries,
      errors: null,
      preferredCountries: [
        'mx',
        'us',
        'ca'
      ],
      roles: [],
    };
  },
  computed: {
    states() {
      if(!this.userData.country) return [];
      return this.countries.find(item => item.country == this.userData.country).states;
    }
  },
  methods: {
    async loadMoreItems({ page }) {
      try {
        this.loading = true;
        const response = await axios.get(`your-api-endpoint?page=${page}&search=${this.searchInput}`);
        this.items = [...this.items, ...response.data.items];
        this.serverItemsLength = response.data.totalItems;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async searchItems() {
      this.pagination.page = 1;
      await this.loadMoreItems({ page: 1 });
    },
    resetPagination() {
      this.pagination.page = 1;
      this.items = [];
      this.totalItems = 0;
      this.serverItemsLength = 0;
    },
    selectCountryCode: function ({ name, iso2, dialCode }) {
      this.userData.phone_prefix = name + iso2;
      this.userData.phone_prefix = dialCode;
    },
    selectCountry: function ({ name, iso2, dialCode }) {
      this.userData.country = dialCode + iso2;
      this.userData.country = name;
    },
    registerUser: function () {
      this.loadingButton = true;
      
      let formData = new FormData();
      formData.append('fileName', this.file.name);
      formData.append('file', this.file.document);
      formData.append('email', this.userData.email)
      formData.append('password', this.userData.password)
      formData.append('password_confirmation', this.userData.password_confirmation)
      formData.append('phone_prefix', this.userData.phone_prefix)
      formData.append('phone', this.userData.phone)
      formData.append('phone_whatsapp', this.userData.phone_whatsapp)
      formData.append('country', this.userData.country)
      formData.append('state', this.userData.state)
      formData.append('userType', this.userData.userType)
      

      Api.post('auth/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        this.$alertify.success(response.data.message_text)
        this.$swal(response.data.message_text, 'Su nuevo usuario se registró correctamente. Le hemos enviado un correo para que verifique el email que nos proporcionó.', "success");
        setTimeout(() => {
          this.navigateTo('/');
        }, 1500);
      })
      .catch(errors => this.errorHandling(errors))
      .finally(() => this.loadingButton = false);
    },
    getPublicRoles: function() {
      Api.get('roles/public')
      .then(response => {
        this.roles = response.data;

        this.roles.forEach(element => {
          element.name_es = this.translateRoleName(element.name);
        });

      })
      .catch(error => this.errorHandling(error));
    },
    translateRoleName: function (name_en) {
      let name_es = '';
      switch(name_en) {
        case 'Admininstrator': 
          name_es = 'Administrador';
        break;
        case 'Location owner': 
          name_es = 'Propietario de locación';
        break;
        case 'Producer': 
          name_es = 'Casa productora';
        break;
        case 'Provider': 
          name_es = 'Proveedor';
        break;
        case 'Person': 
          name_es = 'Usuario común';
        break;
        case 'Crew': 
          name_es = 'Crew';
        break;
        default: 
          name_es = name_en;
        break;
      }

      return name_es;
    },
    pickFile: function () {
      this.$refs.file.click();
    },
    getFile: function(e) {
      let file = e.target.files[0];
      this.file.name = file.name;
      this.file.document = file
    }
  },
  created() {
    this.getPublicRoles()
  }
};
</script>

<style scoped>
  .text-title-card {
    font-weight: bold;
  }

  .main-card {
    height: 89% !important;
  }

  .wa-checkbox-container {
    margin-top: 2% !important;
  }

  .country-select-code {
    width: 100%;
    border-style: solid;
    border-width: thin 0 0 0;
    height: 65%;
    border-radius: 0.375rem;
  }

  .fm-input-file {
    border-radius: 0.375rem;
    height: 40px;
  }

  .v-input__control {
    display: none !important;
  }
</style>