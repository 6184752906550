<template>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="`background-image:linear-gradient(0deg, #12141B 0%, rgba(0, 20, 27, 0) 100%), url(${require('@/assets/img/cabañatinajas.jpg')}) !important; background-size: cover; background-position: 50%;`"
          >
          </div>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-search">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <v-card class="border-radius-xl blur blur-transparent">
              <v-row class="card-margin" justify="center">
                <v-col cols="12" xs="12" sm="12" md="12" :lg="(temporalSectionType == '13') ? '3' : '4'"
                  v-if="!actualDomain.includes('en.')"
                >
                  <label class="ms-0 text-white">Buscar</label>
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      cancel_gradient_border
                      input-white-text
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Buscar aquí"
                    clearable
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="16"
                        class="material-icons-round mt-n2 "
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" :lg="(temporalSectionType == '13') ? '3' : '4'">
                  <label class="ms-0 text-white">Tipo</label>
                  <v-select
                    v-model="temporalSectionType" 
                    color="white" 
                    dark 
                    item-text="text"
                    item-value="value"
                    :items="selectSectionType"
                    label="Tipo de solicitud"
                    @change="getCategories(temporalSectionType)">
                  </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" :lg="(temporalSectionType == '13') ? '3' : '4'"
                  v-if="temporalSectionType == '13'">
                    <label class="ms-0 text-white">Categoria</label>
                    <v-select
                      v-model="temporalCategory" 
                      color="white" 
                      dark 
                      item-text="name"
                      item-value="id"
                      :items="categories"
                      label="Categoría"
                      clearable
                      @change="getSubcategories(temporalCategory)">
                    </v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" :lg="(temporalSectionType == '13') ? '3' : '4'">
                    <label class="ms-0 text-white">{{ (temporalSectionType == '13') ? 'Subcategoría' : 'Categoría' }}</label>
                    <v-select
                      v-model="temporalSubCategory" 
                      color="white" 
                      dark 
                      item-text="name"
                      item-value="id"
                      :items="subcategories"
                      :label="(temporalSectionType == '13') ? 'Subcategoría' : 'Categoría'"
                      clearable
                      :disabled="subcategories.length < 1"
                      @change="getProvider()"
                    >
                    </v-select>
                </v-col>
                <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="3" class="pt-8">
                    <v-btn 
                        class="mb-5 mx-0 main_gradient_background text-white text-transform-none"
                        x-large
                        @click="getProvider()"
                    >
                        Buscar
                    </v-btn>
                </v-col> -->
              </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-card margin-view" v-if="temporalSectionType == 13">
        <v-col v-for="(provider, index) in providers.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
          <v-card
            class="text-white"
            outlined color="transparent"
            v-for="(company,index) in provider.companies" :key="index"
          >
            <div class="mt-n11 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  :src="company.company.company_image_url ? company.company.company_image_url : ''"
                  :style="company.company.company_image_url ? '' :{background:'#282c39'}"
                  class="img-middle img-fluid shadow border-radius-lg not-alter-image"
                />
                <!-- <v-img
                  :src="provider.personal_information ? provider.personal_information.profile_image_url : ''"
                  :style="provider.personal_information.profile_image_url ? '' :{background:'#282c39'}"
                  class="img-middle img-fluid shadow border-radius-lg"
                /> -->
              </div>
            </div>
            <h2 class="mt-7 mb-2 px-4 text-white">
              {{ company ? company.company.name : '-' }}
            </h2>
            <div class="text-broken">
              <p v-html="company ? company.company.description : '-'" class="mb-7 px-4 text-justify"></p>
            </div>
            <v-card-actions>
              <v-btn class="btn-gradient" large :href="`/supplierInformation/${provider.id}`">
                Ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-card margin-view" v-if="temporalSectionType == 15">
        <v-col cols="12">
          <table class="table-responsive table-sections text-white">
            <thead class="text-white">
              <tr>
                <th>Nombre</th>
                <th>Perfil de IMDB</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody class="notranslate">
              <tr v-for="(provider, index) in providers.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal"
                      :class="(provider.personal_information.complete_name != null) ? 'text-white' : 'text-secondary'"
                    >
                      {{ (provider.personal_information.complete_name != null) ? provider.personal_information.complete_name : 'Sin nombre registrado' }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2 justify-center">
                    <span class="font-weight-normal"
                      :class="(provider.personal_information.imdb_url != null) ? 'text-white' : 'text-secondary'"
                    >
                      <a :href="provider.personal_information.imdb_url" target="_blank" class="link-format">
                        {{ (provider.personal_information.imdb_url != null) ? provider.personal_information.imdb_url : 'Sin perfil de IMDB registrado' }}
                      </a>
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2 justify-center">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Ver mas información"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="`/crewInformation/${provider.id}`"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            visibility
                          </v-icon>
                        </v-btn> 
                      </v-layout>
                    </span>
                  </div>
                </td>        
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-card">
        <v-col cols="12" lg="12" class="d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination table-pagination"
                :length="providers.last_page"
                v-model="providers.current_page"
                total-visible="6"
                circle
                @input="getProvider"
              />
        </v-col>
      </v-row>
    </v-container>  
</template>

<script>
import Api from '@/api/Api';
import debounce from "lodash.debounce";
export default {
  props: {
  },
  data() {
      return {
          visible: false,
          index: 0,
          categories: [],
          subcategories: [],
          itemsPerPage: 9,
          temporalCategory: 0,
          temporalSubCategory: 0,
          search: '',
          providers: [],

          temporalSectionType: 13,
          selectSectionType: [
            { text: 'Proveedor', value: 13 },
            { text: 'Crew', value: 15 }
          ],
          temporalUserRole: 'provider'
      };
  },
  created() {
    this.$emit('navbarMainPage',false);
    this.getCategories('13');
    this.getProvider();
    this.debounceSearchSection = debounce(() => {
        this.getProvider()
    }, 500);
  },
  computed: {
    actualDomain () {
      return window.location.host;
    }
  },
  methods: {
    getCategories(sectionTypeId){
      Api.get(`category/get_by_section_type/${sectionTypeId}`)
      .then(response => {
        this.categories = response.data;

        if (sectionTypeId == 15) {
          this.temporalCategory = this.categories[0].id;
        }
      })
      .catch(error => this.errorHandling(error))
    },
    getSubcategories(category_id) {
      Api.get(`subcategory/get_by_category/${category_id}`)
      .then(response => {
        this.subcategories = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    getSuppliers:function (page = 1){
      Api.get('/user/get_user_by_role_type', {
        params: {
          page: page,
          paginate: this.itemsPerPage,
          role: 'provider'
        }
      })
      .then(response => {
        this.providers = response.data;
      })
      .catch(error => this.errorHandling(error))
    },
    getProvider:function (page = 1){
      Api.get('/users/get_provider_filter', {
        params: {
          page: page,
          paginate: this.itemsPerPage,
          role: this.temporalUserRole,
          query: this.search,
          category: this.temporalCategory,
          subCategory: this.temporalSubCategory
        }
      })
      .then(response => {
        this.providers = response.data;
      })
      .catch(error => this.errorHandling(error))
    }
  },
  watch: {
    temporalCategory (value) {
      this.temporalSubCategory = 0;
      this.getProvider();
      this.getSubcategories(value);
    },
    search () {
      this.debounceSearchSection();
    },
    temporalSectionType (value) {
      this.temporalCategory = 0;
      this.temporalSubCategory = 0;
      this.providers = [];

      if (this.temporalSectionType == '13') {
        this.temporalUserRole = 'provider';
        this.itemsPerPage = 9;
      } else {
        this.temporalUserRole = 'crew';
        this.itemsPerPage = 10;
      }
      this.getProvider();
      this.getCategories(value);
    },
  },
};
</script>
<style scoped>
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.margin-view{
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  margin-top: -10vh;
}
.main-image{
  min-height: 43vh;
}
.card-margin{
  margin-left: 2%;
  margin-right: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-search{
  margin-bottom: 20vh;
}
.margin-card{
  margin-bottom: 20vh;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  min-width: 100% !important;
}
.card-padding{
  padding-right: 3% !important;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
.text-broken{
  line-clamp: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>