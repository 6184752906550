<template style="font-family: 'roboto';">
  <section>
    <div class="container pa-0 py-10 ">
      <v-row class="justify-content-center border-radius-lg">
        <v-col cols="12" class="pa-0">
          <v-btn 
            class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
            to="/admin/companies_management/new/0"
          >
            Nueva empresa
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12"> <!-- Table controls -->
          <v-row>
            <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
              <h5 class="text-h5 text-white mb-0">
                Empresas
              </h5>
              <p class="text-body mb-0">
                Gestionar empresas y usuarios relacionados con ellas
              </p>
            </v-col>
            <v-col cols="11" lg="4" class="d-flex align-left">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  cancel_gradient_border
                  input-white-text
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                placeholder="Buscar por nombre de empresa"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="16"
                    class="material-icons-round mt-n2 "
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12"> <!-- Table body -->
          <table class="table-responsive table-sections text-white notranslate">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Nombre de la compañia</th>
                <th>Usuario presidente</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(record, index) in companies.data" :key="index">
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      #{{ record.id }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      {{ record.name }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span 
                      class="font-weight-normal"
                      :class="(record.user_president) ? 'text-white' : 'text-secondary'"
                    >
                      {{ (record.user_president) ? record.user_president.user.personal_information.name != null ? record.user_president.user.personal_information.complete_name : record.user_president.user.email : 'Not assigned yet' }}
                    </span>
                  </div>
                </td>

                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Editar información de empresa"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="'/admin/companies_management/edit/'+record.id"
                          >
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            edit
                          </v-icon>
                        </v-btn>  
                        <v-btn icon text rel="tooltip" title="Asignar a un usuario" 
                          v-if="record.user_president == null"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="openAssignUserModal(record.id)"
                        >
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            manage_accounts
                          </v-icon>
                        </v-btn>
                        <v-btn icon text rel="tooltip" title="Revocar empresa" 
                          v-if="record.user_president != null"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="revokeCompany(record.id, record.user_president.user.id)"
                        >
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            person_off
                          </v-icon>
                        </v-btn>
                        <v-btn icon text rel="tooltip" title="Borrar empresa" 
                          class="btn btn-primary btn-simple btn-xs white-button"
                          @click="deleteCategory(record.id)">
                          <v-icon end class="material-icons-outlined me-sm-1" size="20">
                            close
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </span>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-left">
              <div class="d-flex px-5 py-3">
                <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                  Mostrando {{ companies.from }} a {{ companies.to }} de {{ companies.total }} registros
                </div>
              </div>
            </v-col>
            <v-col cols="11" lg="6" class="d-flex justify-end">
              
              <v-pagination
                class="pagination table-pagination"
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                v-model="companies.current_page"
                :length="companies.last_page"
                circle
                @input="getCompanies"
              />

            </v-col>
          </v-row>
        </v-col>

        <!--         
        <v-col cols="12">
          <pre style="height: 300px; overflow-y: scroll;" class="text-white">
            companies: {{ companies }}
            search: {{ search }}
            page: {{ page }} 
          </pre>
        </v-col>   
         -->

      </v-row>

      <!-- Modal for add new category or change category name -->
      <!-- <ActionsCompanyModal
        :show.sync="showNewRecordModal"
        :action_edit= "actionEdit"
        :company_id= "currentCompanyId"
      /> -->

      <!-- Modal for assign a user to a company -->
      <v-dialog v-model="showAssignUserModal" class="upload-image-card" width="100%" height="100%">
        <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="500px" height="100%">
          <v-card-text>
            <v-row class="my-5">
              <v-col cols="12" class="d-flex">
                <label class="text-white new-section-title">Asignar esta empresa a un usuario</label>
                <v-spacer></v-spacer>

                <v-btn icon dark @click="showAssignUserModal = false" class="text-white">
                  <v-icon>close</v-icon>
                </v-btn>                    
              </v-col>

              <!--               
              <v-col cols="12" class="mb-0">
                <v-select
                  v-model="newAssignation.user_role"
                  label="Person type"
                  :items="['President', 'Collaborator']"
                  required
                  class="font-size-input input-style mb-0 input-white-text"
                />
              </v-col> 
              -->

              <v-col cols="12" lg="12" class="mt-1 py-0">
                <v-autocomplete
                  v-model="newAssignation.user_id"
                  :items="vAutocompleteEmployer.employers" 
                  :loading="vAutocompleteEmployer.isLoading" 
                  v-bind:search-input.sync="vAutocompleteEmployer.search" 
                  label="Usuario" 
                  item-text="email"
                  item-value="id"
                  hint="Escribe al menos 3 caracteres" 
                  class="input-style mb-5 input-white-text"
                />
              </v-col>

              <!--               
              <v-col cols="12">
                <pre style="height: 300px; overflow-y: scroll;" class="text-white">
                  newAssignation: {{ newAssignation }}
                </pre>
              </v-col>    
              -->
              
            </v-row>

            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="assignUser"
              :loading="btnLoadingNewRecord"
            >
              Asignar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  import debounce from "lodash.debounce";

  //import ActionsCompanyModal from "./ActionsCompanyModal.vue";

  export default {
    name: "companies-management",
    components: {
      //ActionsCompanyModal
    },
    data() {
      return {
        companies: {
          data: []
        },
        search: '',

        //used to crud operations on a record
        btnLoadingNewRecord: false,

        showNewRecordModal: false,
        currentCompanyId: null,
        actionEdit: false,

        
        //used to assign users to a company
        showAssignUserModal: false,
        newAssignation: {
          user_id: null,
          company_id: null,
          user_role: 'President',
        },

        vAutocompleteEmployer: {
          isLoading: false,
          employers: [],
          search: null,
          select: {},
        },
      };
    },
    computed: {
      searchUser: function () {
        return this.vAutocompleteEmployer.search
      }
    },
    watch: {
      search () {
        this.debounceSearchRecord();
      },
      showAssignUserModal (value) {
        if (value == false) {
          this.newAssignation = {
            user_id: null,
            company_id: null,
            user_role: 'President',
          };
        }
      },
      searchUser() {
        this.getUsers();
      },
    },
    methods: {
      getCompanies: function (page = 1) {
        Api.post(`company/get_related_users?page=${page}`, {
          search: this.search,
          role: 'President'
        })
        .then(response => {
          this.companies = response.data;
        })
        .catch(error => this.errorHandling(error))
      },

      openAddRecordModal: function () {
        this.currentCompanyId = null;
        this.actionEdit = false;
        this.showNewRecordModal = true;
      },
      openUpdateRecordModal: function (companyId) {       
        this.currentCompanyId = companyId;
        this.actionEdit = true;
        this.showNewRecordModal = true;
      },

      deleteCategory: function (itemId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "Todos los usuarios asociados perderán su empresa",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.delete(`company/${itemId}`)
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getCompanies();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },

      openAssignUserModal: function (companyId) {
        this.showAssignUserModal = true;
        this.newAssignation.company_id = companyId;
      },
      getUsers: function() {
        if (!this.vAutocompleteEmployer.search || this.vAutocompleteEmployer.search.length < 3) {
          return this.vAutocompleteEmployer.isLoading = true
        }
        return Api.get(`users?query=${this.vAutocompleteEmployer.search}`)
        .then(response => {
          this.vAutocompleteEmployer.employers = response.data.data ? response.data.data : response.data
          this.vAutocompleteEmployer.isLoading = false
        })
        .catch(error => this.errorHandling(error))
      },
      assignUser: function () {
        this.btnLoadingNewRecord = true;
        Api.post(`company/assign_company`, this.newAssignation)
        .then(response => {
          if (response.data.status == true) {
            this.$swal("", response.data.message, "success");
            this.showAssignUserModal = false;
            this.getCompanies();
          } else {
            this.$swal("", response.data.message, "error");
          }
        })
        .catch(error => this.errorHandling(error))
        .finally(() => {
          this.btnLoadingNewRecord = false;
        })
      },
      revokeCompany: function (companyId, userId) {
        this.$swal({
          title: "¿Esta seguro?",
          text: "",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.btnLoadingNewRecord = true;
            Api.post(`company/revoke_company`, {
              user_id: userId,
              company_id: companyId
            })
            .then(response => {
              if (response.data.status == true) {
                this.$swal("", response.data.message, "success");
                this.getCompanies();
              } else {
                this.$swal("", response.data.message, "error");
              }
            })
            .catch(error => this.errorHandling(error))
            .finally(() => {
              this.btnLoadingNewRecord = false;
            })
          } 
        });
      }
      
    },
    created() {
      this.getCompanies();
      this.debounceSearchRecord = debounce(() => {
        this.getCompanies();
      }, 500);
    }
  };
  </script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }
</style>