<template style="font-family: 'roboto';">
    <section>
      <div class="container pa-0 py-10 ">
        <v-row class="justify-content-center border-radius-lg">
          <v-col cols="12" class="pa-0">
            <v-btn 
              class="mb-5 mx-0 main_gradient_background text-white text-transform-none" 
              to="/admin/user_management/new_user"
            >
              Nuevo usuario
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="container">
        <v-row v-if="users.data.length > 0" class="justify-content-center border-radius-lg blur blur-transparent mb-10">
          <v-col cols="12"> <!-- Table controls -->
            <v-row>
              <v-col cols="12" class="align-right ml-3 mt-3 mb-5">
                <h5 class="text-h5 text-white mb-0">
                  Gestión de usuarios
                </h5>
                <p class="text-body mb-0">
                  Todos los usuarios en el sistema
                </p>
              </v-col>
              <v-col cols="12" lg="3" class="d-flex align-right">
                <v-text-field
                  v-model="itemsPerPage" 
                  hide-details
                  type="number"
                  min="1"
                  max="10"
                  color="#FFFFFF"
                  placeholder="Registros por página"
                  class="
                    input-icon
                    cancel_gradient_border
                    input-white-text
                    ma-0
                    pa-1
                  "
                />
                <span class="text-white mt-3 mx-3 text-sm">registros por página</span>
              </v-col>
              <v-col cols="1" lg="6" class="d-flex align-left">
                <!--  -->
              </v-col>
              <v-col cols="11" lg="3" class="d-flex align-left">
                <v-text-field
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    cancel_gradient_border
                    input-white-text
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  v-model="search"
                  placeholder="Buscar por email o nombre"
                >
                  <template slot="prepend-inner">
                    <v-icon
                      color="#adb5bd"
                      size="16"
                      class="material-icons-round mt-n2 "
                      >search</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
  
          <v-col cols="12"> <!-- Table body -->
            <table class="table-responsive table-sections text-white notranslate">
              <thead class="text-white">
                <tr>
                  <th>Id</th>
                  <th>Email</th>
                  <th>Nombre</th>
                  <th>Rol</th>
                  <th>Estatus</th>
                  <th>Email confirmado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user,index) in users.data" :key="index">
                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        #{{ user.id }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ user.email }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ (user.personal_information.name) ? user.personal_information.complete_name : 'n/a' }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span 
                        class="font-weight-normal"
                        :class="(user.roles[0].name_es == 'Sin rol específico') ? 'text-secondary' : 'text-white'"
                      >
                        {{ user.roles[0].name_es }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ (user.approved == null) ? 'Pendiente' : (user.approved == true) ? 'Aprobado' : 'Rechazado' }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ (user.email_verified_at != null) ? 'Sí' : 'No'}}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        <v-layout>                       
                          <v-btn 
                            icon text rel="tooltip" title="Revisar información del usuario"
                            class="btn btn-primary btn-simple btn-xs white-button"
                            :to="'/admin/user_details/'+user.id"
                          >
                            <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                              visibility
                            </v-icon>
                          </v-btn>      
                          <v-btn v-if="loggedUser.Roles[0] == 'Admininstrator' && user.roles[0].name == 'Provider'" icon text rel="tooltip" title="Consultar documento de Registro en el padrón de proveedores" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            :href="user.certificate_sat_url"
                            target="/blank"
                          >
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              workspace_premium
                            </v-icon>
                          </v-btn>
                          <v-btn icon text rel="tooltip" title="Aprobar usuario"
                            v-if="loggedUser.Roles[0] == 'Admininstrator' && user.approved == null"
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="approveOrReject(user.id, true)">
                            <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                              task_alt
                            </v-icon>
                          </v-btn>   
                          <v-btn icon text rel="tooltip" title="Rechazar usuario"
                            v-if="loggedUser.Roles[0] == 'Admininstrator' && user.approved == null"
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="approveOrReject(user.id, false)">
                            <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                              unpublished
                            </v-icon>
                          </v-btn>                       
                          <v-btn v-if="user.roles[0].name != 'Admininstrator'" icon text rel="tooltip" title="Cambiar el tipo de usuario" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="openChangeTypeModal(user)">
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              manage_accounts
                            </v-icon>
                          </v-btn>
                          <v-btn v-if="loggedUser.Roles[0] == 'Admininstrator' && user.email_verified_at == null" icon text rel="tooltip" title="Validar el correo electrónico" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="confirmUserEmail(user.id)">
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              mark_email_read
                            </v-icon>
                          </v-btn>
                          <v-btn v-if="loggedUser.Roles[0] == 'Admininstrator' && user.email_verified_at == null" icon text rel="tooltip" title="Reenviar correo de verificación" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="resendEmail(user.id)"
                            :loading="loadingSendEmail"
                          >
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              mark_as_unread
                            </v-icon>
                          </v-btn>
                          <v-btn icon text rel="tooltip" title="Borrar usuario" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="deleteUser(user.id)">
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              close
                            </v-icon>
                          </v-btn>
                        </v-layout>
                      </span>
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </v-col>
  
          <v-col cols="12" class="d-flex justify-end"> <!-- Table footer -->
            <v-row>
              <v-col cols="12" lg="6" class="d-flex align-left">
                <div class="d-flex px-5 py-3">
                  <div class="d-flex flex-column justify-content-center text-secondary text-xs">
                    Mostrando {{ users.from }} a {{ users.to }} de {{ users.total }} registros
                  </div>
                </div>
              </v-col>
              <v-col cols="11" lg="6" class="d-flex justify-end">
                
                <v-pagination
                  class="pagination table-pagination"
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  v-model="users.current_page"
                  :length="users.last_page"
                  circle
                  @input="getUsers"
                />

              </v-col>
            </v-row>
          </v-col>

          <!--
          <v-col cols="12">
            <pre style="height: 300px; overflow-y: scroll;" class="text-white">
              currentUser: {{ currentUser }}
              showRoleModal: {{ showRoleModal }}
              users: {{ users }}
              search: {{ search }}
              itemsPerPage: {{ itemsPerPage }}
              page: {{ page }} 
            </pre>
          </v-col>   
          -->
        </v-row>

        <v-dialog v-model="showRoleModal" class="upload-image-card" width="100%" height="100%">
          <v-card class="mx-auto border-radius-xl blur blur-transparent text-white" max-width="400px" height="100%">
            <v-card-text>
              <v-row class="my-5">
                <v-col cols="12" class="d-flex">
                  <label class="text-white new-section-title">Cambiar tipo de usuario</label>
                  <v-spacer></v-spacer>

                  <v-btn icon dark @click="showRoleModal = false" class="text-white">
                    <v-icon>close</v-icon>
                  </v-btn>                    
                </v-col>
                <v-col cols="12" class="text-white">
                  <p class="my-1"><b>Id:</b> &nbsp;&nbsp; {{ currentUser.id }}</p>
                  <p class="my-1"><b>Email:</b> &nbsp;&nbsp; {{ currentUser.email }}</p>
                  <p class="my-1"><b>Rol actual:</b> &nbsp;&nbsp; {{ currentUser.role }}</p>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="new_user_type"
                    class="font-size-input input-style mb-5 style_background"
                    hide-details
                    color="#e91e63"
                    outlined
                    dense
                    label="Tipo de cuenta"
                    :items="roles"
                    item-text="name_es"
                    item-value="name"
                  />
                </v-col>
              </v-row>
              <v-btn 
                block
                class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
                @click="changeUserType"
                :loading="btnLoading"
              >
                Cambiar tipo de usuario
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
        </div>
      </section>
    </template>
    
    <script>
    //import Csrf from '@/api/Csrf';
    import Api from '@/api/Api';
    import debounce from "lodash.debounce";
  
    export default {
      name: "Dashboard",
      components: {
        //
      },
      data() {
        return {
          users: {
            data: []
          },
          search: '',
          itemsPerPage: 10,
          page: 1,
          loggedUser: {
            Personal_information: {
              complete_name: "  "
            },
            Roles: [
              null
            ]
          },

          roles: [],
          showRoleModal: false,
          btnLoading: false,
          new_user_type: null,
          currentUser: {
            id: null,
            email: '',
            role: null,
          },


          loadingSendEmail: false
        };
      },
      watch: {
        itemsPerPage(value) {
          if (value === '') value = 10;
          this.getUsers();
        },
        search () {
          this.debounceSearch();
        }
      },
      methods: {
        getUserInfo: function () {
          Api.get('/auth/logged_user').then(response => {
            this.loggedUser = response.data;
          })
          .catch(error => this.errorHandling(error))
        },
        getUsers: function (page = 1) {
          Api.get(`users?page=${page}&query=${this.search}&paginate=${this.itemsPerPage}`)
          .then(response => {
            this.users = response.data;

            this.users.data.forEach(elementi => {
              if (elementi.roles.length > 0){
                elementi.roles.forEach(elementj => {
                  elementj.name_es = this.translateRoleName(elementj.name);
                });
              } else {
                let fictionRole = {
                  name: 'Sin rol específico',
                  name_es: 'Sin rol específico'
                };
                elementi.roles.push(fictionRole);
              }
              
            });
          })
          .catch(error => this.errorHandling(error))
        },
        translateRoleName: function (name_en) {
          let name_es = '';
          switch(name_en) {
            case 'Admininstrator': 
              name_es = 'Administrador';
            break;
            case 'Location owner': 
              name_es = 'Propietario de locación';
            break;
            case 'Producer': 
              name_es = 'Casa productora';
            break;
            case 'Provider': 
              name_es = 'Proveedor';
            break;
            case 'Person': 
              name_es = 'Usuario común';
            break;
            case 'Crew': 
              name_es = 'Crew';
            break;
            default: 
              name_es = name_en;
            break;
          }

          return name_es;
        },

        getRoles: function () {
          Api.get('roles')
          .then(response => {
            this.roles = response.data;
          })
          .catch(error => this.errorHandling(error))
        },
        openChangeTypeModal: function (user) {
          this.currentUser.id = user.id;
          this.currentUser.email = user.email;
          this.currentUser.role = user.roles[0].name;

          this.showRoleModal = true;
        },
        changeUserType: function () {
          this.$swal({
            title: "Confirmación",
            text: "¿Seguro que quiere cambiar el tipo de usuario?",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              this.btnLoading = true;
              Api.post(`roles/assign_role`, {
                user_id: this.currentUser.id,
                role: this.new_user_type
              })
              .then(response => {
                if (response.data.status == true) {
                  Api.post(`roles/remove_role`, {
                    user_id: this.currentUser.id,
                    role: this.currentUser.role
                  })
                  .then(() => {
                    this.$swal("", "Rol cambiado correctamente", "success");
                    this.showRoleModal = false;
                    this.new_user_type = null;
                    this.currentUser = {
                      id: null,
                      email: '',
                      role: null,
                    }
                    this.getUsers();
                  })
                  .catch(error => this.errorHandling(error))
                  .finally(() => {
                    this.btnLoading = false
                  })
                } 
              })
              .catch(error => this.errorHandling(error))
              .finally(() => {
                this.btnLoading = false
              })
            } 
          });
        },

        approveOrReject: function(SectionId, value) {
          this.$swal({
            title: ((value) ? '¿Aprobar usuario?' : '¿Rechazar usuario?'),
            text: "",
            type: "info",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.post(`users/approve_reject_user`, {
                user_id: SectionId,
                approved: value
              })
              .then(response => {
                this.$swal.fire("Success", response.data.message, "success");
                this.getUsers();
              })
              .catch(error => this.errorHandling(error))
            } 
          });
        },

        confirmUserEmail (userId) {
          this.$swal({
            title: "¿Validar correo?",
            text: "No se podrá revertir",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.post(`/auth/confirm_user_email/${userId}`)
              .then(response => {
                if (response.data.status == true) {
                  this.$swal.fire("Success", response.data.message, "success");
                } else {
                  this.$swal.fire("Success", response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error))
              .finally(() => {
                this.getUsers();
              })
            } 
          });
        },

        resendEmail (userId) {
          this.loadingSendEmail = true;
          this.$swal({
            title: "¿Reenviar correo de verificación?",
            text: "",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.post(`/email/resend-verification-notification/${userId}`)
              .then(response => {
                if (response.data.status == true) {
                  this.$swal.fire("Success", response.data.message, "success");
                } else {
                  this.$swal.fire("Success", response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error))
              .finally(() => {
                this.getUsers();
                this.loadingSendEmail = false;
              })
            } else {
              this.loadingSendEmail = false;
            }
          });
        },

        deleteUser: function (userId) {
          this.$swal({
            title: "¿Esta seguro?",
            text: "No se podrá revertir",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              Api.delete(`users/${userId}`)
              .then(response => {
                if (response.data.status == true) {
                  this.$swal("", response.data.message, "success");
                  this.getUsers();
                } else {
                  this.$swal("", response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error))
            } 
          });
        },
        
      },
      created() {
        this.getUserInfo();
        this.getRoles();
        this.getUsers();
        this.debounceSearch = debounce(() => {
          this.getUsers();
        }, 500);
        this.sectionTypeId = this.$route.params.section_type_id;
      }
    };
    </script>
  <style scoped>
    .table-sections.theme--light.v-data-table {
      background-color: transparent !important;
    }
  
    .actions-column {
      max-width: 100px;
      overflow-x: scroll;
    }
  </style>