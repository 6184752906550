<template>
    <v-container fluid class="pa-0 margin-main-image">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <div
            class="page-header main-image min-vw-100"
            :style="{ background:'#12141B' }"
          >
          </div>
          </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom margin-main-image" v-for="(company,index) in provider.companies" :key="index">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="text-white supplier-description d-flex flex-column">
          <h1 class="text-white supplier-title">{{ company ? company.company.name : '' }}</h1>
          <div class="information">
            <v-expansion-panels color="#12141B" flat tile>
                <v-expansion-panel>
                    <v-expansion-panel-header color="#12141B" class="text-white" disable-icon-rotate expand-icon="">
                    <template v-slot:default="{ open }">
                        Descripcion
                        <v-icon color="white" class="d-flex justify-end">
                            <template v-if="open">fa-minus</template>
                            <template v-else>fa-plus</template>
                        </v-icon>
                    </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content 
                     
                      color="#12141B" 
                      class="text-white">
                      <div v-html="company ? company.company.description : '-'"></div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="information">
            <v-expansion-panels color="#12141B" flat tile>
                <v-expansion-panel>
                    <v-expansion-panel-header color="#12141B" class="text-white" disable-icon-rotate expand-icon="">
                    <template v-slot:default="{ open }">
                        Detalles
                        <v-icon color="white" class="d-flex justify-end">
                            <template v-if="open">fa-minus</template>
                            <template v-else>fa-plus</template>
                        </v-icon>
                    </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#12141B" class="text-white justify-center">
                     <p>Email: {{ company ? company.company.email : '' }}</p>
                     <p>Telefono: {{ company ? company.company.phone_prefix + ' ' + company.company.phone : '' }}</p>
                     <p>Tipo de proveedor: {{ company ? company.company.company_type_es : '' }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="supplier-trailer">
          <v-img 
            :src="(company && company.company.company_image_url) ? company.company.company_image_url : ''"      
            :style="(company && company.company.company_image_url) ? '' :{background:'#282c39'}"
            height="50vh"
            class="not-alter-image">
          </v-img>
          <!-- <v-img 
            :src="provider.personal_information ? provider.personal_information.profile_image_url : ''"      
            :style="provider.personal_information.profile_image_url ? '' :{background:'#282c39'}"
            height="50vh">
          </v-img> -->
          <!-- <v-carousel v-model="currentPage" :show-arrows="false" hide-delimiters>
            <v-carousel-item
            :src="item.image" v-for="item in carouselItems" :key="item.id"
            ></v-carousel-item>
            
          </v-carousel>
          <v-pagination
                class="pagination table-pagination margin-pagination" 
                circle v-model="adjustedPage" 
                :length="carouselItems.length" 
                @input="updatePage">
          </v-pagination> -->
        </v-col>
      </v-row>
      <v-row class="margin-view" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Productos y servicios
            </h1>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-table border-radius-lg blur blur-transparent mb-10">
        <v-col cols="12">
          <table class="table-responsive table-sections text-white">
            <thead class="text-white">
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Tipo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody class="notranslate">
              <tr v-for="(productService,index) in provider.products_services" :key="index">
                <td>
                  <div class="d-flex align-center ms-2 justify-center">
                    <span class="font-weight-normal text-white ">
                      #{{ productService.id }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2">
                    <span class="font-weight-normal text-white ">
                      {{ productService.indexed_contents.title ? productService.indexed_contents.title.value : '' }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2" >
                    <span class="font-weight-normal text-white table-description-cell" v-html="productService.indexed_contents.main_description ? productService.indexed_contents.main_description.value : '' ">
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2 justify-center">
                    <span class="font-weight-normal text-white ">
                      {{ productService.section_type_text ? productService.section_type_text.section_type_es : '' }}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center ms-2 justify-center">
                    <span class="font-weight-normal text-white">
                      <v-layout>                                              
                        <v-btn icon text rel="tooltip" title="Ver mas información"
                          class="btn btn-primary btn-simple btn-xs white-button"
                          :to="`/productService/${productService.id}/${id}`">
                          <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                            visibility
                          </v-icon>
                        </v-btn> 
                      </v-layout>
                    </span>
                  </div>
                </td>        
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row class="margin-view margin-bottom" justify="center">
        <v-col cols="12" xs="12" sm="12" md="5" lg="6">
          <v-card class="text-center text-white" outlined color="transparent">
            <h1 class="justify-center mb-5">
              Mira otros proveedores
            </h1>
            <p class="parrafo">Busca la variedad de proveedores que tenemos en Durango.</p>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="margin-card margin-view">
        <v-col v-for="(provider, index) in providers.data" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" class="card-padding">
          <v-card
            class="text-white"
            outlined color="transparent"
            v-for="(company,index) in provider.companies" :key="index"
          >
            <div class="mt-n11 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                :src="company.company.company_image_url ? company.company.company_image_url : ''"
                  :style="company.company.company_image_url ? '' :{background:'#282c39'}"
                  class="img-middle img-fluid shadow border-radius-lg"
                />
              </div>
            </div>
            <h2 class="mt-7 mb-2 px-4 text-white">
              {{ company ? company.company.name : '-' }}
            </h2>
            <div class="text-broken">
              <p v-html="company ? company.company.description : '-'" class="mb-7 px-4 text-justify"></p>
            </div>
            <v-card-actions>
              <v-btn class="btn-gradient" large :href="`/supplierInformation/${provider.id}/`">
                Ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import Api from '@/api/Api';
export default{
    props: {
    },
    data() {
        return {
            currentPage: 0,
            id: this.$route.params.user_id,
            provider: [],
            providers: []
        };
    },
    created() {
      this.$emit('navbarMainPage',false);
      this.getSupplier();
      this.getSuppliers();
    },
    methods: {
        updatePage(page) {
            this.adjustedPage = page;
        },
        getSupplier:function(){
          Api.get(`/users/get_provider_complete_information/${this.id}`)
          .then(response => {
            this.provider = response.data;
          })
          .catch(error => this.errorHandling(error))
        },
        getSuppliers:function (page = 1){
        Api.get('/user/get_user_by_role_type', {
          params: {
            page: page,
            paginate: this.itemsPerPage,
            role: 'provider'
          }
        })
        .then(response => {
          this.providers = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
    },
    computed: {
        adjustedPage: {
            get() {
            return this.currentPage + 1;
            },
            set(value) {
            this.currentPage = value - 1;
            }
        },
    }
}
</script>
<style scoped>
.margin-view{
    margin-left: auto;
    margin-right: auto;
    max-width: 85%;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}
.main-image{
  min-height: 70vh;
}
.supplier-description{
    margin-top: 30vh;
}
.supplier-trailer{
    margin-top: 30vh;
}
.supplier-title{
    margin-bottom: 2vh;
}
.information{
    display: inline-flex;
    margin-bottom: 1vh;
    margin-left: -2.1vh;
}
.width-text{
    width: 10vw;
}
.margin-main-image{
    margin-bottom: 10vh;
}
.margin-bottom{
    margin-bottom: 10vh;
}
.margin-main-image{
  margin-top: -35vh;
}
.btn-gradient{
  border-radius: 10px;
  font-weight: 500;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, #FFB800 0%, #FF4146 53%, #FF1361 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #12141B inset;
}
.v-btn:not(.v-btn--round).v-size--large{
  height: 50px !important;
  max-width: 100px !important;
  padding: 0 6vw !important;
}
.theme--light.v-btn { 
  color: white !important;
}
.margin-pagination{
    margin-top: 3vh;
}
.img-middle{
  height: 32vh;
  background-size: cover; 
  background-position: 50%;
}
.table-sections.theme--light.v-data-table {
  background-color: transparent !important;
}
.v-expansion-panel-header {
  font-size: 1.3em !important;
}
.margin-table{
  margin-bottom: 15vh !important;
}
</style>