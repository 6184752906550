<template>
  <v-app>
    <v-main class="auth-pages">
      <div class="position-relative min-vh-100 background-page" :style="`background-image: url(${backgroundImage});`">
        <span class="mask bg-gradient-custom"></span>
        <v-container class="my-auto">
          <v-row class="min-vh-100">
            <app-bar-auth background="transparent" has-bg linkColor="white">
            </app-bar-auth>
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <!-- your content here -->
              <v-container class="pb-0 position-relative">
                <router-view></router-view>
                <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
              </v-container>
            </fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "../../Authentication/Layout/AppBarAuthLayout.vue";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "../../Authentication/Layout/FooterAuthLayout.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      backgroundImage: 'https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80'
    };
  }
};
</script>

<style scoped>
.background-page {
  background-size: cover;
  background-position: 100% 100%;
}

.bg-gradient-custom {
  background-image: linear-gradient(360deg, #000C1F 0%, rgba(0, 12, 31, 0) 100%)
}
</style>