<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="user != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white mx-1 mb-3" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 pb-3">
              <label class="text-white new-section-title">Editar información de empresa</label>
            </v-col>
            <v-col cols="12" lg="12" class="pt-0 pb-3">
              <!-- 
                Inhabilitado hasta etapa 2

                <label class="text-white">Selecciona una empresa existente, o registra una nueva empresa.</label> 
              -->
              <label class="text-white">Ingresa la información de tu empresa.</label>
            </v-col>
          </v-row>

          <!-- 
          Inhabilitado hasta etapa 2

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <v-radio-group 
                v-model="companyOption"
                inline 
                class="my-0"
              >
                <v-radio label="Empresa existente" value="existing" 
                class="input-style mb-5 input-white-text"/>
                <v-radio label="Nueva empresa" value="new"
                class="input-style input-white-text"/>
              </v-radio-group>
            </v-col>
          </v-row> 
        

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row v-if="companyOption == 'existing'" class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Empresas existentes</label>
            </v-col>
            <v-col cols="12" lg="12" class="mt-1 py-0">
              <v-autocomplete
                v-model="existingCompany"
                :items="vAutocompleteEmployer.employers" 
                :loading="vAutocompleteEmployer.isLoading" 
                v-bind:search-input.sync="vAutocompleteEmployer.search" 
                label="Empresa" 
                item-text="name"
                hint="Escribe al menos 3 caracteres" 
                class="input-style mb-5 input-white-text"
                return-object
              />
            </v-col>
          </v-row>

          -->

          <!-- <v-col v-if="existingCompany != null" cols="12" class="mt-0 pt-0 secondary-text">
              <p class="my-1"><b>Name:</b> &nbsp;&nbsp; {{ (existingCompany.name) ? existingCompany.name : '---' }}</p>
              <p class="my-1"><b>Phone:</b> &nbsp;&nbsp; {{ (existingCompany.phone) ? '('+existingCompany.phone_prefix+') '+existingCompany.phone : '---'}}</p>
              <p class="my-1"><b>Whatsapp?:</b> &nbsp;&nbsp; {{ (existingCompany.phone_whatsapp) ? 'Yes' : 'No' }}</p>
              <p class="my-1"><b>Email:</b> &nbsp;&nbsp; {{ (existingCompany.email) ? existingCompany.email : '---' }}</p>
              <p class="my-1"><b>Person type:</b> &nbsp;&nbsp; {{ (existingCompany.person_type) ? existingCompany.person_type : '---' }}</p>
            </v-col> -->

          <v-row v-if="companyOption == 'new'" class="text-white ma-1 mt-5" justify="space-around">
            <!-- 
            Inhabilitado hasta etapa 2

            <v-col cols="12" lg="12" class="mt-1 py-0">
              <label class="text-white font-weight-bold">Nueva empresa</label>
            </v-col> 
            -->

            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="newCompany.name"
                label="Nombre"
                placeholder="Nueva empresa"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>

            <v-col cols="12" lg="4" class="mb-5">
              <v-text-field
                v-model="newCompany.email"
                label="Email"
                placeholder="example@gmail.com"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>

            <v-col cols="12" lg="4" class="mb-5">
              <v-select
                v-model="newCompany.person_type"
                label="Tipo de persona"
                :items="selectPersonType"
                item-text="text"
                item-value="value"
                required
                class="font-size-input input-style mb-5 input-white-text"
              />
            </v-col>

            <v-col cols="12" lg="4" class="my-5">
              <label class="mr-3">Código de país</label>
              <vue-country-code 
                class="input-style style_background country-select-code"
                style="color: #12141B"
                v-model="newCompany.phone_prefix"
                :disabledFetchingCountry="true"
                :enabledCountryCode="true"
                :preferredCountries="preferredCountries"
                @onSelect="selectCountryCode"
              />
            </v-col>

            <v-col cols="12" lg="4">
              <v-text-field
                v-model="newCompany.phone"
                label="Teléfono"
                placeholder="6181234567"
                color="text-white"
                class="input-style mb-5 input-white-text"
                hide-details
              />
            </v-col>

            <v-col cols="12" lg="4">
              <div>
                <v-checkbox 
                  color="white"
                  v-model="newCompany.phone_whatsapp"
                  class="wa-checkbox-container"
                >
                  <template v-slot:label>
                    <span class="text-white">¿Whatsapp?</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" lg="12" class="mb-5 px-0">
              <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around">
                <v-col cols="12" lg="12" class="mt-1">
                  <label class="text-white">Descripción de la empresa</label>
                </v-col>
                <hr style="width: 100%; border: 1px solid #7b809a;">
                <v-col cols="12" lg="12" class="mt-1">
                  <VueEditor 
                    v-model="newCompany.description"
                    class="input-style ma-0 pa-1 input-white-text white-background"
                    :editorOptions="optionsEditor"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mb-0">
              <v-select
                v-model="newCompany.company_type"
                label="Tipo de empresa"
                :items="selectCompanyType"
                item-text="text"
                item-value="value"
                required
                class="font-size-input input-style mb-5 input-white-text"
              />
            </v-col>

            <v-col cols="12" class="ma-0 pa-0">
              <hr class="horizontal mb-0 light line_gradient_background"/>
            </v-col>

            <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
              class="form-control" 
            />
            <v-row class="text-white ma-1 mt-5 border-radius-lg" justify="space-around">
              <v-col cols="12" class="d-flex">
                <label class="text-white font-weight-bold">Imágen de la empresa</label>
              </v-col>
              <div class="border-radius-lg main-img-container">
                <!-- 'https://via.placeholder.com/200?text=Imagen' -->
                <v-img :src="(newCompany.company_image_url != null) ? newCompany.company_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                class="img-view-size pa-2 border-radius-lg">
                  
                </v-img>
              </div>
              <v-col cols="11" v-if="newCompany.company_image_url">
                <label class="text-white">{{ newCompany.company_image_name }}</label>
              </v-col>
              <v-col cols="1" v-if="newCompany.company_image_url">
                <v-layout class="text-right">
                  <v-btn icon color="primary" :href="newCompany.company_image_url" target="_blank" title="Descargar imágen">
                    <v-icon outlined 
                      class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                      file_download
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-col>

              <v-col cols="12" class="d-flex justify-center">
                <label class="text-white text-center">Subir una imágen desde su dispositivo (Tamaño máximo de 1.95 MB)</label>
              </v-col>
              <v-col cols="12" lg="4" class="d-flex">
                <!--  -->
              </v-col>
              <v-col cols="12" lg="4" class="d-flex">
                <v-btn 
                  block
                  class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
                  @click="pickFile"
                  :loading="btnLoading"
                >
                  Subir imágen
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4" class="d-flex">
                <!--  -->
              </v-col>
            </v-row>

            <v-col cols="12" class="ma-0 pa-0">
              <hr class="horizontal mb-0 light line_gradient_background"/>
            </v-col>

            <v-row class="text-white ma-1 mt-5 border-radius-lg" justify="space-around">
              <v-col cols="12" lg="12" class="mt-1 py-0">
                <label class="text-white font-weight-bold">Categorías de la nueva empresa</label>
              </v-col>

              <v-col cols="12" lg="5" class="mb-5">
                <v-select
                  v-model="newCategory.category"
                  item-text="name"
                  :items="categories"
                  label="Categoría"
                  required
                  return-object
                  class="font-size-input input-style input-white-text"
                  @change="getSubcategories(newCategory.category.id)"
                />
              </v-col>  

              <v-col cols="12" lg="5" class="mb-5">
                <v-select
                  v-model="newCategory.subcategory"
                  item-text="name"
                  :items="subcategories"
                  label="Subcategoría"
                  required
                  return-object
                  class="font-size-input input-style input-white-text"
                  :disabled="subcategories.length < 1"
                />
              </v-col>

              <v-col cols="12" lg="2" class="mb-5">
                <v-btn icon text rel="tooltip" title="Añadir categoría" 
                  class="btn btn-primary btn-simple btn-xs white-button"
                  @click="addCategory()">
                  <v-icon end class="material-icons-outlined me-sm-1" size="20">
                    add
                  </v-icon>
                  Añadir categoría
                </v-btn>
              </v-col>

              <v-col cols="12"> <!-- Table body -->
                <table class="table-responsive table-sections text-white notranslate">
                  <thead class="text-white">
                    <tr>
                      <th>Categoría</th>
                      <th>Subcategoría</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(category, index) in newCompany.companyCategories" :key="index">
                      <td>
                        <div class="d-flex align-center ms-2">
                          <span class="font-weight-normal text-white">
                            {{ category.category_name }}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div class="d-flex align-center ms-2">
                          <span class="font-weight-normal text-white">
                            {{ category.subcategory_name }}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div class="d-flex align-center ms-2">
                          <span class="font-weight-normal text-white">
                            <v-layout>                                              
                              <v-btn icon text rel="tooltip" title="Borrar categoría" 
                                class="btn btn-primary btn-simple btn-xs white-button"
                                @click="deleteCategory(index)">
                                <v-icon end class="material-icons-outlined me-sm-1" size="20">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-layout>
                          </span>
                        </div>
                      </td>

                    </tr>

                    <tr v-if="newCompany.companyCategories.length == 0">
                      <td colspan="5" class="pa-3 align-center text-secondary" style="text-align: center;">
                        Aún no hay Categorías
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-row>

          <hr class="horizontal mb-0 light line_gradient_background"/>

          <v-row class="text-white ma-1">
            <v-col cols="12" lg="9" class="d-flex align-right">
              <!--  -->
            </v-col>
            <v-col cols="12" lg="3" class="d-flex align-right">
              <v-btn 
                class="my-5 mx-1 cancel_gradient_border text-white text-transform-none transparent-background"
                @click="cancelConfirm"
              >
                Cancelar
              </v-btn>
              <v-btn 
                class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="updateInfo"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      
     <!--  <pre style="height: 300px; overflow-y: scroll;" class="text-white">
        newCompany: {{ newCompany }}
        companyCategories: {{ newCompany.companyCategories }}
        newCategory: {{ newCategory }}
        existingCompany: {{ existingCompany }}
        currentUserCompany: {{ currentUserCompany }}
        companies: {{ vAutocompleteEmployer.employers }}
        user: {{ user }}
      </pre> -->
      
    </div>
  </section>
</template>
  
<script>
  //import Csrf from '@/api/Csrf';
  import Api from '@/api/Api';
  import { VueEditor } from "vue2-editor";
  import debounce from "lodash.debounce";

  export default {
    name: "edit-company-information",
    components: {
      VueEditor
    },
    data() {
      return {
        user: null,
        preferredCountries: [
          'mx',
          'us',
          'ca'
        ],
        selectPersonType: [
          { text: 'Moral', value: 'moral'},
          { text: 'Física', value: 'physical'}
        ],
        companyOption: 'new',
        currentUserCompany: {
          company: ''
        },
        existingCompany: {},

        newCompany: {
          name: null,
          phone_whatsapp: false,
          phone_prefix: '+52',
          person_type: 'moral',
          description: '',
          company_type: 'national',
          companyCategories: [],
          company_image_name: null,
          company_image_url: null,
        },
        
        btnLoading: false,

        newCategory: {
          category: null,
          subcategory: null
        },

        vAutocompleteEmployer: {
          isLoading: false,
          employers: [],
          search: null,
          select: {},
        },
        resultMessage: '',

        selectCompanyType: [
          {text: 'Local', value: 'local'},
          {text: 'Nacional', value: 'national'},
          {text: 'Internacional', value: 'international'},
        ],

        categories: [],
        subcategories: [],

        optionsEditor: {
          theme: 'snow',
          formats: [
            'bold',
            'italic',
            'list',
            'align',
          ],
          modules: {
            toolbar: [
              ['bold', 'italic'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ align: "left" }, { align: "center" }, { align: "right"}, { align: "justify"}]
            ],
          }
        },
      };
    },
    computed: {
      searchEmployer: function () {
        return this.vAutocompleteEmployer.search
      }
    },
    watch: {
      user(value) {
        if (value != null) {
          this.getCompanyInfo(value.User.id);
        }
      },
      searchEmployer() {
        this.getEmployers();
      },
      'newCompany.name' () {
        if (this.newCompany.name != '') {
          this.debounceVerifyName();
        }
      },
      'newCategory.category' () {
        this.newCategory.subcategory = null;
      }
    },
    methods: {
      getCompanyInfo: function (userId) {
        Api.get(`company/get_company_by_user/${userId}`)
        .then(response => {
          this.currentUserCompany = response.data;

          if (this.currentUserCompany.company.name) {
            this.existingCompany = this.currentUserCompany.company;
            this.vAutocompleteEmployer.search = this.currentUserCompany.company.name;
            this.getEmployers();
          }
        })
        .catch(error => this.errorHandling(error))
      },
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.user = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getEmployers: function() {
        if (!this.vAutocompleteEmployer.search || this.vAutocompleteEmployer.search.length < 3) {
          return this.vAutocompleteEmployer.isLoading = true
        }
        //return Api.get(laravelRoute('bosses.index', queryParam))
        return Api.get(`company?query=${this.vAutocompleteEmployer.search}`)
        .then(response => {
          this.vAutocompleteEmployer.employers = response.data.data ? response.data.data : response.data
          this.vAutocompleteEmployer.isLoading = false
        })
        .catch(error => this.errorHandling(error))
      },
      selectCountryCode: function ({ name, iso2, dialCode }) {
        this.newCompany.phone_prefix = name + iso2;
        this.newCompany.phone_prefix = '+'+dialCode;
      },
      getCategories: function () {
        Api.get(`category/get_by_section_type/13`)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },        
      getSubcategories: function (category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },   
      addCategory: function () {
        if (this.newCategory.category != null && this.newCategory.subcategory != null) {
          let newCat = {};
        
          newCat = {
            category_name: this.newCategory.category.name,
            category_id: this.newCategory.category.id,
            subcategory_name: this.newCategory.subcategory.name,
            subcategory_id: this.newCategory.subcategory.id
          }

          let previousCategory = this.newCompany.companyCategories.find(element => element.category_id == newCat.category_id && element.subcategory_id == newCat.subcategory_id);

          if (previousCategory == null) {
            this.newCompany.companyCategories.push(newCat);
          } else {
            this.$swal("", "Ya añadió esa categoría y subcategoría", "warning");
          } 
        } else {
          this.$swal("", "Debe elegir una categoría y una subcategoría", "warning");
        }
      },
      deleteCategory: function (index) {
        this.newCompany.companyCategories.splice(index, 1);
      },

      checkIfCompanyExists: function() {
        Api.get(`company/get_company_by_name/${this.newCompany.name}`)
        .then(response => {
          if (response.data) {
            this.$swal("", "Ya existe otra empresa con ese nombre", "warning");
            this.newCompany.name = ''
          }
        })
        .catch(error => this.errorHandling(error))
      },

      updateInfo: function () {
        if (this.companyOption == 'existing') {
          if (this.existingCompany.id) {
            if (!this.currentUserCompany) {
              //The user doesn't have an actual company
              Api.post(`company/assign_company`, {
                user_id: this.user.User.id,
                company_id: this.existingCompany.id,
                user_role: 'President'
              })
              .then(response => {
                if (response.data.status == true) {
                  this.$swal("", response.data.message, "success");
                  this.$router.push({ 
                    name: 'Profile Overview'
                  })
                } else {
                  this.$swal("", response.data.message, "error");
                }
              })
              .catch(error => this.errorHandling(error))
              
            } else if (this.currentUserCompany.company.id != this.existingCompany.id) {
              //the user's actual company it's different than the new company
              //delete the previous association and add the new one
              Api.post(`company/revoke_company`, {
                user_id: this.user.User.id,
                company_id: this.currentUserCompany.company.id
              })
              .then(() => {
                Api.post(`company/assign_company`, {
                  user_id: this.user.User.id,
                  company_id: this.existingCompany.id,
                  user_role: 'President'
                })
                .then(response => {
                  if (response.data.status == true) {
                    this.$swal("", response.data.message, "success");
                    this.$router.push({ 
                      name: 'Profile Overview'
                    })
                  } else {
                    this.$swal("", response.data.message, "error");
                  }
                })
                .catch(error => this.errorHandling(error))
              })
              .catch(error => this.errorHandling(error))
            } else {
              this.$swal("", "Información de empresa actualizada", "success");
              this.$router.push({ 
                name: 'Profile Overview'
              })
            }
          } else {
            this.$swal("Datos faltantes", "Debes seleccionar una empresa existente", "warning");
          }
        } else if (this.companyOption == 'new') { 
          if (this.newCompany.companyCategories.length > 0) {
            if (!this.currentUserCompany) {
              Api.post(`company`, this.newCompany)
              .then(response => {

                this.resultMessage += response.data.message;
                Api.post(`company/assign_company`, {
                  user_id: this.user.User.id,
                  company_id: response.data.company_id,
                  user_role: 'President'
                })
                .then(response => {
                  this.resultMessage += '<br>' + response.data.message;
                  this.$swal("", this.resultMessage, "success");
                  this.$router.push({ 
                    name: 'Profile Overview'
                  })
                })
                .catch(error => this.errorHandling(error))
                
              })
              .catch(error => this.errorHandling(error))
            } else {
              Api.post(`company/revoke_company`, {
                user_id: this.user.User.id,
                company_id: this.currentUserCompany.company.id
              })
              .then(() => {

                Api.post(`company`, this.newCompany)
                .then(response => {

                  this.resultMessage += response.data.message;
                  Api.post(`company/assign_company`, {
                    user_id: this.user.User.id,
                    company_id: response.data.company_id,
                    user_role: 'President'
                  })
                  .then(response => {
                    this.resultMessage += '<br>' + response.data.message;
                    this.$swal("", this.resultMessage, "success");
                    this.$router.push({ 
                      name: 'Profile Overview'
                    })
                  })
                  .catch(error => this.errorHandling(error))
                  
                })
                .catch(error => this.errorHandling(error))

              })
              .catch(error => this.errorHandling(error))
            }
          } else {
            this.$swal("", "Debe elegir por lo menos una categoría y una subcategoría", "warning");
          }
        }
      },

      pickFile: function () {
        this.$refs.input_file.click();
        this.fileSlug = 'company_profile_image';
      },
      getFile: function (e) {
        this.btnLoading = !this.btnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {

          let formData = new FormData();
          formData.append('file', file)
          formData.append('slug', this.fileSlug)
          formData.append('path', 'companies/images/')

          Api.post('files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.$swal(response.data.message, '', "success");
            this.newCompany.company_image_name = response.data.file_name;
            this.newCompany.company_image_url = response.data.file_url;
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoading = !this.btnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.btnLoading = !this.btnLoading;
        }
      },

      cancelConfirm: function () {
        this.$swal({
          title: "¿Esta seguro?",
          text: "Los cambios realizados no se guardarán",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.$router.push({ 
              name: 'Profile Overview'
            })
          }
        });
      },
    },
    mounted() {
      this.getUserInfo();
      this.getEmployers();
      this.getCategories();
      this.debounceVerifyName = debounce(() => {
        this.checkIfCompanyExists();
      }, 500);
    }
  };
</script>
<style scoped>
  .main-img-container {
    width: 290px !important;
    height: 200px !important;
    background-color: #FFFFFF;
    overflow: hidden;
  }
</style>