<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row class="justify-content-center border-radius-lg blur blur-transparent mb-10 pa-5 mt-5">
        <v-col cols="12" class="d-flex">
          <label class="text-white new-section-title">{{ (action_edit == 'edit') ? 'Actualizar información de empresa' : 'Añadir nueva empresa' }}</label>
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-text-field
            v-model="currentRecord.name"
            label="Nombre"
            placeholder="Nueva empresa"
            color="text-white"
            class="input-style mb-5 input-white-text"
            hide-details
          />
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-text-field
            v-model="currentRecord.email"
            label="Email"
            placeholder="example@gmail.com"
            color="text-white"
            class="input-style mb-5 input-white-text"
            hide-details
          />
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-select
            v-model="currentRecord.person_type"
            label="Tipo de persona"
            :items="selectPersonType"
            item-text="text"
            item-value="value"
            required
            class="font-size-input input-style mb-5 input-white-text"
          />
        </v-col>

        <v-col cols="12" lg="4" class="my-5">
          <label class="mr-3 text-white">Código de país</label>
          <vue-country-code 
            class="input-style style_background country-select-code"
            style="color: #12141B"
            v-model="currentRecord.phone_prefix"
            :disabledFetchingCountry="true"
            :enabledCountryCode="true"
            :preferredCountries="preferredCountries"
            @onSelect="selectCountryCode"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <v-text-field
            v-model="currentRecord.phone"
            label="Teléfono"
            placeholder="6181234567"
            color="text-white"
            class="input-style mb-5 input-white-text"
            hide-details
          />
        </v-col>

        <v-col cols="12" lg="4">
          <div>
            <v-checkbox 
              color="white"
              v-model="currentRecord.phone_whatsapp"
              class="wa-checkbox-container"
            >
              <template v-slot:label>
                <span class="text-white">¿Whatsapp?</span>
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <v-col cols="12" lg="12" class="mb-5 px-0">
          <v-row class="text-white mx-1 my-5 border-radius-lg blur blur-transparent my-5" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white">Descripción de la empresa</label>
            </v-col>
            <hr style="width: 100%; border: 1px solid #7b809a;">
            <v-col cols="12" lg="12" class="mt-1">
              <VueEditor 
                v-model="currentRecord.description"
                class="input-style ma-0 pa-1 input-white-text white-background"
                :editorOptions="optionsEditor"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="4" class="mb-5">
          <v-select
            v-model="currentRecord.company_type"
            label="Tipo de empresa"
            :items="selectCompanyType"
            item-text="text"
            item-value="value"
            required
            class="font-size-input input-style mb-5 input-white-text"
          />
        </v-col>

        <v-col cols="12" class="ma-0 pa-0">
          <hr class="horizontal mb-0 light line_gradient_background"/>
        </v-col>

        <input accept="image/*" @change="getFile" style="display: none;" type="file" ref="input_file"
          class="form-control" 
        />
        <v-row class="text-white ma-1 mt-5 border-radius-lg" justify="space-around">
          <v-col cols="12" class="d-flex">
            <label class="text-white font-weight-bold">Imágen de la empresa</label>
          </v-col>
          <div class="border-radius-lg main-img-container">
            <!-- 'https://via.placeholder.com/200?text=Imagen' -->
            <v-img :src="(currentRecord.company_image_url != null) ? currentRecord.company_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
            class="img-view-size pa-2 border-radius-lg">
              
            </v-img>
          </div>
          <v-col cols="11" v-if="currentRecord.company_image_url">
            <label class="text-white">{{ currentRecord.company_image_name }}</label>
          </v-col>
          <v-col cols="1" v-if="currentRecord.company_image_url">
            <v-layout class="text-right">
              <v-btn icon color="primary" :href="currentRecord.company_image_url" target="_blank" title="Descargar imágen">
                <v-icon outlined 
                  class="material-icons-round me-sm-1 border-radius-lg gradient-icon-button" size="20">
                  file_download
                </v-icon>
              </v-btn>
            </v-layout>
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <label class="text-white text-center">Subir una imágen desde su dispositivo (Tamaño máximo de 1.95 MB)</label>
          </v-col>
          <v-col cols="12" lg="4" class="d-flex">
            <!--  -->
          </v-col>
          <v-col cols="12" lg="4" class="d-flex">
            <v-btn 
              block
              class="my-1 mx-0 main_gradient_background text-white text-transform-none ma-2" 
              @click="pickFile"
              :loading="btnLoading"
            >
              Subir imágen
            </v-btn>
          </v-col>
          <v-col cols="12" lg="4" class="d-flex">
            <!--  -->
          </v-col>
        </v-row>

        <v-col cols="12" class="ma-0 pa-0">
          <hr class="horizontal mb-0 light line_gradient_background"/>
        </v-col>

        <v-row class="text-white ma-1 mt-5 border-radius-lg" justify="space-around">
          <v-col cols="12" lg="12" class="mt-1 py-0">
            <label class="text-white font-weight-bold">Categorías de la nueva empresa</label>
          </v-col>

          <v-col cols="12" lg="5" class="mb-5">
            <v-select
              v-model="newCategory.category"
              item-text="name"
              :items="categories"
              label="Categoría"
              required
              return-object
              class="font-size-input input-style input-white-text"
              @change="getSubcategories(newCategory.category.id)"
            />
          </v-col>  

          <v-col cols="12" lg="5" class="mb-5">
            <v-select
              v-model="newCategory.subcategory"
              item-text="name"
              :items="subcategories"
              label="Subcategoría"
              required
              return-object
              class="font-size-input input-style input-white-text"
              :disabled="subcategories.length < 1"
            />
          </v-col>

          <v-col cols="12" lg="2" class="mb-5">
            <v-btn icon text rel="tooltip" title="Añadir categoría" 
              class="btn btn-primary btn-simple btn-xs white-button"
              @click="addCategory()">
              <v-icon end class="material-icons-outlined me-sm-1" size="20">
                add
              </v-icon>
              Añadir categoría
            </v-btn>
          </v-col>

          <v-col cols="12"> <!-- Table body -->
            <table class="table-responsive table-sections text-white mb-10 notranslate">
              <thead class="text-white">
                <tr>
                  <th>Categoría</th>
                  <th>Subcategoría</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(category, index) in currentRecord.companyCategories" :key="index">
                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ category.category_name }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        {{ category.subcategory_name }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-center ms-2">
                      <span class="font-weight-normal text-white">
                        <v-layout>                                              
                          <v-btn icon text rel="tooltip" title="Borrar categoría" 
                            class="btn btn-primary btn-simple btn-xs white-button"
                            @click="deleteCategory(index)">
                            <v-icon end class="material-icons-outlined me-sm-1" size="20">
                              close
                            </v-icon>
                          </v-btn>
                        </v-layout>
                      </span>
                    </div>
                  </td>

                </tr>
                <tr v-if="currentRecord.companyCategories.length == 0">
                  <td colspan="5" class="pa-3 align-center text-secondary" style="text-align: center;">
                    Aún no hay Categorías
                  </td>
                </tr>
              </tbody>
            </table>

            <hr class="horizontal mb-0 light line_gradient_background"/>

            <v-row class="text-white ma-1">
              <v-col cols="12" lg="8" class="d-flex align-right">
                <!--  -->
              </v-col>
              <v-col cols="12" lg="4" class="d-flex align-right">
                <v-btn class="my-5 mx-2 cancel_gradient_background text-white text-transform-none"
                  @click="goBackToPrevoiusPage"
                >
                  Cancelar
                </v-btn>

                <v-btn 
                  class="my-5 mx-2 main_gradient_background text-white text-transform-none ma-2" 
                  @click="storeRecord"
                  :loading="btnLoadingNewRecord"
                  v-if="action_edit == 'new'"
                >
                  Guardar
                </v-btn>
                <v-btn 
                  class="my-5 mx-2 main_gradient_background text-white text-transform-none ma-2" 
                  @click="updateRecord"
                  :loading="btnLoadingNewRecord"
                  v-if="action_edit == 'edit'"
                >
                  Actualizar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        
      </v-row>
      <!--
      <pre style="width: 600px; height: 300px; overflow-x: scroll; overflow-y: scroll;" class="text-white">
        action_edit: {{ action_edit }}
        company_id: {{ company_id }}
        currentRecord: {{ currentRecord }}
      </pre> 
      -->
    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  import { VueEditor } from "vue2-editor";
  import debounce from "lodash.debounce";

  export default {
    name: "actions-company-modal",
    components: {
      VueEditor
    },
    data() {
      return {
        action_edit: null,
        company_id: null,

        localShow: false,

        //used to crud operations on a record
        btnLoadingNewRecord: false,
        
        currentRecord: {
          name: '',
          phone_whatsapp: false,
          phone_prefix: '+52',
          person_type: 'moral',
          description: '',
          company_type: 'national',
          companyCategories: [],
          company_image_name: null,
          company_image_url: null,
        },

        btnLoading: false,
        
        newCategory: {
          category: null,
          subcategory: null
        },
        
        categories: [],
        subcategories: [],
        
        preferredCountries: [
          'mx',
          'us',
          'ca'
        ],

        selectPersonType: [
          { text: 'Moral', value: 'moral'},
          { text: 'Física', value: 'physical'}
        ],

        selectCompanyType: [
          {text: 'Local', value: 'local'},
          {text: 'Nacional', value: 'national'},
          {text: 'Internacional', value: 'international'},
        ],


        optionsEditor: {
          theme: 'snow',
          formats: [
            'bold',
            'italic',
            'list',
            'align',
          ],
          modules: {
            toolbar: [
              ['bold', 'italic'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ align: "left" }, { align: "center" }, { align: "right"}, { align: "justify"}]
            ],
          }
        },
      };
    },
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      //
    },
    watch: {
      show: function (value) {
        this.localShow = value;

        if (value == false) {
          this.currentRecord = {
            name: '',
            phone_whatsapp: false,
            phone_prefix: '+52',
            person_type: 'moral',
            description: '',
            company_type: 'national',
            company_image_name: null,
            company_image_url: null,
            companyCategories: [],
          };
        }

        //if the modal is shown to edit a company
        if (this.localShow && this.action_edit == 'edit' && this.company_id != null) {
          this.getItem();
        }
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },

      'currentRecord.name' () {
        if (this.localShow == true && this.currentRecord.name != '') {
          this.debounceVerifyName();
        }
      },
    },
    methods: {
      getCategories: function () {
        Api.get(`category/get_by_section_type/13`)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },        
      getSubcategories: function (category_id) {
        Api.get(`subcategory/get_by_category/${category_id}`)
        .then(response => {
          this.subcategories = response.data;
        })
        .catch(error => this.errorHandling(error))
      },   
      addCategory: function () {
        if (this.newCategory.category != null && this.newCategory.subcategory != null) {
          let newCat = {};
        
          newCat = {
            category_name: this.newCategory.category.name,
            category_id: this.newCategory.category.id,
            subcategory_name: this.newCategory.subcategory.name,
            subcategory_id: this.newCategory.subcategory.id
          }

          let previousCategory = this.currentRecord.companyCategories.find(element => element.category_id == newCat.category_id && element.subcategory_id == newCat.subcategory_id);

          if (previousCategory == null) {
            this.currentRecord.companyCategories.push(newCat);
          } else {
            this.$swal("", "Ya añadió esa categoría y subcategoría", "warning");
          } 
        } else {
          this.$swal("", "Debe elegir una categoría y una subcategoría", "warning");
        }
      },
      deleteCategory: function (index) {
        this.currentRecord.companyCategories.splice(index, 1);
      },

      pickFile: function () {
        this.$refs.input_file.click();
        this.fileSlug = 'company_profile_image';
      },
      getFile: function (e) {
        this.btnLoading = !this.btnLoading;

        let file = e.target.files[0];
        e.target.value = "";
        let size_in_mb = file.size / 1048576;

        if (size_in_mb < 1.95) {

          let formData = new FormData();
          formData.append('file', file)
          formData.append('slug', this.fileSlug)
          formData.append('path', 'companies/images/')

          Api.post('files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.$swal(response.data.message, '', "success");
            this.currentRecord.company_image_name = response.data.file_name;
            this.currentRecord.company_image_url = response.data.file_url;
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoading = !this.btnLoading;
          })

        } else {
          this.$swal('', 'El archivo debe tener un tamaño máximo de 1.95 MB <br> Este archivo tiene un tamaño de '+size_in_mb.toFixed(2)+'MB', "error");
          this.btnLoading = !this.btnLoading;
        }
      },


      getItem: function() {
        Api.get(`company/${this.company_id}/edit`)
        .then(response => {
          this.currentRecord = {
            name: '',
            phone_whatsapp: false,
            phone_prefix: '+52',
            person_type: 'moral',
            description: '',
            company_type: 'national',
            company_image_name: null,
            company_image_url: null,
            companyCategories: [],
          };

          this.currentRecord.id = response.data.id;
          this.currentRecord.name = response.data.name;
          this.currentRecord.email = response.data.email;
          this.currentRecord.phone_prefix = response.data.phone_prefix;
          this.currentRecord.phone = response.data.phone;
          this.currentRecord.phone_whatsapp = response.data.phone_whatsapp;
          this.currentRecord.person_type = response.data.person_type;
          this.currentRecord.description = response.data.description;
          this.currentRecord.company_type = response.data.company_type;
          this.currentRecord.company_image_name = response.data.company_image_name;
          this.currentRecord.company_image_url = response.data.company_image_url;

          response.data.categories_subcategories.forEach(element => {
            let newCat = {};
        
            newCat = {
              caegory_id: element.id,
              category_name: element.category.name,
              category_id: element.category.id,
              subcategory_name: element.subcategory.name,
              subcategory_id: element.subcategory.id
            }

            this.currentRecord.companyCategories.push(newCat);
          });
        })
        .catch(error => this.errorHandling(error));
      },

      checkIfCompanyExists: function() {
        Api.get(`company/get_company_by_name/${this.currentRecord.name}`)
        .then(response => {
          if (response.data != null && response.data.id != this.company_id) {
            this.$swal("", "Ya existe otra empresa con ese nombre", "warning");
            this.currentRecord.name = ''
          }
        })
        .catch(error => this.errorHandling(error))
      },

      selectCountryCode: function ({ name, iso2, dialCode }) {
        this.currentRecord.phone_prefix = name + iso2;
        this.currentRecord.phone_prefix = '+'+dialCode;
      },

      updateRecord: function () {
        this.btnLoadingNewRecord = true;
        if (this.currentRecord.companyCategories.length > 0) {
          Api.patch(`company/${this.currentRecord.id}`, this.currentRecord)
          .then(response => {
            this.$swal("", response.data.message, "success");
            this.goBackToPrevoiusPage();
            /*
            this.localShow = false;
            setTimeout(function() {
              location.reload(); 
            }, 1500);
            */
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoadingNewRecord = false;
          })
        } else {
          this.$swal("", "Debe elegir por lo menos una categoría y una subcategoría", "warning");
          this.btnLoadingNewRecord = false;
        }
      },

      storeRecord: function () {
        this.btnLoadingNewRecord = true;
        if (this.currentRecord.companyCategories.length > 0) {
          Api.post('company', this.currentRecord)
          .then(response => {
            this.$swal("", response.data.message, "success");
            this.goBackToPrevoiusPage();
            /*
            this.localShow = false;
            setTimeout(function() {
              location.reload(); 
            }, 1500);
            */
          })
          .catch(error => this.errorHandling(error))
          .finally(() => {
            this.btnLoadingNewRecord = false;
          })
        } else {
          this.$swal("", "Debe elegir por lo menos una categoría y una subcategoría", "warning");
          this.btnLoadingNewRecord = false;
        }
      },
      goBackToPrevoiusPage: function () {
        this.$router.back();
        /*
        this.$router.push({ 
          name: 'Manage users'
        })
        */
      }
    },
    created() {
      this.action_edit = this.$route.params.action;
      this.company_id = this.$route.params.company_id;

      if (this.action_edit == 'edit' && this.company_id != null) {
        this.getItem();
      }

      this.getCategories();
      this.debounceVerifyName = debounce(() => {
        this.checkIfCompanyExists();
      }, 500);
    }
  };
</script>
<style scoped>
  .table-sections.theme--light.v-data-table {
    background-color: transparent !important;
  }

  .actions-column {
    max-width: 100px;
    overflow-x: scroll;
  }

  .upload-image-card {
    width: initial !important;
  }
</style>