
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import "@/assets/css/main.css";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'



Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

// Mask input
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

// Alertify
import VueAlertify from 'vue-alertify';
Vue.use(VueAlertify);

// Global mixins
import GlobalMixins from "./mixins/GlobalMixins";
Vue.mixin(GlobalMixins)

// CoolLightBox
Vue.use(CoolLightBox);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
