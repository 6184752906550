<template style="font-family: 'roboto';">
  <section>
    <div class="container">
      <v-row v-if="user != null" class="justify-content-center border-radius-lg blur blur-transparent mb-10 mt-5">
        <v-col cols="12">
          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" lg="12" class="mt-1">
              <label class="text-white new-section-title">Revisar cambios de una sección</label>
            </v-col>
          </v-row>

          <v-row class="text-white ma-1 mt-5" justify="space-around">
            <v-col cols="12"> <!-- Table body -->
              <table class="table-responsive table-sections text-white notranslate">
                <thead class="text-white">
                  <tr>
                    <th>Contenido</th>
                    <th>Antes</th>
                    <th>Después</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(content,index) in section.changes_contents" :key="index"
                    class="py-5"
                  >
                    <td style="width: 10%" class="changes_row">
                      <div class="d-flex align-center ms-2">
                        <span class="font-weight-normal"
                          :class=" (content.value_before) ? 'text-white' : 'text-secondary'"
                        >
                          {{ content.slug_es }}
                        </span>
                      </div>
                    </td>

                    <td style="width: 45%" class="changes_row">
                      <div class="d-flex align-center ms-2">
                        <span 
                          v-if="content.slug == 'main_description' || content.slug == 'body_incentive'"  
                          class="font-weight-normal ql-editor"
                          :class=" (content.value_before) ? 'text-white' : 'text-secondary'"
                          v-html="(content.value_before) ? content.value_before : 'Sin valor previo'"
                        > 
                        </span>
                        <span 
                          v-else-if="content.slug != 'main_image_url' && content.slug != 'secondary_image_url' && content.slug != 'additional_image_url'"
                          class="font-weight-normal"
                          :class=" (content.value_before) ? 'text-white' : 'text-secondary'"
                        >
                          {{ (content.value_before) ? content.value_before : 'Sin valor previo' }}
                        </span>
                        <span 
                          v-else
                          class="font-weight-normal"
                          :class=" (content.value_before) ? 'text-white' : 'text-secondary'"
                        >
                          <v-img v-if="content.value_before != null" :src="(content.value_before != null) ? content.value_before : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                          class="img-view-size pa-2 border-radius-lg"/>
                          <p v-else class="font-weight-normal text-secondary">{{ (content.value_before) ? content.value_before : 'Sin valor previo' }}</p>
                        </span>
                      </div>
                    </td>

                    <td style="width: 45%" class="changes_row">
                      <div class="d-flex align-center ms-2">
                        <span 
                          v-if="content.slug == 'main_description' || content.slug == 'body_incentive'"  
                          class="font-weight-normal ql-editor"
                          :class=" (content.value_after) ? 'text-white' : 'text-secondary'"
                          v-html="(content.value_after) ? content.value_after : 'Sin cambios'"
                        >
                        </span>
                        <span 
                        v-else-if="content.slug != 'main_image_url' && content.slug != 'secondary_image_url' && content.slug != 'additional_image_url'"
                          class="font-weight-normal"
                          :class=" (content.value_after) ? 'text-white' : 'text-secondary'"
                        >
                          {{ (content.value_after) ? content.value_after : 'Sin cambios' }}
                        </span>
                        <span 
                          v-else
                          class="font-weight-normal"
                          :class=" (content.value_after) ? 'text-white' : 'text-secondary'"
                        >
                          <v-img v-if="content.value_after != null" :src="(content.value_after != null) ? content.value_after : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/no-image.png'" 
                          class="img-view-size pa-2 border-radius-lg"/>
                          <p v-else class="font-weight-normal text-secondary">{{ (content.value_after) ? content.value_after : 'Sin cambios' }}</p>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>

          <v-row class="text-white ma-1" justify="space-around">
            <v-col cols="12" :lg="(user.Roles[0] == 'Admininstrator' && section.approved == null) ? 7 : 9" class="mt-1">
              <!--  -->
            </v-col>
            <v-col cols="12" :lg="(user.Roles[0] == 'Admininstrator' && section.approved == null) ? 5 : 3" class="mt-1">
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                @click="goBackToPrevoiusPage"
              >
                Volver
              </v-btn>
              
              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="user.Roles[0] == 'Admininstrator' && section.approved == null"
                @click="approveOrRejectSection(section.id, true)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  task_alt
                </v-icon>
                Aprobar
              </v-btn>

              <v-btn class="my-5 mx-2 main_gradient_background text-white text-transform-none"
                v-if="user.Roles[0] == 'Admininstrator' && section.approved == null"
                @click="approveOrRejectSection(section.id, false)"
              >
                <v-icon outlined class="material-icons-outlined me-sm-1" size="20">
                  unpublished
                </v-icon>
                Rechazar
              </v-btn>
                          
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--       
      <pre style="height: 300px; overflow-y: scroll;" class="text-white">
        section: {{ section }}
      </pre>  
      -->

    </div>
  </section>
</template>
  
<script>
  import Api from '@/api/Api';
  
  export default {
    name: "preview-section-changes",
    components: {
      //
    },
    data() {
      return {
        user: null,
        section: {
          approved_contents: []
        },
        section_id: null,
        sectionName: null,
        section_type_id: null
      };
    },
    computed: {
      //
    },
    watch: {
      //
    },
    methods: {
      getUserInfo: function () {
        Api.get('/auth/logged_user').then(response => {
          this.user = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      getSectionData: function () {
        Api.get(`/section/preview_section_changes/${this.section_id}`).then(response => {
          this.section = response.data;
        })
        .catch(error => this.errorHandling(error))
      },
      approveOrRejectSection: function(SectionId, value) {
        this.$swal({
          title: ((value) ? '¿Aprobar sección?' : '¿Rechazar sección?'),
          text: "",
          type: "info",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            Api.post(`section/approve_reject_section`, {
              section_id: SectionId,
              approved: value
            })
            .then(response => {
              this.$swal.fire("Success", response.data.message, "success");
              this.$router.push({ 
                name: 'sections', 
                params: { 
                  section: this.sectionName, 
                  section_type_id: this.section_type_id
                } 
              })
            })
            .catch(error => this.errorHandling(error))
          } 
        });
      },
      goBackToPrevoiusPage: function () {
        this.$router.push({ 
          name: 'sections', 
          params: { 
            section: this.sectionName, 
            section_type_id: this.section_type_id
          } 
        })
      }
    },
    mounted() {
      this.section_id = this.$route.params.section_id;
      this.sectionName = this.$route.params.section;
      this.section_type_id = this.$route.params.section_type_id;

      this.getUserInfo();
      this.getSectionData();
    }
  };
</script>

<style scoped>
  .changes_row {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .img-view-size {
    width: 300px !important;
    max-height: 200px !important;
  }
</style>